import React from "react";
import styled from "styled-components";

import { colorSetting } from "../../../../constant/color";
import { fontSetting } from "../../../../constant/font";
import defaultAvatarOwner from "../../../../assets/images/default-avatar-owner.png";
import ModalUserJoined from "./ModalUserJoined";

// Define the styled component
const NewBannerProjectRefactor = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #53565a;
  padding: 0 16px;
  height: 75px;
  position: relative;
  z-index: 600;
  margin-top: 0;
  width: 100%;
`;

const BlockProjectRight = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
`;

const BlockUserAvatar = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 11px;
  border: 1px solid #f0f0f0;
  padding: 4px 8px 4px 4px;
  background: rgba(255, 255, 255, 0.16);
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
    border: 1px solid #009ace;
    outline: 1px solid #009ace;
    -webkit-box-shadow: 2px 4px 8px 3px rgba(0, 154, 206, 0.1);
    box-shadow: 2px 4px 8px 3px rgba(0, 154, 206, 0.1);
  }
`;

const AvatarUserProject = styled.img`
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  margin-right: -4px;
  border-radius: 8px;
  max-width: none;
  max-width: initial;
`;

const BlockProjectLeft = styled.div`
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

const VerticalLine = styled.span`
  width: 1px;
  height: 16px;
  background-color: #f0f0f0;
  margin: 0 4px 0 8px;
`;

const DotMoreUser = styled.div`
  padding-left: 5px;
  height: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
`;

const CalendarSchedule = styled.span`
  color: #fff;

  &:hover {
    cursor: pointer;
    color: #009ace;
  }
`;

const ProjectBanner = ({ project, user, modalMember, setModalMember }) => {
  const color = project.color_setting
    ? colorSetting[project.color_setting - 1]
    : {};
  const font = project.font_setting
    ? fontSetting[project.font_setting - 1]
    : {};
  let owners = project?.owners ? project.owners : [];
  let admins = project?.admins ? project.admins : [];

  return (
    <NewBannerProjectRefactor
      className={`pbanner banner-project-backgroud ${color.code}`}
      data-project-role={user?.position}
      style={color ? { backgroundColor: color.css_code } : {}}
    >
      <BlockProjectLeft>
        <div className="code-name-block">
          <div
            className="code-name"
            style={
              font && color
                ? { ...font.css_code, color: color.text_color_css }
                : {}
            }
          >
            {project?.get_name_in_page_title}
          </div>
        </div>
      </BlockProjectLeft>

      {/* Right Block: User List and Schedule */}
      <BlockProjectRight>
        <BlockUserAvatar onClick={(e) => setModalMember(true)}>
          {/* Owners Avatars */}
          {owners && owners.length > 0 && (
            <>
              {owners.slice(0, 5).map((owner) => (
                <AvatarUserProject
                  key={owner.id}
                  data-user={owner.id}
                  title={owner.display_name}
                  src={owner.avatar ? owner.avatar : defaultAvatarOwner}
                  alt=""
                />
              ))}
              {owners.length > 5 && <DotMoreUser>...</DotMoreUser>}
            </>
          )}

          <VerticalLine />

          {/* Admins Avatars */}
          {admins && admins.length > 0 && (
            <>
              {admins.slice(0, 5).map((admin) => (
                <AvatarUserProject
                  key={admin.id}
                  title={admin.display_name}
                  data-user={admin.id}
                  src={admin.avatar ? admin.avatar : defaultAvatarOwner}
                  alt=""
                />
              ))}
              {admins.length > 5 && <DotMoreUser>...</DotMoreUser>}
            </>
          )}
        </BlockUserAvatar>
        <ModalUserJoined
          project={project}
          user={user}
          visible={modalMember}
          setVisible={setModalMember}
        />
        {/* Schedule Icon */}
        <div className="back-button-block schedule-project-detail schedule-icon-modal">
          <CalendarSchedule className="material-symbols-rounded calendar-schedule">
            event_note
          </CalendarSchedule>
        </div>
      </BlockProjectRight>
    </NewBannerProjectRefactor>
  );
};

export default ProjectBanner;
