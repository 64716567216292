import React, { useState } from "react";
import styled from "styled-components";

import { roundRating } from "../../common/utils";
import RatingComponent from "../../../../../../../components/form-item/RatingComponent";
import SceneBlock from "../scene-block";
import AddSceneModal from "../modal/AddSceneModal";

const ProjectProductScene = ({
  user,
  finished_scene,
  product_scene,
  project_id,
}) => {
  const [addModalVisible, setAddModalVisible] = useState(false);

  // Check if the role is admin or master_client
  if (user?.role !== "admin" && user?.role !== "master_client") {
    return <></>; // Return null if the role is neither 'admin' nor 'master_client'
  }

  const listSceneInProductScene = finished_scene
    .map((scene) => {
      if (product_scene?.id === scene?.product_scene_id) {
        return scene;
      } else {
        return undefined;
      }
    })
    .filter(Boolean);

  const ProductSceneRating = roundRating(listSceneInProductScene);

  return (
    <StyledPdChapter data-product-scene-id={product_scene.id}>
      <div className="pd-chapter__title active">
        <span className="pd-chapter__name">{product_scene.name}</span>
        <div className="cvideo__rating" style={{ margin: "0 4px 0 24px" }}>
          <RatingComponent currentRating={ProductSceneRating} canRate={false} />
        </div>
        <span className="pd-chapter__rating">{ProductSceneRating} (5)</span>
        <div className="pd-chapter__line"></div>
      </div>
      <div className="pd-chapter__content" style={{ display: "block" }}>
        <PdChaterList>
          {listSceneInProductScene.map((scene) => {
            if (scene) {
              return (
                <div key={scene.id}>
                  <SceneBlock
                    scene={scene}
                    user={user}
                    project_id={project_id}
                  />
                </div>
              );
            }
            return null;
          })}
        </PdChaterList>
      </div>
      {user?.role === "admin" && (
        <AddProductSceneButton onClick={() => setAddModalVisible(true)}>
          <div className="c-fab heading-13 u-gap8 u-mb8">
            <span className="material-symbols-rounded">add_circle</span> 納品
          </div>
        </AddProductSceneButton>
      )}
      <AddSceneModal
        show={addModalVisible}
        onClose={() => {
          setAddModalVisible(false);
        }}
        project_scene={product_scene}
        project_id={project_id}
      />
    </StyledPdChapter>
  );
};

const StyledPdChapter = styled.div`
  &:first-child {
    margin-top: 16px;
  }

  margin-bottom: 40px;

  .pd-chapter__title {
    color: #000;
    padding: 0 16px 8px;
    display: flex;
    align-items: center;
  }

  .pd-chapter__name {
    font-family: "A+mfCv-AXISラウンド 50 R StdN", "Noto Sans Japanese",
      "sans-serif";
    font-size: 18px;
    letter-spacing: 2.5px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .pd-chapter__rating {
    font-size: 11px;
    letter-spacing: 0;
    color: #a7a8a9;
  }
`;

const AddProductSceneButton = styled.div`
  z-index: 599;
  position: fixed;
  bottom: 72px;
  right: 16px;
  margin: 0;
  flex: 0 0 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  min-height: 96px;
  cursor: pointer;

  .c-fab {
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      font-size: 48px;
    }
  }

  .material-symbols-rounded {
    font-size: 24px;
  }
`;

const PdChaterList = styled.div`
  flex-wrap: nowrap;
  display: flex;
  gap: 12px 16px;
  overflow: unset;
  overflow-x: auto;
  padding: 8px 16px 16px;
`;

export default ProjectProductScene;
