import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getAuthTokenFromCookies } from "../../common/utils";
import { HOSTNAME } from "../../constant/host";

// Define a service using a base URL and expected endpoints
export const listProjectApi = createApi({
  reducerPath: "listProjectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: HOSTNAME,
    prepareHeaders: (headers) => {
      // Get the auth token from cookies
      const token = getAuthTokenFromCookies();

      // If we have a token, add it to the headers
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProjectList: builder.query({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        return `/api/get-list-project?${queryString}`;
      },
      keepUnusedDataFor: 0, // Invalidate cache immediately
    }),
    getProject: builder.query({
      query: (project_id) => {
        const queryString = new URLSearchParams({ project_id }).toString();
        return {
          url: `/api/get-project?${queryString}`,
          method: "GET",
        };
      },
    }),
    uploadFile: builder.mutation({
      query: (formData) => ({
        url: "/api/upload",
        method: "POST",
        body: formData,
      }),
    }),
    createScene: builder.mutation({
      query: (formData) => ({
        url: "/top/upload_video",
        method: "POST",
        body: formData,
      }),
    }),
    getCommentList: builder.query({
      query: ({ project_id, type }) => {
        const queryString = new URLSearchParams({
          project_id,
          type,
        }).toString();
        return `/api/get-list-comment-new?${queryString}`;
      },
    }),
    getMessengerListArtist: builder.query({
      query: ({ project_id, filter_offer, user_id, offer_active }) => {
        const queryString = new URLSearchParams({
          project_id,
          filter_offer,
          user_id,
          offer_active,
        }).toString();
        return `/api/get-messenger-list-artist?${queryString}`;
      },
    }),
    loadOfferMessageDetail: builder.query({
      query: ({ offer_id }) => {
        const queryString = new URLSearchParams({ offer_id }).toString();
        return `/api/load-offer-detail-message?${queryString}`;
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetProjectListQuery,
  useGetProjectQuery,
  useUploadFileMutation,
  useCreateSceneMutation,
  useGetCommentListQuery,
  useGetMessengerListArtistQuery,
  useLoadOfferMessageDetailQuery,
} = listProjectApi;
