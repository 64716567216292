import React, { useState } from "react";
import styled from "styled-components";

import Modal from "../../../components/modal/Modal";

// styled components
const TransactionSectionStyle = styled.dl`
  background-color: #009ace;
  border-radius: 4px;

  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  cursor: pointer;
`;

const TransactionBlockStyle = styled.ul`
  background-color: #fff;
  border-radius: 6px;

  border: 1px solid var(--soremo-border);
  padding: 16px 8px;
  display: flex;

  flex-direction: column;

  cursor: pointer;

  &:hover {
    .material-symbols-rounded {
      color: var(--soremo-blue);
    }
  }
`;

const TransactionStatus = () => {
  const authUser = {
    bank: "楽天",
    bank_branch: "ロック",
    account_number: "1234567",
    balance_available: "19800",
    balance_expected: "15000000",
  };

  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);

  return (
    <TransactionSectionStyle>
      <TransactionBlockStyle onClick={() => setIsTransferModalOpen(true)}>
        <li className="bodytext-13 u-line-heightー200 u-text-light-gray">
          残高
        </li>
        <p className="bodytext-13 u-line-height-150 u-text-green">
          {authUser.bank && authUser.bank_branch
            ? `${authUser.bank}銀行${authUser.bank_branch}支店に振込できます。`
            : "振込先の銀行口座を登録しよう"}
        </p>
        <ul className="u-row-between">
          <li className="heading-32 u-line-height-150">
            {Number(authUser.balance_available).toLocaleString()}
            <span className="bodytext-11 u-pl4">円（税込）</span>
          </li>
          <ul className="u-row u-gap4">
            <li className="heading-13 u-lihe-height-150 u-text-blue">
              振込申請
            </li>
            <li className="material-symbols-rounded u-text-blue">
              chevron_right
            </li>
          </ul>
        </ul>
      </TransactionBlockStyle>
      {isTransferModalOpen && (
        // TODO 残高を0にて支払明細書を作成
        <Modal onClose={() => setIsTransferModalOpen(false)}>
          振込申請を受け付けました。
          <br />
          5営業日を目安に、{authUser.bank}銀行{authUser.bank_branch}
          支店にお振込します。
          <br />
          詳しい内訳は、支払明細書でご確認いただけます。
        </Modal>
      )}
      <TransactionBlockStyle onClick={() => setIsProgressModalOpen(true)}>
        <li className="bodytext-13 u-line-height-150 u-text-light-gray">
          仕掛
        </li>
        <ul className="u-row-between">
          <li className="heading-18 u-line-height-150">
            {Number(authUser.balance_expected).toLocaleString()}
            <span className="bodytext-11 u-pl4">円（税込）</span>
          </li>
          <ul className="u-row u-gap4">
            <li className="material-symbols-rounded u-text-light-gray">
              chevron_right
            </li>
          </ul>
        </ul>
      </TransactionBlockStyle>
      {isProgressModalOpen && (
        <Modal onClose={() => setIsProgressModalOpen(false)}>
          発注日|コードネーム｜対価｜ロール（シーン）
          <br />
          発注日|コードネーム｜対価｜ロール（シーン）
          <br />
          発注日|コードネーム｜対価｜ロール（シーン）
          <br />
          発注日|コードネーム｜対価｜ロール（シーン）
          <br />
        </Modal>
      )}
    </TransactionSectionStyle>
  );
};

export default TransactionStatus;
