import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Icon = styled.div.attrs({
  className: "material-symbols-rounded",
})`
  font-size: 24px;
  color: var(--soremo-light-gray);
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: var(--soremo-blue);
      scale: 1.1;
    }
  }
`;

const IconButton = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <>
      <Icon ref={ref} onClick={onClick}>
        {children}
      </Icon>
    </>
  );
});

IconButton.propTypes = {
  onClick: PropTypes.func,
};

export default IconButton;
