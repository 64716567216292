import React, { useState } from "react";

const Dropdown = ({ member }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      className="u-relative member-item"
      data-user={member.id}
      onClick={toggleDropdown}
    >
      <span className="material-symbols-rounded c-icon-more-horiz">
        more_horiz
      </span>
      {isOpen && (
        <ul className="c-dropdown">
          {/* Add Dropdown Items based on member's properties */}
          <li className="u-row-between u-w100">Option 1</li>
          <li className="u-row-between u-w100">Option 2</li>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
