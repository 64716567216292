import homeRouteSchema from "./home/routes";
import topRouteSchema from "./top/routes";
import myPageRouteSchema from "./my-page/routes";
import accountsRouteSchema from "./accounts/routes";
import sceneDetailRouteSchema from "./scene-detail/routes";

const routes = [
  ...homeRouteSchema,
  ...myPageRouteSchema,
  ...topRouteSchema,
  ...accountsRouteSchema,
  ...sceneDetailRouteSchema,
];

export default routes;
