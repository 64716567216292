import React from "react";

import UserDownloaded from "./UserDownloaded.jsx";

const DocumentBlock = ({ file, fileInfo, index, comment }) => {
  return (
    <div>
      {/* PDF Image Block */}
      <div className="block-pdf-image">
        <img src={file?.presigned_url} className="pdf-image" alt="pdf" />
      </div>

      {/* File Content */}
      <div className="comment-file-content">
        <p style={{ wordBreak: "break-all" }} className="file-name">
          {file?.real_name}
        </p>
        <div className="btn-download-file-cmt">
          <div
            className={`btn-download-file ${
              file?.scene?.production_file ? "tfile-producttion-file" : ""
            } block-download-file`}
          >
            <span className="material-symbols-rounded scene-file-download">
              download
            </span>
          </div>
        </div>
      </div>

      {/* File Info */}
      <div className="info-message-file">
        <div className="size-file-message">
          <span>{`${fileInfo?.width} x ${fileInfo?.height}px`}</span>
        </div>
        <div className="user-download-file">
          <div className="has_user_downloaded">
            <UserDownloaded file={file} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentBlock;
