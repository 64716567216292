import React from "react";
import PropTypes from "prop-types";

import Button from "../button/Button";
import StatusLabel from "../status-label/StatusLabel";

const NDASignedBadge = ({ signed = false }) => {
  return (
    <>
      {signed ? (
        <Button
          name="「NDA締結」バッジを獲得"
          size="xs"
          color="border"
          leftIcon="lock_open"
          rightIcon="chevron_right"
        />
      ) : (
        <StatusLabel
          label="NDA締結済み"
          color="border"
          size="11px"
          leftIcon="lock"
        />
      )}
    </>
  );
};

NDASignedBadge.propTypes = {
  /**
   * NDA締結のステータス
   */
  signed: PropTypes.bool,
};

export default NDASignedBadge;
