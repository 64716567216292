import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import {
  setScrollUp,
  setScrollDown,
  resetScroll,
} from "../../../../../app/actions/layout/scroll";
import FileItemBlock from "./file-block/FileItemBlock";

const PDSectionFile = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  ${(props) => (props?.fileOpen ? `right: 0;` : `right: -267px;`)}
  overflow: auto;
  width: 267px;
  border-left: 1px solid #f0f0f0;
  padding: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  background: #fcfcfc;

  .pd-file-content {
    max-height: 100%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow-x: hidden;
  }
`;

const PdProductFile = ({ data, user, project, fileOpen }) => {
  const dispatch = useDispatch();
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = (e) => {
    const currentScrollPos = e?.target?.scrollTop;

    // Compare previous scroll position with the current to determine direction
    if (currentScrollPos > prevScrollPos) {
      dispatch(setScrollDown());
    } else if (currentScrollPos < prevScrollPos) {
      dispatch(setScrollUp());
    } else {
      dispatch(resetScroll());
    }

    setPrevScrollPos(currentScrollPos);
  };

  // Memoizing comments to avoid recalculation on every scroll
  const comments = useMemo(() => {
    return data?.comments?.map((comment) => {
      return {
        ...comment,
        ...data?.list_same_role?.find(
          (valueData) => valueData.comment_id === comment.comment_id,
        ),
        fileList: data?.comment_files
          ?.map((file) => {
            let extraInfo = data?.list_extra_file_info?.find(
              (dataFile) => dataFile?.file_id === file.file_id,
            );
            if (file.message_id === comment.comment_id) {
              return { ...file, ...extraInfo };
            } else {
              return false;
            }
          })
          .filter(Boolean),
        folders: data?.folder.filter(
          (folder) => folder.message_id === comment.comment_id,
        ),
        parentComment: {
          ...data?.parent_comments?.find(
            (valueData) => valueData.comment_id === comment.comment_id,
          ),
        },
      };
    });
  }, [data]); // Recalculate only when `data` changes

  // Memoizing comment_files to avoid recalculation on every scroll
  const comment_files = useMemo(() => {
    return (
      data?.comment_files?.map((file) => {
        let extraInfo = data?.list_extra_file_info?.find(
          (dataFile) => dataFile?.file_id === file.file_id,
        );
        return {
          ...file,
          ...extraInfo,
        };
      }) ?? []
    );
  }, [data]); // Recalculate only when `data.comment_files` or `data.list_extra_file_info` change

  const folders = data?.folder ?? [];

  return (
    <PDSectionFile fileOpen={fileOpen}>
      <div className="pd-file-content" onScroll={handleScroll}>
        {/* Render FileItemBlock passing the necessary props */}
        <FileItemBlock
          comments={comments}
          fileList={comment_files}
          folderList={folders}
          user={user}
          project={project}
        />
      </div>
    </PDSectionFile>
  );
};

export default PdProductFile;
