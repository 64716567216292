// import React, { useState, useLayoutEffect } from "react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  setScrollUp,
  setScrollDown,
  // resetScroll,
} from "../../app/actions/layout/scroll";
import GlobalMenu from "../global-menu";

const GlobalWrapper = ({ children, isHideTopBar = false }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let touchStartY = null;

    const handleWheel = (event) => {
      const deltaY = event.deltaY;
      if (deltaY > 0) {
        dispatch(setScrollDown());
      } else if (deltaY < 0) {
        dispatch(setScrollUp());
      }
    };

    const handleTouchStart = (event) => {
      touchStartY = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
      if (touchStartY === null) return;
      const currentY = event.touches[0].clientY;
      if (currentY < touchStartY) {
        dispatch(setScrollDown());
      } else if (currentY > touchStartY) {
        dispatch(setScrollUp());
      }
      touchStartY = currentY;
    };

    window.addEventListener("wheel", handleWheel, { passive: true });
    window.addEventListener("touchstart", handleTouchStart, { passive: true });
    window.addEventListener("touchmove", handleTouchMove, { passive: true });

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [dispatch]);

  // const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  // useLayoutEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;

  //     // Compare previous scroll position with the current to determine direction
  //     if (currentScrollPos > prevScrollPos) {
  //       dispatch(setScrollDown());
  //     } else if (currentScrollPos < prevScrollPos) {
  //       dispatch(setScrollUp());
  //     } else {
  //       dispatch(resetScroll());
  //     }

  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   // Attach scroll listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the scroll listener on component unmount
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [prevScrollPos, dispatch]); // Add dependencies to prevent stale closures

  return (
    <div>
      <GlobalMenu isHideTopBar={isHideTopBar} />
      {children}
    </div>
  );
};

export default GlobalWrapper;
