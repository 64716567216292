import React from "react";

// Processing Scene Modal Component
const ProcessingSceneModal = ({ isOpen, onClose }) => {
  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      role="dialog"
      style={{ overflow: "hidden", display: isOpen ? "block" : "none" }}
    >
      <div
        className="modal-dialog modal-lg"
        role="document"
        style={{ width: "100%" }}
      >
        <div className="modal-content" style={{ height: "100vh" }}>
          <div className="modal-body container" style={{ maxHeight: "100vh" }}>
            <div className="project-video-item show-comment">
              <div className="video-item-wrap">
                <div className="video-item-list">
                  <div className="video-item-component active" data-scene-id="">
                    <div className="video-item-component-content">
                      <div className="video-item-component-content-video">
                        <div className="video-item-chapter"></div>
                        <div className="video-item-variation"></div>
                        <div className="video-item-slug" share-url=""></div>
                        <div className="video-button video-item-bookmark"></div>
                        <div
                          className="video-button video-item-share"
                          onClick={onClose}
                        ></div>
                        <div className="video-button video-item-button-top">
                          とりあえずOK
                        </div>
                        <div className="video-button video-item-button-bottom">
                          チェックバック
                        </div>
                        <div className="video-button video-item-button-left">
                          もういちど
                        </div>
                        <video
                          width="100%"
                          height="auto"
                          controls
                          preload="none"
                        >
                          <source src="" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="video-item-control">
                  <div className="video-item-bullets-wrap">
                    <div className="video-item-bullets">
                      <div
                        className="video-item-bullet-prev disable"
                        data-current_index="0"
                      ></div>
                      <div className="video-item-bullet-list">
                        <div
                          className="video-item-bullet active"
                          data-index=""
                        ></div>
                      </div>
                      <div
                        className="video-item-bullet-next"
                        data-current_index="0"
                      ></div>
                    </div>
                  </div>
                  <div className="video-item-thumbnails">
                    <div className="video-item-thumbnail-list">
                      <div
                        className="video-item-thumbnail active"
                        data-index=""
                        data-id=""
                      >
                        <video
                          className="active"
                          width="100%"
                          height="auto"
                          preload="none"
                        ></video>
                      </div>
                    </div>
                  </div>
                  <div className="video-item-collapse">
                    <div className="video-item-collapse-button"></div>
                  </div>
                </div>
                <br />
              </div>
              <div className="video-item-comment" data-scene-id=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessingSceneModal;
