import React from "react";
import styled from "styled-components";

// Define a styled component for .mmessage-user-seen
const MMessageUserSeen = styled.div`
  display: flex; /* Equivalent to display: -webkit-box; -ms-flexbox; flex */

  &:not(:first-child) {
    margin-left: -5px;
  }
`;

const Avatar = styled.div`
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #fff;
`;

const UserSeenMessage = ({ user, avatar }) => {
  const userAvatar = user?.user_avatar || avatar;

  return (
    <MMessageUserSeen>
      <div className="avatar avatar--image avatar--14 avatar-seen avatar--square">
        <Avatar imageUrl={userAvatar} />
      </div>
    </MMessageUserSeen>
  );
};

export default UserSeenMessage;
