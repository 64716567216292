import React from "react";
import styled from "styled-components";

// styled-components
const TabContainer = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--soremo-background);
  border-top: 1px solid var(--soremo-border);
  border-left: 1px solid var(--soremo-border);
  border-right: 1px solid var(--soremo-border);
  border-radius: 6px 6px 0px 0px;
  color: var(--soremo-light-gray);
  transition: 0.2s;
`;

const TabTextContainer = styled.ul`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

const TabItem = styled.li`
  width: 100%;
  text-align: center;
  border-radius: 6px 6px 0px 0px;
  padding: 1px 0;
  margin: 1px 1px;
  /* アクティブなタブのスタイル */
  background-color: ${({ isActive }) =>
    isActive ? "var(--soremo-light-gray)" : "var(--soremo-background)"};
  color: ${({ isActive }) => (isActive ? "#fff" : "var(--soremo-light-gray)")};

  ${({ isActive }) =>
    !isActive &&
    `
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background-color: var(--soremo-border);
    }
  }
  `}
`;

const TabTextItem = styled.li`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  /* アクティブなタブのスタイル */
  color: ${({ isActive }) =>
    isActive ? "var(--soremo-blue)" : "var(--soremo-light-gray)"};

  ${({ isActive }) =>
    !isActive &&
    `
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      color: var(--soremo-blue);
    }
  }
  `}
`;

const Tab = (props) => {
  const { type = "tab", list = [], activeTab, onTabChange } = props;
  return (
    <>
      {type === "tab" ? (
        <TabContainer>
          {list.map((item, index) => (
            <TabItem
              key={index}
              isActive={activeTab === item}
              onClick={() => onTabChange(item)}
            >
              {item}
            </TabItem>
          ))}
        </TabContainer>
      ) : type === "text" ? (
        <TabTextContainer>
          {list.map((item, index) => (
            <TabTextItem
              key={index}
              isActive={activeTab === item}
              onClick={() => onTabChange(item)}
            >
              {item}
            </TabTextItem>
          ))}
        </TabTextContainer>
      ) : null}
    </>
  );
};

export default Tab;
