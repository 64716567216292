import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ToggleWrapper = styled.label.attrs({
  className: "u-w100 u-row u-gap8 u-relative",
})``;

const Toggle = ({ value = true, children, onClick }) => {
  return (
    <ToggleWrapper onClick={onClick}>
      {value ? (
        <div className="material-symbols-rounded u-fontsize-40 u-text-blue">
          toggle_on
        </div>
      ) : (
        <div className="material-symbols-rounded u-text-border u-fontsize-40">
          toggle_off
        </div>
      )}
      <div>{children}</div>
    </ToggleWrapper>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Toggle;
