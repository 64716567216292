import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";

import logoList from "../../../constant/logo"; // Move this import below

const ListLogo = () => {
  return (
    <div className="list-logo">
      <div className="wrapper">
        <Swiper
          modules={[Autoplay, Pagination]}
          loop={true}
          speed={500}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          spaceBetween={50}
          slidesPerView={5}
          onSlideChange={() => {
            //code when slide change
          }}
          onSwiper={
            (/*swiper*/) => {
              //code when swiper setup
            }
          }
        >
          {logoList.map((logo, indexLogo) => {
            return (
              <SwiperSlide key={indexLogo}>
                <div className="logo-item">
                  <a href={logo.link} target="_blank" rel="noreferrer">
                    <img src={logo.src} alt={logo.alt} />
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ListLogo;
