import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavbarItem = styled.li`
  ${(props) =>
    props.active &&
    `color: #009ace;`} // Apply active color when active prop is true

  .material-symbols-rounded {
    ${(props) =>
      props.active &&
      `color: #009ace;`}// Apply active color when active prop is true
  }
`;

const NavigationLink = ({ current, linkInfo }) => {
  return (
    <>
      <Link to={linkInfo?.url}>
        <NavbarItem active={linkInfo?.name === current}>
          <div className="material-symbols-rounded icon-with-badge u-relative">
            {linkInfo?.icon}
            {linkInfo?.count > 0 && (
              <div
                className="c-budge u-row-center u-absolute"
                style={{ top: "-8px", right: "-8px" }}
                value={linkInfo?.count}
              >
                {linkInfo?.count < 100 ? linkInfo?.count : "99+"}
              </div>
            )}
          </div>
          <div className="label8">{linkInfo?.label}</div>
        </NavbarItem>
      </Link>
    </>
  );
};

export default NavigationLink;
