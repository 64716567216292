// PaymentForm.js
import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const PaymentForm = ({ clientSecret, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // 成功時のリダイレクトURL（必要に応じて設定）
        // return_url: "https://your-return-url.com",
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("カードを追加しました");
      // カード追加後の処理（必要に応じて）
      onClose();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button type="submit" disabled={!stripe}>
          カードを追加
        </button>
      </form>
      {message && <div>{message}</div>}
    </div>
  );
};

export default PaymentForm;
