import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import styled from "styled-components";

import Modal from "../modal/Modal";
import Button from "../button/Button";
// styled components
const Dropzone = styled.div.attrs({ className: "bodytext-13" })`
  aspect-ratio: ${(props) => props.aspect};
  max-height: 144px;
  border: 1px dashed #f0f0f0;
  border-radius: 6px;
  padding: 24px 16px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
  transition: background-color 0.3s ease;
  color: #a7a8a9;
  .material-symbols-rounded {
    color: #f0f0f0;
  }

  &:hover {
    background-color: #009ace;
    border: 1px solid #f0f0f0;
    color: #fff;

    .material-symbols-rounded {
      color: #fff;
    }
  }

  &:active {
    border-color: #009ace;
  }
`;

const PreviewImage = styled.div`
  aspect-ratio: ${(props) => props.aspect};
  max-height: 256px;
  border-radius: 6px;
`;

const ImageUploader = ({
  initialImage,
  targetWidth,
  targetHeight,
  aspect = 16 / 9, // aspect比をPropsから受け取る
}) => {
  const [imageSrc, setImageSrc] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setImageSrc(reader.result.toString());
          setShowCropModal(true); // ファイルアップロード後にモーダル表示
        }
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [], "video/*": [] },
  });

  // クロップ範囲決定時コールバック
  const onCropComplete = useCallback((_, croppedAreaPixelsVal) => {
    setCroppedAreaPixels(croppedAreaPixelsVal);
  }, []);

  // OKボタン押下時
  const handleCropConfirm = useCallback(async () => {
    if (!imageSrc || !croppedAreaPixels) return;
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    // リサイズ処理
    const resizedImage = await resizeImage(
      croppedImage,
      targetWidth,
      targetHeight,
    );
    setImageSrc(resizedImage);
    // クロップ完了後にモーダルを閉じる
    setShowCropModal(false);
  }, [imageSrc, croppedAreaPixels, targetWidth, targetHeight]);

  // プレビュー画像をクリックまたはドラッグ＆ドロップで再登録
  const handleReupload = useCallback(() => {
    setShowCropModal(true);
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {/* アップロードボックス（まだimageSrcがない場合のみ） */}
      {!imageSrc && (
        <Dropzone aspect={aspect} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>ここにファイルをドロップしてください</p>
          ) : (
            <span className="material-symbols-rounded u-fontsize-48">
              add_a_photo
            </span>
          )}
        </Dropzone>
      )}

      {/* プレビュー表示（画像がある場合） */}
      {imageSrc && (
        <PreviewImage
          aspect={aspect}
          onClick={handleReupload}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <img
            src={imageSrc}
            alt="preview"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "6px",
            }}
          />
        </PreviewImage>
      )}

      {/* クロップ用モーダル */}
      {showCropModal && (
        <Modal onClose={() => setShowCropModal(false)}>
          <div style={{ width: "100%", height: "50vh", position: "relative" }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect} // ここでPropsから渡されたaspectを使用
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="u-row u-gap8 u-w100">
            <label className="u-flex-none">拡大</label>
            <input
              type="range"
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e) => setZoom(Number(e.target.value))}
            />
          </div>
          <Button name="OK" onClick={handleCropConfirm} />
        </Modal>
      )}
    </div>
  );
};

// 以下はヘルパー関数をアロー関数化したもの
const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });
};

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return null;

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return canvas.toDataURL("image/jpeg");
};

const resizeImage = async (imageSrc, targetWidth, targetHeight) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return null;

  canvas.width = targetWidth;
  canvas.height = targetHeight;
  ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

  return canvas.toDataURL("image/jpeg", 0.9);
};

export default ImageUploader;
