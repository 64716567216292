import React, { useState } from "react";
import styled from "styled-components";

import SwitchComponent from "../../../../../components/form-item/SwitchComponent";
import ArtistSearchBox from "./ArtistSearchBox";
import ProjectOfferList from "./ProjectOfferList";

const ColumnLeftStyle = styled.div`
  width: max(320px, min(38.2vw, 435.48px));
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  transition: all 0.2s ease 0s;
  height: 100%;
`;

const ProjectDMArtisList = ({ data, user, project, currentOffer }) => {
  const [valueData, setValueData] = useState(0);

  return (
    <ColumnLeftStyle>
      <div
        className="navigation-top-app-bar"
        style={{
          display: "flex",
          padding: "8px 16px",
          minHeight: "46px",
        }}
      >
        <div
          style={{
            display: "flex",
            zIndex: 10,
            width: "100%",
            justifyContent: "center",
            transition: "all .3s",
          }}
        >
          <SwitchComponent
            isTopBar={true}
            defaultValue={valueData}
            options={[
              {
                value: 0,
                label: "進行中",
              },
              {
                value: 1,
                label: "全てのスレッド",
              },
            ]}
            onChange={(e) => setValueData(e)}
          />
        </div>
      </div>
      <ArtistSearchBox />
      <ProjectOfferList
        data={data}
        user={user}
        project={project}
        currentOffer={currentOffer}
      />
    </ColumnLeftStyle>
  );
};

export default ProjectDMArtisList;
