import React, { useState } from "react";

import TitleBlock from "../../../components/block/TitleBlock";
import Tab from "../../../components/tab/Tab";

const FollowersAndFollowing = () => {
  const [activeTab, setActiveTab] = useState("フォロー中");

  // モックデータ
  const authUser = {
    following_users: [],
    blocking_users: [],
  };

  return (
    <>
      <TitleBlock title="フォロワー・フォロー中" />
      <Tab
        type="text"
        list={["フォロワー", "フォロー中"]}
        activeTab={activeTab}
        onTabChange={(tabName) => setActiveTab(tabName)}
      />
      {activeTab === "フォロー中" && (
        <>
          {authUser.following_users.map((user) => (
            <div key={user.id}>{user.name}</div>
          ))}
        </>
      )}
    </>
  );
};

export default FollowersAndFollowing;
