const leftColumnList = ({ setSelectedItem, setShowRightColumn }) => [
  { name: "ウォレット", size: "section" },
  {
    name: "支払明細書",
    onClick: () => {
      setSelectedItem("paymentDetails");
      setShowRightColumn(true);
    },
  },
  // {
  //   name: "取引履歴",
  //   alert: "未検収のやりとりが残っています",
  //   onClick: () => {
  //     setSelectedItem("transactionHistory");
  //     setShowRightColumn(true);
  //   },
  // },
  {
    name: "振込先銀行口座",
    status: "未登録",
    alert: "",
    onClick: () => {
      setSelectedItem("bankAccount");
      setShowRightColumn(true);
    },
  },
  {
    name: "クレジットカード情報",
    status: "登録済み",
    onClick: () => {
      setSelectedItem("creditCard");
      setShowRightColumn(true);
    },
  },
  { name: "利用設定", size: "section" },
  {
    name: "通知",
    onClick: () => {
      setSelectedItem("notification");
      setShowRightColumn(true);
    },
  },
  {
    name: "オファーを引き受ける基準",
    onClick: () => {
      setSelectedItem("offerConditions");
      setShowRightColumn(true);
    },
  },
  {
    name: "スケジュール",
    onClick: () => {
      setSelectedItem("schedule");
      setShowRightColumn(true);
    },
  },
  {
    name: "フォロワー・フォロー中",
    onClick: () => {
      setSelectedItem("followersAndFollowing");
      setShowRightColumn(true);
    },
  },

  { name: "アカウント情報", size: "section" },
  {
    name: "メールアドレス・パスワード",
    onClick: () => {
      setSelectedItem("emailPassword");
      setShowRightColumn(true);
    },
  },
  {
    name: "個人情報",
    status: "登録済み",
    onClick: () => {
      setSelectedItem("personalInformation");
      setShowRightColumn(true);
    },
  },

  { name: "マイサイト", size: "section" },
  {
    name: "公開範囲とURL",
    status: "公開中",
    onClick: () => {
      setSelectedItem("mySitePublic");
      setShowRightColumn(true);
    },
  },
  {
    name: "お問い合わせへの応対",
    status: "自分で",
    onClick: () => {
      setSelectedItem("mySiteContact");
      setShowRightColumn(true);
    },
  },
  {
    name: "サイトのデザイン",
    status: "フルスクリーン",
    onClick: () => {
      setSelectedItem("mySiteDesign");
      setShowRightColumn(true);
    },
  },
  {
    name: "ステートメント",
    onClick: () => {
      setSelectedItem("mySiteState");
      setShowRightColumn(true);
    },
  },
  {
    name: "サービス登録",
    status: "未登録",
    onClick: () => {
      setSelectedItem("mySiteService");
      setShowRightColumn(true);
    },
  },
  {
    name: "作品登録",
    status: "3点",
    onClick: () => {
      setSelectedItem("mySiteWorks");
      setShowRightColumn(true);
    },
  },
  {
    name: "プロフィールテキスト",
    status: "400文字",
    onClick: () => {
      setSelectedItem("mySiteText");
      setShowRightColumn(true);
    },
  },
  {
    name: "フッターのリンク設定",
    status: "5件",
    onClick: () => {
      setSelectedItem("mySiteFooter");
      setShowRightColumn(true);
    },
  },

  // { name: "マイプロフィール", size: "section" },
  // { name: "プロフィールの登録" },
  // {
  //   name: "マイサイトの作成",
  //   status: "公開中",
  //   onClick: () => {
  //     setSelectedItem("mySite");
  //     setShowRightColumn(true);
  //   },
  // },

  // {
  //   name: "本人確認書類",
  //   status: "承認済み",
  //   onClick: () => {
  //     setSelectedItem("identityVerification");
  //     setShowRightColumn(true);
  //   },
  // },
  // {
  //   name: "機密保持契約書",
  //   status: "締結済み",
  //   onClick: () => {
  //     setSelectedItem("contract");
  //     setShowRightColumn(true);
  //   },
  // },
  { name: "その他", size: "section" },
  { name: "ヘルプセンター", size: "large" },
  { name: "サインアウト", size: "large", hasBorderBottom: true },
];

export default leftColumnList;
