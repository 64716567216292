import React, { useEffect, useRef, useMemo } from "react";
import { useWavesurfer } from "@wavesurfer/react";
import Hover from "wavesurfer.js/dist/plugins/hover.esm.js";

import UserDownloaded from "./UserDownloaded";

const AudioBlock = ({ file, fileInfo, index, comment }) => {
  const containerRef = useRef(null);
  const { wavesurfer, isReady, isPlaying /*, currentTime */ } = useWavesurfer({
    container: containerRef,
    url: file?.presigned_url,
    waveColor: "#a7a8a9",
    progressColor: "#009ace",
    cursorColor: "#009ace",
    cursorWidth: 1,
    barGap: 2,
    barHeight: 1.5,
    barRadius: 4,
    barWidth: 2,
    mediaControls: false,
    height: 80,
    backend: "MediaElement",
    fillParent: true,
    dragToSeek: true,
    hideScrollbar: false,
    splitChannels: true,
    plugins: useMemo(() => [], []),
  });

  useEffect(() => {
    if (isReady && wavesurfer) {
      wavesurfer?.registerPlugin(
        Hover.create({
          lineColor: "#f0f0f0",
          lineWidth: 1,
          labelBackground: "#fcfcfc",
          labelColor: "#000",
          labelSize: "11px",
          formatTimeCallback: (time) => {
            // Custom time formatting (example)
            return `${Math.floor(time / 60)}:${Math.floor(time % 60)}`;
          },
        }),
      );
    }
  }, [isReady, wavesurfer]);

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  return (
    <div className="comment-audio-content comments-audio-block">
      <div className="mmessenger mmessenger--audio-wave mmessenger--black audio-message">
        <div className="messenger-content">
          <div className="s-audio s-audio--audio-wave s-audio--black">
            <div className={`s-audio-control`} onClick={onPlayPause}>
              {isPlaying ? (
                <span className="material-symbols-rounded c-icon-pause-audio u-fontsize-32 material-symbol-pause">
                  pause
                </span>
              ) : (
                <span className="material-symbols-rounded c-icon-play-audio u-fontsize-32 material-symbol-play">
                  play_circle
                </span>
              )}
            </div>
            <div className="video-pin-start hide">{comment?.pin_time}</div>
            <div ref={containerRef} />
          </div>
        </div>
      </div>
      <div
        className="info-item-audio-comment block-download-file"
        data-scene-title-id="scene.pk"
        data-file-id={file?.file_id}
      >
        <div className="info-audio-message">
          <div className="block-name-action-audio">
            <p className="file-name-message file-name-cmt">{file?.real_name}</p>
            <div className="block-btn-action-audio-msg">
              {file.acr_status === "3" ? (
                <button
                  className="acr-result-icon btn-finger-print active"
                  data-file-id={file?.file_id}
                >
                  <img
                    src="/static/images/scene-detail/icon-finger-print-active.svg"
                    alt="finger print"
                  />
                </button>
              ) : (
                <button className="acr-result-icon btn-finger-print">
                  <img
                    src="/static/images/scene-detail/icon-finger-print.svg"
                    alt="finger print"
                  />
                </button>
              )}
              <button className="btn-download-file">
                <span className="material-symbols-rounded scene-file-download">
                  download
                </span>
              </button>
            </div>
          </div>
          <div className="audio-type-info-msg info-message-file">
            <div className="file-info-message">
              <span>{fileInfo?.sample_rate}</span>
              <span>{fileInfo?.bit_depth}</span>
              <span>{fileInfo?.channel_type}</span>
              <span>{fileInfo?.loudness}</span>
            </div>
            <div className="peoples-downloaded-audio-msg">
              <div className="has_user_downloaded">
                <UserDownloaded file={file} type_comment="messenger" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioBlock;
