import React, { useState } from "react";

import RadioCard from "../../../components/form-item/RadioCard";
import TitleBlock from "../../../components/block/TitleBlock";

const MySiteContact = () => {
  const [checked, setChecked] = useState("matching");

  const handleChecked = (id) => {
    setChecked(id);
  };
  return (
    <div className="u-col u-w100">
      <TitleBlock
        title="お問い合わせへの応対"
        description="クライアントとのコンタクト方法を設定"
      />

      <div className="u-col u-w100 u-gap16">
        {/* <div className="u-row u-gap4">
          <div className="heading">コンタクト方法</div>
          <span className="label8 u-text-blue">[必須]</span>
        </div> */}

        <RadioCard
          name="mysite_contact"
          id="matching"
          width="128px"
          height="128px"
          showRadio
          checked={checked === "matching"}
          showImage
          onClick={() => handleChecked("matching")}
        >
          <label>自分で直接やりとり</label>
          <p className="bodytext-13">
            ご自身で直接やりとりできます。SOREMOはシステムのみを提供します。
          </p>
        </RadioCard>
        <RadioCard
          name="mysite_contact"
          id="agent"
          width="128px"
          height="128px"
          showRadio
          checked={checked === "agent"}
          showImage
          onClick={() => handleChecked("agent")}
        >
          <label>SOREMOがやりとり代行</label>
          <p className="bodytext-13">
            オーナーとは直接契約頂き、SOREMOがオーナーとのやりとりを代行します。
          </p>
        </RadioCard>
        <RadioCard
          name="mysite_contact"
          id="production"
          width="128px"
          height="128px"
          showRadio
          checked={checked === "production"}
          showImage
          onClick={() => handleChecked("production")}
        >
          <label>SOREMOから仕事を斡旋</label>
          <p className="bodytext-13">
            SOREMOがオーナーと契約します。お仕事が完了したらその対価が支払われます。
          </p>
        </RadioCard>
      </div>
    </div>
  );
};

export default MySiteContact;
