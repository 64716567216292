import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import SwitchComponent from "../../../../../components/form-item/SwitchComponent";
import { useGetCommentListQuery } from "../../../../../app/services/project";
import ProjectCommentsBlock from "./ProjectCommentBlock";
import ProjectFileListBlock from "./ProjectFileListBlock";

const FileToggleButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 20;
  &:hover {
    .material-symbols-outlined {
      color: #009ace;
    }
  }
`;

const TalkRoom = ({ user, project }) => {
  const [valueData, setValueData] = useState(0);
  const [fileOpen, setFileOpen] = useState(false);
  const { projectId } = useParams();
  let {
    data,
    // isLoading: loading,
    // isFetching,
  } = useGetCommentListQuery({
    project_id: projectId,
    type: "project",
  });

  return (
    <div
      className="project-item__content refactor"
      id="projectItemDetail"
      style={{ position: "relative" }}
    >
      <div
        className="navigation-top-app-bar"
        style={{
          display: "flex",
          padding: "8px 16px",
          minHeight: "46px",
        }}
      >
        <div
          style={{
            display: "flex",
            zIndex: 10,
            width: fileOpen ? "calc(100% - 267px)" : "100%",
            justifyContent: "center",
            transition: "all .3s",
          }}
        >
          <SwitchComponent
            isTopBar={true}
            defaultValue={valueData}
            options={[
              {
                value: 0,
                label: "進行中のみ",
              },
              {
                value: 1,
                label: "全メッセージ",
              },
            ]}
            onChange={(e) => setValueData(e)}
          />
        </div>
      </div>
      <FileToggleButton onClick={(e) => setFileOpen(!fileOpen)}>
        <span className="material-symbols-outlined">home_storage</span>
      </FileToggleButton>
      <ProjectCommentsBlock
        data={data}
        user={user}
        project={project}
        fileOpen={fileOpen}
      />
      <ProjectFileListBlock
        data={data}
        user={user}
        project={project}
        fileOpen={fileOpen}
      />
    </div>
  );
};

export default TalkRoom;
