import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { linkList } from "../../../constant/links";

const GlobalMenu = styled.div`
  height: 64px;
  position: sticky;
  transition: top 0.2s;
  top: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 32px);
  margin-inline: auto;
  gap: 25px;
`;

const Button = styled.div`
  font-size: 32px;
  border-radius: 13px;
  box-shadow: 2px 4px 10px 0px #e5e5e5;
  :hover {
    color: #a7a8a9;
  }
`;

const Text = styled.div`
  font-size: 13px;
  margin-top: 5px;
  &:hover,
  &:visited {
    color: #009ace;
  }
`;

const UnauthorizedHeader = () => {
  return (
    <GlobalMenu>
      <a href={linkList.app.creator_index}>
        <Text>GALLERY</Text>
      </a>
      <Link to={linkList.account.accounts_login}>
        <Button>
          <i class="icon icon--sicon-user"></i>
        </Button>
      </Link>
    </GlobalMenu>
  );
};

export default UnauthorizedHeader;
