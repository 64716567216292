export const generateBottomNavBarLink = ({
  isAdminOrProducer,
  user,
  project,
}) => {
  let output = [];

  if (isAdminOrProducer) {
    output.push({
      id: 0,
      name: "home",
      url: `/top/project/${project.product_id}`,
      icon: "home",
      count: project?.updated_scene_titles?.length || 0,
      label: "ホーム",
    });

    output.push({
      id: 1,
      name: "talkroom",
      url: `/top/project/${project.product_id}/project-comment`,
      icon: "forum",
      count: project?.unloaded_comment?.length || 0,
      label: "トークルーム",
    });

    if (project?.project_user?.position !== "client") {
      output.push({
        id: 2,
        name: "dm",
        url: `/top/project/${project.product_id}/dm/${project?.first_offer_id}`,
        icon: "mail",
        count: project?.unread_message_count?.length || 0,
        label: "DM",
      });
    }
  } else {
    if (user?.role === "master_admin") {
      output.push({
        id: 0,
        name: "dm",
        url: `/top/project/${project.product_id}/messenger_waiting/${project?.first_offer_id}`,
        icon: "mail",
        count: project?.unread_message_count?.length || 0,
        label: "DM",
      });
    } else {
      output.push({
        id: 0,
        name: "dm",
        url: `/top/project/${project?.product_scene_list[0].id}/direct_inbox/${project?.first_offer_id}`,
        icon: "mail",
        count: project?.unread_message_count?.length || 0,
        label: "DM",
      });
    }
  }

  return output;
};
