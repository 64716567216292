import React, { useState } from "react";
import styled from "styled-components";

import CommentContentWithParentDM from "./CommentContentWithParentDM";
import CommentContentWithoutParentDM from "./CommentContentWithoutParentDM";
import MessageInfoBlock from "../../../project-comment/components/MessageInfoBlock"; // assuming you have this component as well

// Styled components
const MessageReceivedContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;

  .dropdown.dropdown-comment-new {
    display: none;
    position: relative;
  }

  &:hover {
    .dropdown.dropdown-comment-new {
      display: block;
      position: relative;
    }
  }
`;

const MenuContainerBlock = styled.ul`
  ${(props) => (props?.showMenu ? `display: block;` : `display: none;`)}
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: #fff;
  min-width: 200px;
  -webkit-box-shadow: 2px 4px 8px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 8px 3px rgba(0, 0, 0, 0.1);
  border-color: #fff;
  padding: 0 8px;

  li {
    border-bottom: 1px solid #f0f0f0;
    padding: 8px 0;
    list-style: none;
  }
`;

const MenuItemStyle = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor: pointer;

  .txt-item-comment {
    font-weight: 300;
    font-size: 13px;
    font-style: normal;
    line-height: 200%;
  }

  &:hover {
    color: #009ace;

    .material-symbols-rounded {
      color: #009ace;
    }
  }
`;

const MessageReceivedBlock = ({ comment, user, currentUserId }) => {
  const [showMenu, setShowMenu] = useState(false);

  const allowAction = true;

  return (
    <div className="mmessage-container refactor">
      {/* Message Container */}
      <MessageReceivedContainer>
        {comment?.parent_id || comment?.parent ? (
          <CommentContentWithParentDM comment={comment} user={user} />
        ) : (
          <CommentContentWithoutParentDM
            comment={comment}
            user={user}
            is_received={true}
          />
        )}

        {/* Dropdown menu for actions */}
        {allowAction && (
          <div className="mmessage-info">
            <div className="dropdown dropdown-comment-new">
              <div
                onClick={(e) => setShowMenu(!showMenu)}
                style={{ cursor: "pointer" }}
              >
                <span class="material-symbols-outlined">more_horiz</span>
              </div>
              <MenuContainerBlock showMenu={showMenu}>
                <li>
                  <MenuItemStyle>
                    <span className="txt-item-comment">
                      {comment?.resolved ? "進行中に戻す" : "解決済みにする"}
                    </span>
                    <span className="material-symbols-rounded img-resolve-comment">
                      check_circle
                    </span>
                  </MenuItemStyle>
                </li>

                {/* Reply Option */}
                <li>
                  <MenuItemStyle>
                    <span className="txt-item-comment txt-reply-comment">
                      返信
                    </span>
                    <span className="material-symbols-rounded img-reply-comment">
                      reply
                    </span>
                  </MenuItemStyle>
                </li>

                {/* Edit/Delete Options for the Current User */}
                {comment?.is_same_role && comment.user_id === currentUserId && (
                  <>
                    <li>
                      <MenuItemStyle>
                        <span className="txt-item-comment txt-edit-comment">
                          コメントを編集
                        </span>
                        <span className="material-symbols-rounded img-edit-comment">
                          edit
                        </span>
                      </MenuItemStyle>
                    </li>
                    <li>
                      <MenuItemStyle>
                        <span className="txt-item-comment txt-green">削除</span>
                        <span className="material-symbols-rounded">delete</span>
                      </MenuItemStyle>
                    </li>
                  </>
                )}
              </MenuContainerBlock>
            </div>
            <MessageInfoBlock
              comment={comment}
              user={user}
              typeInfo={"received"}
            />
          </div>
        )}
      </MessageReceivedContainer>
    </div>
  );
};

export default MessageReceivedBlock;
