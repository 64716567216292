import React, { useEffect, useState } from "react";

import RadioCard from "../../../components/form-item/RadioCard";
import Input from "../../../components/form-item/Input";
import TitleBlock from "../../../components/block/TitleBlock";

const Notification = () => {
  const [authUser, setAuthUser] = useState({
    setting_mail: "now",
    noti_hours: "10:00",
  }); // 初期データ
  const [checked, setChecked] = useState(authUser.setting_mail);
  const [time, setTime] = useState(authUser.noti_hours);

  // サーバーからのデータ取得
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/auth-user/1/");
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        const result = data.result || {};
        setAuthUser({
          setting_mail: result.setting_mail || "now",
          noti_hours: result.noti_hours || "10:00",
        });
        setChecked(result.setting_mail || "now");
        setTime(result.noti_hours || "10:00");
      } catch (err) {
        // エラーハンドリング (必要に応じてUIにエラーを表示)
      }
    };

    fetchUserData();
  }, []);

  // サーバーへの同期関数
  const updateUserData = async (field, value) => {
    try {
      const response = await fetch("http://localhost:8000/api/auth-user/1/", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ [field]: value }),
      });

      if (!response.ok) {
        throw new Error("Failed to update user data");
      }

      // サーバー応答の利用を削除
      await response.json();

      setAuthUser((prev) => ({ ...prev, [field]: value })); // ローカルデータを更新
    } catch (err) {
      // エラーハンドリング (必要に応じてUIにエラーを表示)
    }
  };

  // ラジオボタンの変更処理
  const handleRadioChange = (newChecked) => {
    setChecked(newChecked);
    updateUserData("setting_mail", newChecked); // サーバーに即時反映
  };

  // 時刻入力の変更処理
  const handleTimeChange = (newTime) => {
    setTime(newTime);
    updateUserData("noti_hours", newTime); // サーバーに即時反映
  };

  return (
    <>
      <TitleBlock
        title="通知"
        description="メッセージごとのメール配信タイミングを設定"
      />
      <div className="u-col u-gap16">
        <RadioCard
          name="setting_mail"
          id="now"
          width="128px"
          height="128px"
          showRadio
          checked={checked === "now"}
          showImage
          onClick={() => handleRadioChange("now")}
        >
          <label>今すぐ</label>
          <p className="bodytext-13">15分ごとに配信</p>
        </RadioCard>

        <RadioCard
          name="setting_mail"
          id="on"
          width="128px"
          height="128px"
          showRadio
          checked={checked === "on"}
          showImage
          onClick={() => handleRadioChange("on")}
        >
          <label className="u-w100">1日1回まとめて配信</label>
          <Input
            label=""
            type="time"
            value={time}
            width="112px"
            prepend="配信時間"
            onChange={(e) => handleTimeChange(e.target.value)}
          />
        </RadioCard>

        <RadioCard
          name="setting_mail"
          id="off"
          width="128px"
          height="128px"
          showRadio
          checked={checked === "off"}
          showImage
          onClick={() => handleRadioChange("off")}
        >
          <label>オフ</label>
          <p className="bodytext-13">配信なし</p>
        </RadioCard>
      </div>
    </>
  );
};

export default Notification;
