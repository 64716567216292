import React from "react";
import dayjs from "dayjs";

const BottomPanel = ({ project, user }) => {
  let currentHeartRate = Math.floor(
    (project.current_heart * 100) / project.max_scene,
  );
  const formattedDate = dayjs(project.modified).format("M/D HH:mm");

  return (
    <div className="sproject-meta">
      <div className="u-row-between u-w100 u-ptb8 u-gap8">
        <div className="u-row u-gap8">
          <span className="bodytext-13 u-text-blue">{currentHeartRate}%</span>
          <span
            className="bodytext-13 u-text-light-gray cannot-check"
            data-max-scene={project.max_scene}
          >
            ({project.current_heart}/{project.max_scene})
          </span>
          {(user.role === "admin" || user.role === "master_client") &&
          project?.count_batch_number_project ? (
            <span className="c-budge u-row-center">
              {project?.count_batch_number_project < 100
                ? project?.count_batch_number_project
                : "99+"}
            </span>
          ) : null}
        </div>
        <div className="sproject-date-time sproject-project-time">
          <div
            className="sproject-time"
            style={{
              fontSize: "11px",
              color: "#a7a8a9 !important",
            }}
          >
            {formattedDate}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomPanel;
