import React from "react";

const MessengerTab = () => {
  return (
    <div
      className="project-tab project-tab-messenger refactor"
      id="projectTabMessenger"
      data-tab="messenger"
    >
      <div className="tab--messenger-artist refactor">
        {/* Messenger content can be added here */}
      </div>
    </div>
  );
};

export default MessengerTab;
