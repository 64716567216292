import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { generateBottomNavBarLink } from "../common/utils";
import NavigationLink from "./NavigationLink";

const BlockNavigationBar = styled.div`
  display: flex;
  height: 80px;
  padding: 16px 16px 0px;
  position: fixed;
  ${(props) =>
    props.scrollState === "init"
      ? `bottom: 0;`
      : props.scrollState === "down"
        ? `bottom: -80px;`
        : `bottom: 0;`}
  -webkit-transition: bottom .2s ease;
  transition: bottom 0.2s ease;
  z-index: 500;
  background: rgba(255, 255, 255, 0.16);
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  margin-bottom: 0;
  width: 100%;

  .navigation-bar {
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: max(320px, min(100%, 640px));
    list-style-type: none;
    padding: 0;
  }
`;

const BottomNavBar = ({ user, project, current = "home" }) => {
  const shouldHideNavBar =
    (user?.role === "master_client" && !project.max_scene > 0) ||
    project?.admins.find((adminInfo) => adminInfo.id === user.id) ||
    user?.role === "master_client";
  // Check if the current user is authorized to see certain parts of the navbar
  const isAdminOrProducer =
    project?.admins.find((adminInfo) => adminInfo.id === user.id) ||
    user?.role === "master_client";
  const navbarLinkList =
    generateBottomNavBarLink({
      isAdminOrProducer,
      user,
      project,
    }) || [];
  // Access the scroll state from Redux - you don't have to trigger addEventListener everywhere anymore, only need in global Wraper
  const scrollState = useSelector((state) => {
    return state.layout;
  });

  return (
    <BlockNavigationBar
      scrollState={scrollState}
      className={`${shouldHideNavBar ? "hide" : ""}`}
    >
      <ul
        className={`navigation-bar ${!isAdminOrProducer ? "navigation-bar-custom" : ""}`}
      >
        {navbarLinkList.map((linkInfo) => {
          return <NavigationLink current={current} linkInfo={linkInfo} />;
        })}
      </ul>
    </BlockNavigationBar>
  );
};

export default BottomNavBar;
