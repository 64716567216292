import React from "react";
import styled from "styled-components";

import ItemSentBlock from "./ItemSentBlock";

const MessengerTextContainer = styled.div`
  display: flex;
  ${(props) =>
    props.is_received
      ? `background-color: #fcfcfc;`
      : `background: var(--soremo-bg-blue);`}
  border: 1px solid rgba(0, 154, 206, 0.1);
  padding: 8px;
  border-radius: 6px;
`;

const CommentContentWithoutParent = ({ comment, user, is_received }) => {
  const isProduct = ["product", "messenger_owner", "messenger"].includes(
    comment?.type,
  );

  return (
    <MessengerTextContainer is_received={is_received}>
      <div className="mmessenger mmessenger--text mmessenger--black">
        <div className="messenger-content">
          {!isProduct && comment?.pin_time ? (
            <div
              className="s-audio s-audio--audio s-audio--black"
              data-scene-id={comment?.scene?.scene_id}
            >
              <div className="s-audio-icon">
                <div className="s-audio-control video-pin-time">
                  <span className="material-symbols-rounded c-icon-play-audio u-fontsize-32 material-symbol-play">
                    play_circle
                  </span>
                  <span className="material-symbols-rounded c-icon-pause-audio u-fontsize-32 material-symbol-pause">
                    pause
                  </span>
                </div>
                <div className="s-audio-time video-pin-start">
                  {comment?.pin_time}
                </div>
              </div>
              <div className="s-audio-text">{comment?.comment}</div>
            </div>
          ) : (
            <div className="s-text s-text--black">{comment?.comment}</div>
          )}
        </div>
      </div>
      {comment.fileList?.length > 0 &&
        comment.fileList.map((file, indexFile) => {
          if (!file.folder_id) {
            return (
              <ItemSentBlock comment={comment} file={file} index={indexFile} />
            );
          } else {
            return <></>;
          }
        })}
      {comment?.folders?.length > 0 &&
        comment?.folders.map((folder, indexFolder) => {
          if (folder.folder_id) {
            return (
              <ItemSentBlock
                comment={comment}
                folder={folder}
                index={indexFolder}
              />
            );
          } else {
            return <></>;
          }
        })}
    </MessengerTextContainer>
  );
};

export default CommentContentWithoutParent;
