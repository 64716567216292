import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import profileReducer from "./actions/profile/profile";
import scrollReducer from "./actions/layout/scroll";
import { profileApi, loginApi } from "./services/auth";
import { listProjectApi } from "./services/project";
import authUserReducer from "./actions/profile/authuser";
import mileageReducer from "./actions/profile/mileageSlice";

export const store = configureStore({
  reducer: {
    [profileApi.reducerPath]: profileApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [listProjectApi.reducerPath]: listProjectApi.reducer,
    profile: profileReducer,
    layout: scrollReducer,
    AuthUser: authUserReducer,
    mileage: mileageReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileApi.middleware,
      loginApi.middleware,
      listProjectApi.middleware,
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
