import React from "react";

const ProgressBar = ({ project, typePage }) => {
  let doneCount = project?.current_heart || 0;
  let sceneCount = project?.current_scene;
  let maxScene = project?.max_scene;
  let currentHeartRate = Math.floor((doneCount * 100) / maxScene);
  let currentSceneRate = Math.floor((sceneCount * 100) / maxScene);

  return (
    <div
      className="progress"
      data-done-scene={doneCount}
      data-current-scene={sceneCount}
      data-type={typePage}
    >
      <span className="p-tooltip p-tooltip--black p-tooltip--total">
        {maxScene}
      </span>

      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${currentHeartRate}%` }}
        aria-valuenow={currentHeartRate}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <span className="p-tooltip p-tooltip--blue">
          <i className="icon icon--sicon-heart"></i>
          <span>{doneCount.toFixed(0)}</span>
        </span>
      </div>

      <div
        className="progress-bar progress-bar-black"
        role="progressbar"
        style={{ width: `${currentSceneRate}%` }}
        aria-valuenow={currentSceneRate}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span className="p-tooltip p-tooltip--black p-tooltip_two">
          <i className="icon icon--sicon-heart-o"></i>
          <span>{(sceneCount - doneCount).toFixed(0)}</span>
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
