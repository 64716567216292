import React, { useMemo, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useWavesurfer } from "@wavesurfer/react";
import Hover from "wavesurfer.js/dist/plugins/hover.esm.js";

import { renderPdfThumbnail } from "../../../../../../../common/pdf";

const VideoStyle = styled.video`
  width: auto;
  object-fit: cover;
  background: #000;
  display: inline-block;
  vertical-align: middle;
  border-radius: 6px;
`;

const ImageStyle = styled.img`
  height: 256px;
  width: auto;
  max-width: unset;
`;

const AudioStyle = styled.div`
  min-width: 300px;
`;

const VideoThumbnail = ({ scene }) => {
  const containerRef = useRef(null);
  const [thumbnail, setThumbnail] = useState(false);

  const { wavesurfer, isReady, isPlaying /*, currentTime */ } = useWavesurfer({
    container: containerRef,
    url: scene?.movie,
    waveColor: "#a7a8a9",
    progressColor: "#009ace",
    cursorColor: "#009ace",
    cursorWidth: 1,
    barGap: 2,
    barHeight: 1.5,
    barRadius: 4,
    barWidth: 2,
    mediaControls: false,
    height: 80,
    backend: "MediaElement",
    fillParent: true,
    dragToSeek: true,
    hideScrollbar: false,
    splitChannels: true,
    plugins: useMemo(() => [], []),
  });

  useEffect(() => {
    if (isReady && wavesurfer) {
      wavesurfer?.registerPlugin(
        Hover.create({
          lineColor: "#f0f0f0",
          lineWidth: 1,
          labelBackground: "#fcfcfc",
          labelColor: "#000",
          labelSize: "11px",
          formatTimeCallback: (time) => {
            // Custom time formatting (example)
            return `${Math.floor(time / 60)}:${Math.floor(time % 60)}`;
          },
        }),
      );
    }
  }, [isReady, wavesurfer]);

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  // Event handlers for hover to play video
  const onVideoMouseEnter = (e) => {
    if (scene?.movie?.includes(".mp4")) {
      const videoElement = e.target;
      if (videoElement) {
        try {
          videoElement.play(); // Play video on hover
        } catch (e) {
          //
        }
      }
    }
  };

  const onVideoMouseLeave = (e) => {
    if (scene?.movie?.includes(".mp4")) {
      const videoElement = e.target;
      if (videoElement) {
        try {
          videoElement.pause(); // Pause video when mouse leaves
        } catch (e) {
          //
        }
      }
    }
  };

  // Function to load PDF and render it to the canvas
  const loadPdfThumbnail = async (pdfUrl) => {
    let output = await renderPdfThumbnail(pdfUrl);
    setThumbnail(output);
  };

  // If the scene is a PDF file, we need to render the first page as a canvas
  useEffect(() => {
    if (scene?.movie?.includes(".pdf")) {
      loadPdfThumbnail(scene.movie);
    }
  }, [scene.movie]);

  if (scene?.is_out_of_date) {
    return (
      <div
        className="thumb-schedule-video"
        style={{
          backgroundImage: `url(${scene?.thumbnail})`,
          mixBlendMode: "luminosity",
          opacity: 0.5,
        }}
      />
    );
  } else if (scene?.movie?.includes(".pdf")) {
    return (
      <ImageStyle
        src={thumbnail?.url}
        height={256}
        width={(thumbnail?.width * 256) / thumbnail?.height}
      />
    );
  } else if (scene?.movie?.includes(".mp3")) {
    return (
      <AudioStyle>
        <div ref={containerRef} />

        <button onClick={onPlayPause}>{isPlaying ? "Pause" : "Play"}</button>
      </AudioStyle>
    );
  } else {
    return (
      <VideoStyle
        width="100%"
        height="256px"
        poster={scene?.thumbnail}
        preload={scene?.movie?.includes(".mp4") ? "metadata" : "none"}
        onMouseEnter={onVideoMouseEnter} // Start playing on hover
        onMouseLeave={onVideoMouseLeave} // Pause on mouse leave
      >
        <source src={scene?.movie} type="video/mp4" />
      </VideoStyle>
    );
  }
};

export default VideoThumbnail;
