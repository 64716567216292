import React from "react";
import styled from "styled-components";

import OfferOne from "./offer/OfferOne";
import OfferTwo from "./offer/OfferTwo";

const ListOfferProject = styled.div`
  height: calc(100vh - 330px);
  overflow: auto;
`;

const ProjectOfferList = ({ data, user, project, currentOffer }) => {
  return (
    <ListOfferProject>
      <div className="list--offers-project">
        {data?.list_offer?.map((offer) => {
          if (offer.type_offer === "1") {
            return (
              <OfferOne
                offer={offer}
                user={user}
                project={project}
                currentOffer={currentOffer}
              />
            );
          } else {
            return (
              <OfferTwo
                offer={offer}
                user={user}
                project={project}
                currentOffer={currentOffer}
              />
            );
          }
        })}
      </div>
    </ListOfferProject>
  );
};

export default ProjectOfferList;
