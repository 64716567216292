import React, { useRef } from "react";
import styled from "styled-components";

const DropdownIcon = styled.div`
  font-family: "Material Symbols Rounded";
  font-size: 24px;
  color: var(--soremo-light-gray);
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SelectContainer = styled.select`
  cursor: pointer;
  appearance: none;

  &:hover + ${DropdownIcon} {
    color: var(--soremo-blue);
  }
`;

const Select = ({
  defaultValue = "", // Set a default value for the select
  type = "select",
  label = "",
  options = [{ value: "", name: "" }],
}) => {
  const selectRef = useRef(null);

  const handleIconClick = () => {
    selectRef.current?.click();
  };

  if (type !== "select") return null;

  return (
    <div className="u-col u-w100 u-relative">
      {label && <label>{label}</label>}
      <div className="u-row u-w100 u-relative">
        <SelectContainer ref={selectRef} defaultValue={defaultValue}>
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          ))}
        </SelectContainer>
        <DropdownIcon onClick={handleIconClick}>arrow_drop_down</DropdownIcon>
      </div>
    </div>
  );
};

export default Select;
