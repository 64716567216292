import React from "react";
import styled from "styled-components";

import RatingComponent from "../../../../../../../components/form-item/RatingComponent";
import VideoThumbnail from "./VideoThumbnail";

const CVideoStyled = styled.div`
  flex: 0 0;
  padding: 0 8px 0 0;
`;
const ProjectDeliveryItemContent = styled.div`
  min-width: 70px;
  position: relative;
`;
const CSMeta = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #a7a8a9;
  line-height: 1;
  justify-content: space-between;
`;

const CSThumb = styled.div`
  background-size: cover;
  position: relative;
  border-radius: 6px;
`;

const CSHeading = styled.div`
  display: flex;
  align-items: center;
  line-height: 200%;
  margin: 0;

  .cvideo__title {
    color: #000;
    cursor: pointer;
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
  }
`;

const CSDateTime = styled.div`
  margin: 0;
  padding: 0;
  color: #a7a8a9;
  margin-left: auto;
  font-size: 8px;
  display: flex;
  align-items: center;
  padding-top: 7px;
`;

const SceneBlock = ({ scene, user, project_id }) => {
  return (
    <CVideoStyled>
      <ProjectDeliveryItemContent>
        <CSMeta>
          {!scene?.is_out_of_date && (
            <>
              {scene?.status !== "5" && scene?.status !== "6" ? (
                <div
                  title=""
                  className={`project-chapter-video-done ${user?.role === "admin" || !scene?.is_out_of_date ? "cannot-check" : ""}`}
                >
                  <span className="material-symbols-rounded">check_circle</span>
                </div>
              ) : (
                <div
                  title=""
                  className={`project-chapter-video-undone ${!scene?.is_out_of_date ? "cannot-check" : ""}`}
                >
                  <span className="material-symbols-rounded u-text-blue">
                    check_circle
                  </span>
                </div>
              )}

              <div
                className="cvideo__rating"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RatingComponent
                  currentRating={scene?.rating}
                  canRate={false}
                />
              </div>
            </>
          )}
        </CSMeta>

        <CSThumb>
          <VideoThumbnail scene={scene} />
        </CSThumb>

        <CSHeading
          onClick={() =>
            (window.location = `/top/project/${project_id}/scene/${scene?.scene_id}?chapter_id=${scene?.product_scene_id}`)
          }
        >
          <div className="cvideo__title" style={{ paddingLeft: 0 }}>
            {scene?.name}
          </div>

          {/* {!viewOnly && (
            <div className="scene-title__action">
              <a className="scene-title-button scene-title__move" href="javascript:void(0)">
                <i className="fas fa-arrows-alt"></i>
              </a>
              {role === 'admin' && (
                <a className="scene-title-button scene-title__delete" href="javascript:void(0)">
                  <i className="icon icon--sicon-trash"></i>
                </a>
              )}
              <a className="scene-title-button scene-title__edit" href="javascript:void(0)">
                <i className="icon icon--sicon-pencil"></i>
              </a>
            </div>
          )} */}
        </CSHeading>

        <CSDateTime>
          {scene?.movie_name ? (
            scene?.is_out_of_date ? (
              <div className="cvideo__date" style={{ fontSize: "11px" }}>
                配信予定: {scene?.is_out_of_date}
              </div>
            ) : (
              <div className="cvideo__date" style={{ fontSize: "11px" }}>
                {scene?.is_out_of_date_st
                  ? scene?.is_out_of_date_st
                  : "No date available"}
              </div>
            )
          ) : (
            <div className="release-soon" style={{ fontSize: "11px" }}>
              {scene?.is_out_of_date ? "まもなくリリース" : ""}
            </div>
          )}
        </CSDateTime>
      </ProjectDeliveryItemContent>
    </CVideoStyled>
  );
};

export default SceneBlock;
