import React, { useState } from "react";
import styled, { css } from "styled-components";

const sharedStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.05);
  color: #a7a8a9;
  padding: 0;
  margin: 0;
`;

const SwitchWrapper = styled.div`
  ${sharedStyles}
  width: 100%;
`;

const SwitchWrapperTopBar = styled.div`
  ${sharedStyles}
  width: clamp(320px - 24px, 100% - 80px, 640px);
  height: 30px;
`;

const OptionSpan = styled.span`
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-radius: 6px;
  line-height: 100%;
  transition: 0.2s;
  padding: 4px 0;
  margin: 1px 1px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }

  &.active {
    border: 1px solid #f0f0f0;
    cursor: default;
    background-color: #a7a8a9;
    color: #fff;
  }
`;

const SwitchComponent = ({
  defaultValue,
  options,
  onChange,
  isTopBar = false,
}) => {
  const [value, setValue] = useState(defaultValue);

  const Wrapper = isTopBar ? SwitchWrapperTopBar : SwitchWrapper;

  return (
    <Wrapper className="form-check custom-switch custom-switch-new">
      {options.map((option) => (
        <OptionSpan
          key={option.value}
          className={`text-item bodytext text-center ${option.value === value ? "active" : ""}`}
          onClick={() => {
            setValue(option.value);
            onChange(option.value);
          }}
        >
          {option.label}
        </OptionSpan>
      ))}
    </Wrapper>
  );
};

export default SwitchComponent;
