import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledStatusLabel = styled.div.attrs({ className: "u-row u-gap4" })`
  width: fit-content;
  font-size: ${({ size }) => size};
  line-height: 1;
  color: ${({ color }) =>
    color === "border"
      ? "#000000"
      : color === "blue"
        ? "#009ace"
        : color === "light-gray"
          ? "#a7a8a9"
          : "inherit"};
  border-radius: 4px;
  border: 1px solid
    ${({ color }) => (color === "border" ? "#f0f0f0" : "transparent")};
  padding: 4px 8px;
  background-color: ${({ color }) =>
    color === "border" ? "#fcfcfc" : "inherit"};
`;

const StatusLabel = ({
  label,
  color = "border",
  size = "11px",
  leftIcon,
  rightIcon,
}) => {
  return (
    <>
      <StyledStatusLabel color={color} size={size}>
        {leftIcon && (
          <span
            className="material-symbols-rounded"
            style={{ fontSize: "24px" }}
          >
            {leftIcon}
          </span>
        )}
        {label}
        {rightIcon && (
          <span
            className="material-symbols-rounded"
            style={{ fontSize: "24px" }}
          >
            {rightIcon}
          </span>
        )}
      </StyledStatusLabel>
    </>
  );
};

StatusLabel.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf(["blue", "light-gray", "border"]),
  size: PropTypes.oneOf(["11px", "8px"]),
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
};

export default StatusLabel;
