import React, { useState, useEffect, useRef } from "react";
import { Rnd } from "react-rnd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion"; // 追加

import ListItem from "../../components/list-item/ListItem";
import NavigateBefore from "../../components/navigate/NavigateBefore";
import UserItem from "../../components/user-item/UserItem";
import MileageBlock from "./components/MileageBlock";
import TransactionStatus from "./components/TransactionStatus";
import leftColumnList from "./leftColumnList"; // Adjust the path as needed
import rightColumnList from "./rightColumnList"; // Adjust the path as needed

// Styled components
const MyPageContainer = styled.div`
  width: 100%;
  // height: calc(100vh + 160px);
  height: 100dvh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: ${(props) => (props.scrollState === "down" ? 0 : 64)}px;
  transition: margin-top 0.2s;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  position: relative;
  height: 100dvh;
  overflow-y: auto;

  @media (min-width: 640px) {
    width: clamp(320px, 38.2vw, 1140px * 0.382);
  }
`;

const RightColumn = styled.div.attrs({
  className: "u-w100 u-relative",
})`
  max-width: 756px;
  padding: ${(props) => (props.isMobile ? "56px 16px 64px" : "24px 16px 64px")};
  margin-inline: auto;
  height: 100dvh;
  overflow-y: auto;
`;

const MyPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [showRightColumn, setShowRightColumn] = useState(false);
  const [leftColumnWidth, setLeftColumnWidth] = useState(
    "clamp(320px, 38.2vw, calc(1140px * 0.382))",
  );
  const scrollState = useSelector((state) => {
    return state.layout;
  });

  // 以前のウィンドウ幅を追跡するためのrefを追加
  const previousWidth = useRef(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const isCurrentlyMobile = currentWidth < 640;

      // 幅が変化した場合のみ状態を更新
      if (previousWidth.current !== currentWidth) {
        setIsMobile(isCurrentlyMobile);
        setShowRightColumn(!isCurrentlyMobile);
        previousWidth.current = currentWidth;
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // アニメーションのバリアントを定義
  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction > 0 ? "-100%" : "100%",
      opacity: 0,
    }),
  };

  const direction = showRightColumn ? 1 : -1;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showRightColumn]);

  const renderListItems = (items) => {
    return items.map((item) => (
      <ListItem
        key={item.name}
        name={item.name}
        status={item.status}
        alert={item.alert}
        onClick={item.onClick}
        size={item.size}
      />
    ));
  };

  return (
    <>
      <MyPageContainer scrollState={scrollState}>
        {isMobile ? (
          <AnimatePresence initial={false} custom={direction}>
            {!showRightColumn ? (
              <motion.div
                key="left"
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
                style={{ position: "absolute", width: "100%" }}
              >
                <LeftColumn style={{ overflowY: "none" }}>
                  <>
                    <UserItem size={144} editable={true} />
                    <MileageBlock />
                    <TransactionStatus />
                  </>
                  {renderListItems(
                    leftColumnList({ setSelectedItem, setShowRightColumn }),
                  )}
                </LeftColumn>
              </motion.div>
            ) : (
              <motion.div
                key="right"
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ type: "tween", duration: 0.2, ease: "easeOut" }}
                style={{ position: "absolute", width: "100%" }}
              >
                <RightColumn isMobile={isMobile}>
                  <NavigateBefore onClick={() => setShowRightColumn(false)} />
                  {rightColumnList[selectedItem]}
                </RightColumn>
              </motion.div>
            )}
          </AnimatePresence>
        ) : (
          <>
            <Rnd
              minWidth={320}
              maxWidth={640}
              enableResizing={{ right: true }}
              disableDragging={true}
              onResize={(e, direction, ref) => {
                setLeftColumnWidth(ref.offsetWidth);
              }}
              style={{ position: "relative" }}
            >
              <LeftColumn style={{ width: leftColumnWidth, overflowY: "none" }}>
                <div className="resize-handle"></div>
                {renderListItems(
                  leftColumnList({ setSelectedItem, setShowRightColumn }),
                )}
              </LeftColumn>
            </Rnd>
            <RightColumn>
              {selectedItem === null && (
                <>
                  <UserItem size={144} editable={true} />
                  <MileageBlock />
                  <TransactionStatus />
                </>
              )}
              {rightColumnList[selectedItem]}
            </RightColumn>
          </>
        )}
      </MyPageContainer>
    </>
  );
};

export default MyPage;
