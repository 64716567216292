import React from "react";
import styled from "styled-components";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js"; // 日本語ロケールのインポート
import "./flatpickr_soremo.css";

// styled-components
const StyledIcon = styled.div.attrs({
  className: "material-symbols-rounded",
})`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);

  color: var(--soremo-light-gray);

  &:hover {
    color: var(--soremo-light-gray);
  }
`;

const StyledSearchIcon = styled.div.attrs({
  className: "material-symbols-rounded",
})`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);

  color: var(--soremo-light-gray);

  &:hover {
    color: var(--soremo-light-gray);
  }
`;

const Input = ({
  label = "",
  option = "",
  type = "text",
  autocomplete = "",
  placeholder = "",
  inputmode = "",
  maxlength = "",
  width = "100%",
  prepend = "",
  append = "",
  alert = "",
  name,
  value,
  onChange,
}) => {
  if (
    ![
      "text",
      "tel",
      "email",
      "number",
      "date",
      "search",
      "time",
      "password",
    ].includes(type)
  )
    return null;

  const isRequired = option.includes("必須") || option.includes("required");
  const isOptional = option.includes("任意") || option.includes("optional");
  const labelClass = `label8 u-pl8 ${isRequired ? "u-text-blue" : isOptional ? "u-text-light-gray" : ""}`;
  const containerClass =
    width === "100%" ? "u-col u-w100 u-relative" : "u-col u-fill u-relative";

  return (
    <div className={containerClass}>
      {label && (
        <label>
          {label}
          <span className={labelClass}>{option}</span>
        </label>
      )}
      <div className="u-row u-w100 u-relative">
        {type === "time" ? (
          <StyledIcon>schedule</StyledIcon>
        ) : type === "search" ? (
          <StyledSearchIcon>search</StyledSearchIcon>
        ) : type === "date" ? (
          <StyledIcon>event</StyledIcon>
        ) : null}
        {prepend && <span className="u-pr8">{prepend}</span>}

        {/* 日付選択の場合 */}
        {type === "date" ? (
          <Flatpickr
            options={{
              dateFormat: "Y年n月j日（D）",
              locale: Japanese,
              disableMobile: true,
              maxDate: "today",
            }}
            value={value}
            onChange={(date) => {
              onChange({ target: { name, value: date[0] } });
            }}
            className="u-fill"
            placeholder={placeholder}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            autocomplete={autocomplete}
            inputmode={inputmode}
            maxlength={maxlength}
            style={{ width }}
            className="u-fill"
            name={name}
            value={value}
            onChange={onChange}
          />
        )}
        {append && <span className="u-pl8">{append}</span>}
      </div>
      {alert && (
        <p
          className="bodytext-11 u-text-green u-absolute"
          style={{ bottom: "-20px" }}
        >
          {alert}
        </p>
      )}
    </div>
  );
};

export default Input;
