import React from "react";
import styled from "styled-components";

const StyledProjectSetting = styled.div`
  display: none;
`;

const ProjectSetting = () => {
  return (
    <StyledProjectSetting>
      <div className="project-setting__filter">
        <div className="project-setting__filter-item active" data-show="owner">
          詳細
        </div>
        <div className="project-setting__filter-item" data-show="director">
          詳細
        </div>
        <div className="project-setting__filter-item" data-show="staff">
          スタッフ
        </div>
      </div>
      <div className="setting-content-tab">
        {/* ajax will append data here */}
      </div>
    </StyledProjectSetting>
  );
};

export default ProjectSetting;
