import React from "react";

import AudioBlock from "./comment-block/AudioBlock";
import ImageBlock from "./comment-block/ImageBlock";
import VideoBlock from "./comment-block/VideoBlock";
import DocumentBlock from "./comment-block/DocumentBlock";
import DefaultMinorBlock from "./comment-block/DefaultMinorBlock";

const ItemSentBlock = ({ comment, file, index, folder }) => {
  let fileInfo = {};

  if (file) {
    // Try parsing file info if it's available
    try {
      fileInfo = JSON.parse(file?.file_info);
    } catch (e) {
      // If file info is not valid JSON, do nothing
    }

    switch (file?.type_file_name) {
      case "audio":
        return (
          <AudioBlock
            file={file}
            fileInfo={fileInfo}
            index={index}
            comment={comment}
          />
        );
      case "image":
        return (
          <ImageBlock
            file={file}
            fileInfo={fileInfo}
            index={index}
            comment={comment}
          />
        );
      case "video":
        return (
          <VideoBlock
            file={file}
            fileInfo={fileInfo}
            index={index}
            comment={comment}
          />
        );
      case "document":
        return (
          <DocumentBlock
            file={file}
            fileInfo={fileInfo}
            index={index}
            comment={comment}
          />
        );
      default:
        return (
          <DefaultMinorBlock
            file={file}
            fileInfo={fileInfo}
            index={index}
            comment={comment}
          />
        );
    }
  } else if (folder) {
    // Try parsing file info if it's available
    try {
      fileInfo = JSON.parse(folder?.file_info);
    } catch (e) {
      // If file info is not valid JSON, do nothing
    }

    return (
      <div className={`mmessenger`}>
        <div className="messenger-content">
          <div className={`s-file`}>
            <div class="left-folder-message">
              <span class="material-symbols-outlined">folder</span>
              <span>{folder?.name}</span>
              <span class="material-symbols-outlined">arrow_forward_ios</span>
            </div>
          </div>
          <p className="txt-total-file">{`${comment?.fileList?.length} ${comment?.fileList?.length > 2 ? "files" : "file"}`}</p>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ItemSentBlock;
