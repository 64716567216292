import React, { useState } from "react";
// import { createPortal } from "react-dom";

import Input from "../../../components/form-item/Input";
import FileUploader from "../../../components/uploader/FileUploader";
import ListBlock from "../../../components/block/ListBlock";
import Modal from "../../../components/modal/Modal";
import Button from "../../../components/button/Button";
import TitleBlock from "../../../components/block/TitleBlock";

const MySiteServiceList = () => {
  const [modalAddServiceOpen, setModalAddServiceOpen] = useState(false);

  const handleAddServiceClick = () => {
    setModalAddServiceOpen(true);
  };
  return (
    <>
      <TitleBlock
        title="サービス登録"
        description="提供するサービスの詳細を伝えましょう。"
      />

      <div className="u-w100 u-col u-gap16">
        <div className="u-w100 u-col u-gap4">
          <ListBlock type="service" isSortable={true} isDeletable={true} />
          <ListBlock type="service" isSortable={true} isDeletable={true} />
          <ListBlock type="service" isSortable={true} isDeletable={true} />
          <ListBlock type="service" isSortable={true} isDeletable={true} />
          <ListBlock type="service" isSortable={true} isDeletable={true} />
          <ListBlock type="service" isSortable={true} isDeletable={true} />
        </div>
        <ListBlock
          type="add"
          label="サービスを追加"
          onClick={handleAddServiceClick}
        />
      </div>
      {modalAddServiceOpen && (
        <Modal onClose={() => setModalAddServiceOpen(false)}>
          <div className="u-col u-gap16 u-w100 u-pt128">
            <TitleBlock
              title="サービス登録"
              description="提供するサービスの詳細を伝えましょう。"
            />

            <div className="u-col u-w100">
              <p>アスペクト比が1.414:1の画像をアップロードしましょう。</p>
              <div className=" u-w100">
                <div className="u-row u-gap4">
                  <div className="heading">サムネイル</div>
                  <span className="label8 u-text-light-gray">[任意]</span>
                </div>

                <div className="u-col u-gap8 u-w100">
                  <FileUploader />
                </div>
              </div>
            </div>

            <div className="u-col u-w100">
              <p>動画をアップロードしましょう。</p>
              <div className=" u-w100">
                <div className="u-row u-gap4">
                  <div className="heading">動画</div>
                  <span className="label8 u-text-light-gray">[任意]</span>
                </div>
                <FileUploader />
              </div>
            </div>

            <div className="u-col u-w100">
              <p>アスペクト比が16:9の画像をアップロードしましょう。</p>
              <div className=" u-w100">
                <div className="u-row u-gap4">
                  <div className="heading">キービジュアル</div>
                  <span className="label8 u-text-light-gray">[任意]</span>
                </div>

                <div className="u-col u-gap8 u-w100">
                  <FileUploader />
                </div>
              </div>
            </div>

            <div className="u-col u-w100">
              {/* <p>トピックタイトル説明文</p> */}
              <Input
                label="トピックタイトル"
                option="[必須]"
                type="text"
                placeholder="トピックタイトル"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <Input
                label="構成要素"
                option="[任意]"
                type="text"
                placeholder="インタラクティブミュージック　効果音　オーディオガイド　番組配信"
                width="100%"
                append=""
                name=""
              />
              <div className="bodytext-11">
                ワードの区切りは、スペースで設定してください。
              </div>
            </div>

            <div className="u-col u-w100">
              {/* <p>説明文</p> */}
              <Input
                label="概要"
                option="[任意]"
                type="text"
                placeholder="展示会の空間サウンドデザイン、オーディオガイドを作成します。"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              {/* <p>説明文</p> */}
              <Input
                label="詳細"
                option="[任意]"
                type="text"
                placeholder="展示会やプロジェクションマッピング、インスタレーション作品のサウンド演出にもっと向き合ってみませんか。"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              {/* <p>ワードの区切りは、#で設定してください。</p> */}
              <Input
                label="ハッシュタグ"
                option="[任意]"
                type="text"
                placeholder="ワードの区切りは「#」で設定してください。 #メタバース #ソニックブランディング"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              {/* <p>説明文</p> */}
              <div className=" u-w100">
                <div className="u-row u-gap4">
                  <div className="heading">詳細資料</div>
                  <span className="label8 u-text-light-gray">[任意]</span>
                </div>

                <FileUploader />
              </div>
            </div>

            <div className="u-row u-gap8 u-w100">
              <Button
                name="キャンセル"
                onClick={setModalAddServiceOpen}
                color="secondary"
                size="medium"
              />
              <Button
                name="追加"
                onClick={setModalAddServiceOpen}
                color="primary"
                size="medium"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MySiteServiceList;
