import React from "react";
import Tippy from "@tippyjs/react";
import "./tippy.css"; // CSS for default styling

const ToolTip = ({ description, placement = "top", children }) => (
  <Tippy
    content={description}
    arrow={false}
    delay={[0, 0]}
    duration={[0, 300]}
    placement={placement}
  >
    {children}
  </Tippy>
);

export default ToolTip;
