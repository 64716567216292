import React from "react";

import Avatar from "./Avatar";
import Dropdown from "./Dropdown";

const MemberItem = ({
  member,
  product,
  editable,
  user,
  onlineUsers,
  role,
  editableAll,
  isOwner,
  loginProducer,
  loginMasteradmin,
  loginClient,
}) => {
  const isSelf = member.id === user.id;
  const canEdit = false; //editable || editableAll;
  const isOnline = true; //onlineUsers.includes(member);

  return (
    <div
      className={`p-lists-user u-row-between member-manage ${canEdit ? "can-edit-all" : ""} ${isSelf ? "can-self-edit-delete" : ""}`}
      data-role={role}
    >
      <div className="u-row u-gap16">
        <Avatar url={member.avatar} isOnline={isOnline} size="48" />

        <div className="u-col u-gap4">
          <div
            className={`heading-13-spacing ${canEdit ? "user-can--editable" : ""}`}
          >
            {member.display_name}
          </div>
          <div className="bodytext-11 u-line-height-100">
            {member?.position}
          </div>
          <div className="bodytext-11 u-line-height-100 u-text-light-gray">
            {member?.enterprise}
          </div>
        </div>
      </div>

      {!isSelf && ["admin", "master-admin"].includes(user?.role) && (
        <Dropdown member={member} />
      )}
    </div>
  );
};

export default MemberItem;
