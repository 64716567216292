export const randomImageBackground = (images) => {
  const randomImage = (max, min) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const imagePcs = document.getElementsByClassName("banner__img-pc");
  Array.from(imagePcs).forEach((element) => {
    element.classList.add("img" + randomImage(images.length, 1));
  });

  const imageSps = document.getElementsByClassName("banner__img");
  Array.from(imageSps).forEach((element) => {
    element.classList.add("img" + randomImage(images.length, 1));
  });
};

export const removeNavbar = () => {
  Array.from(document.getElementsByTagName("header")).forEach((el) => {
    el.classList.add("hide");
  });
};

export const resizeAuth = () => {
  let ratio = 1;

  const authMainEl = document.getElementsByClassName("auth__main")?.item(0);
  if (!authMainEl) {
    return;
  }

  if (window.innerWidth < 400) {
    ratio = window.innerWidth / 400;
    authMainEl.style.transform = "scale(" + ratio + ")";
  }

  if (window.innerHeight < authMainEl.innerHeight * ratio) {
    authMainEl.style.transform =
      "scale(" +
      (window.innerHeight / (authMainEl.innerHeight * ratio)) * ratio * 0.9 +
      ")";
  }
};
