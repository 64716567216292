import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getAuthTokenFromCookies = () => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("authToken="))
    ?.split("=")[1];
};

const apiClient = axios.create({
  baseURL: "http://localhost:8000/api/auth-user/",
});

apiClient.interceptors.request.use((config) => {
  const token = getAuthTokenFromCookies();
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

export const updatePartialAuthUser = createAsyncThunk(
  "authUsers/updatePartialAuthUser",
  async ({ id, partialData }) => {
    const response = await apiClient.patch(`${id}/`, partialData);
    return response.data;
  },
);

export const fetchAuthUsers = createAsyncThunk(
  "authUsers/fetchAuthUsers",
  async () => {
    const response = await apiClient.get("/");
    return response.data;
  },
);

export const fetchAuthUserById = createAsyncThunk(
  "authUsers/fetchAuthUserById",
  async (id) => {
    const response = await apiClient.get(`${id}/`);
    return response.data;
  },
);

export const createAuthUser = createAsyncThunk(
  "authUsers/createAuthUser",
  async (userData) => {
    const response = await apiClient.post("/", userData);
    return response.data;
  },
);

export const updateAuthUser = createAsyncThunk(
  "authUsers/updateAuthUser",
  async ({ id, userData }) => {
    const response = await apiClient.put(`${id}/`, userData);
    return response.data;
  },
);

export const deleteAuthUser = createAsyncThunk(
  "authUsers/deleteAuthUser",
  async (id) => {
    await apiClient.delete(`${id}/`);
    return id;
  },
);

const authUserSlice = createSlice({
  name: "authUsers",
  initialState: { users: [], user: null, loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 全ユーザー取得
      .addCase(fetchAuthUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuthUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAuthUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // IDでユーザー取得
      .addCase(fetchAuthUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuthUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchAuthUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // ユーザー作成
      .addCase(createAuthUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAuthUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(createAuthUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // ユーザー更新
      .addCase(updateAuthUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAuthUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user,
        );
      })
      .addCase(updateAuthUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // ユーザー削除
      .addCase(deleteAuthUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAuthUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteAuthUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // 部分更新
      .addCase(updatePartialAuthUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePartialAuthUser.fulfilled, (state, action) => {
        state.loading = false;
        // 更新対象がusers配列の中にも存在する場合は更新
        state.users = state.users.map((user) =>
          user.id === action.payload.id ? { ...user, ...action.payload } : user,
        );
        // 現在表示中のuserが更新対象なら合わせて上書き
        if (state.user && state.user.id === action.payload.id) {
          state.user = { ...state.user, ...action.payload };
        }
      })
      .addCase(updatePartialAuthUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authUserSlice.reducer;
