import React, { useState, useEffect, useRef } from "react";
import AspectRatio from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";

function Preview({ src, fixedWidth, fixedHeight, maxWidth, maxHeight }) {
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
  const videoRef = useRef(null);
  const [calculatedWidth, setCalculatedWidth] = useState("auto");

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleLoadedMetadata = () => {
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const ratio = videoWidth / videoHeight;
        setAspectRatio(ratio);
        // fixedHeightがある場合、幅を計算
        if (fixedHeight) {
          const heightValue = parseFloat(fixedHeight);
          const width = heightValue * ratio;
          setCalculatedWidth(`${width}px`);
        }
      };

      video.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }
  }, [src, fixedHeight]);

  const style = {
    height: fixedHeight,
    width: calculatedWidth,
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    overflow: "hidden",
    flexShrink: 0, // 親コンテナ内で幅が縮小されないように
    // borderRadius: "12px",
  };

  return (
    <div style={{ ...style, overflow: "hidden" }}>
      <AspectRatio
        ratio={aspectRatio}
        style={{ width: "100%", height: "100%" }}
      >
        <video
          ref={videoRef}
          src={src}
          controls
          autoPlay
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </AspectRatio>
    </div>
  );
}

export default Preview;
