import React from "react";

import FileInfoBlock from "./FileInfoBlock";

const FolderInfoBlock = ({ folder, comments }) => {
  const type_comment = "messenger";
  const isMessenger = ["messenger", "messenger_owner"].includes(type_comment);
  let extraClassName = "";
  const comment = comments.find(
    (comment) => comment.comment_id === folder?.message_id,
  );

  // Determine extra class based on message type or role
  if (isMessenger) {
    if (folder?.message?.offer) {
      extraClassName = "s-file--gray";
    }
  } else {
    if (!comment?.is_same_role) {
      extraClassName = "s-file--gray";
    }
  }

  return (
    <div
      className={`sfolder ${extraClassName}`}
      data-message-id={comment?.comment_id}
      data-folder-id={folder?.folder_id}
    >
      <ul className="mfolder">
        <div className="parent-folder">
          <i className="icon icon--sicon-storage"></i>
          <span className="hasSub" data-folder-id={folder?.folder_id}>
            {folder?.name}
          </span>
          {folder?.has_child_folder && (
            <i className="icon icon--sicon-download icon--sicon-folder-download pointer"></i>
          )}
        </div>

        <ul>
          {/* Loop over children (subfolders) */}
          {folder?.children?.map((child) => {
            if (!child?.file_id) {
              return (
                <li
                  key={child?.folder_id || child?.file_id}
                  className="mfolder__sub"
                >
                  <i className="icon icon--sicon-storage"></i>
                  <span
                    className="hasSub"
                    data-folder-id={child?.file_id ?? child?.folder_id}
                  >
                    {child?.real_name ?? child?.name}
                  </span>
                  {child?.has_child_folder && (
                    <i className="icon icon--sicon-download icon--sicon-folder-download pointer"></i>
                  )}
                </li>
              );
            }
            return null;
          })}

          {/* Loop over children and render FileInfoBlock for files */}
          {folder?.children?.map((child) => {
            if (child?.file_id) {
              return (
                <FileInfoBlock
                  key={child?.file_id}
                  file={child}
                  comments={comments}
                />
              );
            }
            return null;
          })}
        </ul>
      </ul>
    </div>
  );
};

export default FolderInfoBlock;
