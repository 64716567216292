import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const FooterCommentContainer = styled.div`
  position: fixed;
  ${(props) =>
    props.scrollState === "init"
      ? `bottom: 80px;`
      : props.scrollState === "down"
        ? `bottom: 0px;`
        : `bottom: 80px;`}
  ${(props) => (props.fileOpen ? `right: 267px;` : `right: 0px;`)}
  transition: all .3s;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

const CommentBoxContainer = styled.div`
  width: max(320px, min(100%, 740px));
  max-width: max(320px, min(100%, 740px));
  border-radius: 6px 6px 6px 6px;
  background: rgba(255, 255, 255, 0.16);
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13px);
  margin: 0 0 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: none;
  padding: 8px;

  textarea {
    resize: none;
    width: 100%;
    padding: 12px 40px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin: 0;
    font-size: 16px;
    line-height: 200%;
    color: black;
  }

  .add-file-button {
    color: var(--soremo-light-gray);
    position: absolute;
    top: 10px;
    left: 16px;
    bottom: 0px;
    align-content: center;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    .material-symbols-rounded {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        color: #009ace;
        scale: 1.3;
      }
    }
  }

  .send-button {
    color: var(--soremo-light-gray);
    position: absolute;
    top: 10px;
    right: 16px;
    bottom: 0px;
    align-content: center;
    -webkit-transition: 0.2s;
    transition: 0.2s;

    .material-symbols-rounded {
      -webkit-transition: 0.2s;
      transition: 0.2s;
      cursor: pointer;
      &.active {
        color: #009ace;
        scale: 1.3;
      }
    }
  }
`;

const CommentBoxDM = ({ isEditing = false, editingComment = {}, fileOpen }) => {
  const [comment, setComment] = useState("");

  const scrollState = useSelector((state) => {
    return state.layout;
  });

  return (
    <FooterCommentContainer scrollState={scrollState} fileOpen={fileOpen}>
      <CommentBoxContainer>
        <div className="add-file-button">
          <span className="material-symbols-rounded">add_circle</span>
        </div>
        <textarea
          className="mcomment-input-text mcomment-autoExpand"
          name="mcomment-input"
          id="text-input-comment"
          rows="1"
          placeholder="メッセージを送信"
          defaultValue={comment}
          onChange={(e) => {
            setComment(e?.target?.value);
          }}
        />
        <div className="send-button">
          <span
            className={`material-symbols-rounded ${comment?.length > 0 ? "active" : ""}`}
          >
            send
          </span>
        </div>
      </CommentBoxContainer>
    </FooterCommentContainer>
  );
};

export default CommentBoxDM;
