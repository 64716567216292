// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useNavigate } from "react-router-dom";

import {
  getAuthTokenFromCookies,
  setAuthTokenToCookies,
} from "../../common/utils";
import { HOSTNAME } from "../../constant/host";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: HOSTNAME, // Set the base URL to HOSTNAME
    prepareHeaders: (headers) => {
      // Get the auth token from cookies
      const token = getAuthTokenFromCookies();

      // If we have a token, add it to the headers
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }

      return headers;
    },
    // Handle redirection based on status (e.g., 401 Unauthorized)
    baseQuery: async (args, api, extraOptions) => {
      const result = await fetchBaseQuery(args, api, extraOptions);
      const location = window.location.pathname; // Get current page
      const navigate = useNavigate();

      // If user is unauthorized (status 401)
      if (result.error && result.error.status === 401) {
        // Redirect to login if not on homepage or login page
        if (location !== "/" && location !== "/accounts/login") {
          navigate("/accounts/login");
        }
      }

      return result;
    },
  }),
  endpoints: (builder) => ({
    getProfileByName: builder.query({
      query: () => {
        return `/api/get-profile`;
      },
    }),
    getTestProfiles: builder.query({
      query: () => ({
        url: "/api/get-profile-test", // API endpoint to get profiles
        method: "GET", // Explicitly specifying GET method
      }),
    }),
    getAuthUsers: builder.query({
      query: () => ({
        url: "/api/get-authuser-test", // API endpoint to get profiles
        method: "GET", // Explicitly specifying GET method
      }),
    }),
    getAuthUserById: builder.query({
      query: (id) => ({
        url: `/api/get-authuser-test/${id}/`, // API endpoint to get profile by id
        method: "GET", // Explicitly specifying GET method
      }),
    }),
    createAuthUser: builder.mutation({
      query: (userData) => ({
        url: "/api/create-authuser", // API endpoint to create a user
        method: "POST", // Explicitly specifying POST method
        body: userData, // Send user data in the body
      }),
    }),
    updateAuthUser: builder.mutation({
      query: ({ id, userData }) => ({
        url: `/api/update-authuser/${id}/`, // API endpoint to update a user
        method: "PUT", // Explicitly specifying PUT method
        body: userData, // Send user data in the body
      }),
    }),
    deleteAuthUser: builder.mutation({
      query: (id) => ({
        url: `/api/delete-authuser/${id}/`, // API endpoint to delete a user
        method: "DELETE", // Explicitly specifying DELETE method
      }),
    }),
  }),
});

// Define the loginApi service using the same base URL structure
export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: HOSTNAME, // Set the base URL to HOSTNAME for login API
    credentials: "include", // To include cookies in the request if needed
    redirect: "manual", // Optionally prevent redirects
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => {
        return {
          url: `${HOSTNAME}/api/login`, // Use HOSTNAME for the login endpoint
          method: "POST",
          body: {
            username: credentials.username,
            password: credentials.password,
            remember_me: credentials.rememberMe,
          },
        };
      },
      // Handle the response and store the token
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const token = data.token;
          if (token) {
            setAuthTokenToCookies(token); // Save token to cookies
          }
        } catch (error) {
          // console.error("Login failed:", error);
        }
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetProfileByNameQuery,
  useGetTestProfilesQuery,
  useGetAuthUsersQuery,
  useGetAuthUserByIdQuery,
  useCreateAuthUserMutation,
  useUpdateAuthUserMutation,
  useDeleteAuthUserMutation,
} = profileApi;
export const { useLoginUserMutation } = loginApi;
