import React from "react";
import styled from "styled-components";

const ViewUserSeen = styled.div`
  display: flex;
`;

const AvatarStyle = styled.div`
  -webkit-box-flex: initial;
  -ms-flex: initial;
  flex: initial;
`;

const AvatarImage = styled.div`
  width: 14px;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  background-color: white;
  border-radius: 50%;
`;

const UserDownloadedAvatar = ({ display_name, avatar }) => {
  return (
    <ViewUserSeen>
      <AvatarStyle>
        <AvatarImage
          style={{
            backgroundImage: `url(${avatar})`,
          }}
        />
      </AvatarStyle>
    </ViewUserSeen>
  );
};

export default UserDownloadedAvatar;
