export const roundRating = (listScene = []) => {
  let totalSceneRating = 0;
  let averageSceneRating = 0;

  listScene.map((scene) => {
    totalSceneRating += scene?.rating;
    return scene;
  });

  averageSceneRating = totalSceneRating / listScene.length;

  return Math.round(averageSceneRating);
};
