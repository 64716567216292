import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

// styled-components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  position: fixed;
  width: clamp(320px, 80vw, 756px);

  /* ビューポートの90%を最大高さとする */
  max-height: 80vh;
  /* 縦方向のスクロールを可能にする */
  overflow-y: auto;

  background: rgba(255, 255, 255, 1);
  border-radius: 12px;
  border: 1px solid var(--soremo-border);
  padding: 32px 16px;
  box-shadow: 2px 4px 8px 3px rgba(0, 0, 0, 0.1);

  /* 画面の中央に垂直方向で配置 */
  top: 50%;
  /* 画面の中央に水平方向で配置 */
  left: 50%;
  /* モーダルの左上の角を中央から左上に移動 */
  transform: translate(-50%, -50%);

  /* flex親設定 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 300ms;
  animation-timing-function: ease-out;
`;

const Modal = ({ children, onClose }) => {
  return createPortal(
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalOverlay>,
    document.getElementById("root"),
  );
};

export default Modal;
