import React from "react";

import ProjectUpdateBlock from "./ProjectUpdateBlock";

const ProjectUpdateList = ({ updatedScenes, user }) => {
  return (
    <>
      {updatedScenes.map((scene, index) => {
        return <ProjectUpdateBlock scene={scene} user={user} />;
      })}
    </>
  );
};

export default ProjectUpdateList;
