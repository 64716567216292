import React from "react";
import styled from "styled-components";

import mireage_universe from "../../../assets/images/mileage_universe.png";

const MileageCardStyle = styled.div`
  width: clamp(192px, 38.2vw, 756px * 0.382);
  aspect-ratio: 1.414/1;
  background-color: #f0f0f0;
  border-radius: 6px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;

  // box-shadow: 2px 4px 10px 0px #E5E5E5;
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.35);
`;

const MileageBlock = () => {
  // モックデータ。models.pyのデータを使用する
  const authUser = {
    balance_reward: 5000000,
    evaluation_point: 100000,
    total_point: 17.5,
  };

  const mileageRank = {
    image: mireage_universe,
  };

  return (
    <div className="u-row u-gap8 u-w100" style={{ padding: "16px 12px" }}>
      <MileageCardStyle image={mileageRank.image}></MileageCardStyle>
      <div className="u-w100 u-col u-gap4">
        <p className="heading-16">
          {Number(authUser.balance_reward).toLocaleString()}
          <span className="bodytext-11">マイル</span>
          <span className="bodytext-11 u-text-light-gray">
            （利用料:{authUser.total_point}%）
          </span>
        </p>
        <progress value={70} max={100} style={{ width: "100%" }}></progress>
        <p className="bodytext-11">
          次のステージまであと
          {Number(authUser.evaluation_point).toLocaleString()}マイル
        </p>
      </div>
    </div>
  );
};

export default MileageBlock;
