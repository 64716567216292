import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Avatar from "../avatar/Avatar";
import IdentityBadge from "./IdentityBadge";
import NDASignedBadge from "./NDASignedBadge";
import Modal from "../modal/Modal";
import Input from "../form-item/Input";
import LanguageSwitcher from "../tab/LanguageSwitcher";
import TitleBlock from "../block/TitleBlock";

// スタイリング
const EditIcon = styled.div.attrs({
  className: "material-symbols-rounded u-text-light-gray",
})`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const ProfileSection = styled.div.attrs({
  className: "u-w100 u-col-center u-ptb8",
})`
  position: relative;
  width: calc(100% - 32px);
  border-radius: 6px;
  transition: 0.2s;

  &:hover {
    background-color: var(--soremo-bg-blue);
    outline: 1px solid var(--soremo-blue);

    cursor: pointer;

    ${EditIcon} {
      color: var(--soremo-blue);
    }
  }
`;

const ModalFooter = styled.div.attrs({
  className: "u-row u-justify-end u-w100 u-pt16",
})`
  position: relative;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

const AvatarUploadWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background-color: #f3f3f3;
  border: 1px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const UserItem = ({ size, editable = false }) => {
  const [localUserData, setLocalUserData] = useState({
    stage_name: "",
    stage_name_en: "",
    position: "",
    type: "",
    enterprise: "",
    affiliation_en: "",
    medium_avatar: "",
  });
  const [language, setLanguage] = useState("JP");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAvatarOpen, setModalAvatarOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // 選択された画像
  const [previewImage, setPreviewImage] = useState(null); // プレビュー画像用URL
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // 初期データ取得
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/auth-user/1/");
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        const result = data.result || {};
        setLocalUserData({
          stage_name: result.stage_name || "未登録",
          stage_name_en: result.stage_name_en || "未登録",
          position: result.position || "未登録",
          type: result.type || "未登録",
          enterprise: result.enterprise || "未登録",
          affiliation_en: result.affiliation_en || "未登録",
          medium_avatar: result.medium_avatar || "",
        });
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // 入力変更時の処理
  const handleChange = (e) => {
    const { name, value } = e.target;

    // ローカルデータを即時更新
    setLocalUserData((prev) => ({ ...prev, [name]: value }));

    // サーバーに非同期更新
    updateUserData(name, value);
  };

  // サーバー更新関数
  const updateUserData = async (name, value) => {
    try {
      const response = await fetch("http://localhost:8000/api/auth-user/1/", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ [name]: value }),
      });

      if (!response.ok) {
        throw new Error("Failed to update user data");
      }
    } catch (err) {
      setError("データ更新に失敗しました。");
    }
  };

  // アバタークリック時の処理
  const handleAvatarClick = () => {
    editable && setModalAvatarOpen(true);
  };

  // 画像選択時の処理
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);

      // プレビュー用URLを生成
      const objectURL = URL.createObjectURL(file);
      setPreviewImage(objectURL);
      setLocalUserData((prev) => ({ ...prev, medium_avatar: objectURL })); // 即時反映
    }
  };

  // アバターアップロード処理
  const handleAvatarUpload = async () => {
    if (!selectedImage) return;

    // ローカルでプレビューを即時反映済み
    try {
      const formData = new FormData();
      formData.append("medium_avatar", selectedImage);

      const response = await fetch("http://localhost:8000/api/auth-user/1/", {
        method: "PATCH",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload avatar");
      }

      const updatedData = await response.json();

      // サーバーが返す正式なURLでローカルデータを更新
      setLocalUserData((prev) => ({
        ...prev,
        medium_avatar: updatedData.medium_avatar,
      }));

      // プレビュー画像をリセット
      setPreviewImage(null);

      // モーダルを閉じる
      setModalAvatarOpen(false);
    } catch (err) {
      setError("アバターアップロードに失敗しました。");
    }
  };

  // モーダルを閉じる処理
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAvatarModalClose = () => {
    setModalAvatarOpen(false);
    setPreviewImage(null); // プレビューリセット
  };

  // モーダル表示/非表示の切り替え
  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div
        className="u-col-center u-gap16"
        style={{ padding: "32px 0px 40px" }}
      >
        <Avatar
          size={size}
          src={previewImage || localUserData.medium_avatar} // プレビューがある場合は優先
          editable={editable}
          onClick={handleAvatarClick}
        />
        <ProfileSection onClick={editable ? toggleModal : undefined}>
          {editable && <EditIcon>settings</EditIcon>}
          <ul className="u-col u-gap4">
            <li className="heading-18-spacing u-pt4">
              {localUserData.stage_name || localUserData.stage_name_en}
            </li>
            <li className="bodytext-13 u-line-height-200">
              {localUserData.position || localUserData.type}
            </li>
            <li className="u-line-height-100 bodytext-11 u-text-light-gray">
              {localUserData.enterprise || localUserData.affiliation_en}
            </li>
          </ul>
        </ProfileSection>
        <div className="u-row-center u-pt8 u-wrap" style={{ gap: "16px 16px" }}>
          <IdentityBadge />
          <NDASignedBadge status="NDA締結済み" />
        </div>
      </div>

      {/* アバター編集モーダル */}
      {modalAvatarOpen && (
        <Modal onClose={handleAvatarModalClose}>
          <p>アバターを変更する</p>
          <AvatarUploadWrapper>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="avatar-upload"
            />
            <label htmlFor="avatar-upload" style={{ cursor: "pointer" }}>
              ファイルを選択
            </label>
          </AvatarUploadWrapper>
          {previewImage && (
            <div style={{ marginTop: "16px" }}>
              <img
                src={previewImage}
                alt="Selected Avatar"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
              <button
                onClick={handleAvatarUpload}
                style={{
                  marginTop: "16px",
                  padding: "8px 16px",
                  backgroundColor: "var(--soremo-blue)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                保存
              </button>
            </div>
          )}
        </Modal>
      )}

      {/* プロフィール編集モーダル */}
      {modalOpen && (
        <Modal onClose={handleModalClose}>
          <TitleBlock title="プロフィールを編集" />
          <div className="u-col u-gap24 u-w100">
            <Input
              label={language === "JP" ? "表示名" : "Display Name"}
              option="[必須]"
              type="text"
              placeholder={
                language === "JP" ? "日本語の表示名" : "English Display Name"
              }
              width="100%"
              name={language === "JP" ? "stage_name" : "stage_name_en"}
              value={
                language === "JP"
                  ? localUserData.stage_name
                  : localUserData.stage_name_en
              }
              onChange={handleChange}
            />
            <Input
              label={language === "JP" ? "タイトル" : "Title"}
              option="[任意]"
              type="text"
              placeholder={
                language === "JP" ? "日本語のタイトル" : "English Title"
              }
              width="100%"
              name={language === "JP" ? "position" : "type"}
              value={
                language === "JP" ? localUserData.position : localUserData.type
              }
              onChange={handleChange}
            />
            <Input
              label={language === "JP" ? "所属" : "Affiliation"}
              option="[任意]"
              type="text"
              placeholder={
                language === "JP" ? "日本語の所属" : "English Affiliation"
              }
              width="100%"
              name={language === "JP" ? "enterprise" : "affiliation_en"}
              value={
                language === "JP"
                  ? localUserData.enterprise
                  : localUserData.affiliation_en
              }
              onChange={handleChange}
            />
          </div>
          <ModalFooter>
            <LanguageSwitcher onLanguageChange={setLanguage} />
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

UserItem.propTypes = {
  size: PropTypes.oneOf([16, 32, 48, 96, 144]),
  editable: PropTypes.bool,
};

export default UserItem;
