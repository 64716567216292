import React from "react";

import { FOOTER_LINK } from "../../../constant/index";

const FooterBlock = () => {
  return (
    <footer
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      <nav className="page-footer wrapper">
        {FOOTER_LINK.map((footerInfo, indexFooter) => {
          return (
            <div key={indexFooter} className="footer-nav">
              <li>
                <a href={footerInfo.link} target="_blank" rel="noreferrer">
                  {footerInfo.text}
                </a>
              </li>
            </div>
          );
        })}
      </nav>
    </footer>
  );
};

export default FooterBlock;
