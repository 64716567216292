import MappingMenuRole from "./mapping";

export const getListMenuFromUser = ({ user }) => {
  let mapping = MappingMenuRole({ user });
  let output = [];

  mapping.map((menuInfo) => {
    if (menuInfo.role.includes(user.role)) {
      output.push(menuInfo);
    }
    return menuInfo;
  });

  return output;
};
