import React from "react";

import SceneDetail from "./SceneDetail";

const routes = [
  {
    path: "/scene-detail",
    exact: true,
    component: <SceneDetail />,
  },
];

export default routes;
