import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import avatarCreator from "../../assets/images/avatar_creator.png";
import avatarClient from "../../assets/images/avatar_client.png";

const AvatarContainer = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 43% 57% 43% 57% / 57% 43% 57% 43%;
  transform: rotate(-19deg);
  border: 1px solid #fff;
  box-shadow: 2px 4px 10px 0px #e5e5e5;
  overflow: hidden;
  position: relative;
  cursor: ${({ editable }) => (editable ? "pointer" : "default")};
  &:hover {
    ${({ editable }) =>
      editable &&
      `
      background-color: var(--soremo-bg-blue);
      outline: 3px solid var(--soremo-blue);
    `}
  }
`;

const AvatarImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(19deg);
`;

const OnlineBadge = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  background-color: #2cc84d;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 1;
`;

const EditableBadge = styled.div.attrs({
  className: "material-symbols-rounded u-text-border",
})`
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  z-index: 1;
  mix-blend-mode: screen;
`;

const Avatar = ({
  size = 48,
  src,
  isOnline = false,
  editable = false,
  onClick,
}) => {
  return (
    <div style={{ position: "relative" }} {...(editable && { onClick })}>
      {isOnline && <OnlineBadge />}
      {editable && <EditableBadge>photo</EditableBadge>}
      <AvatarContainer size={size} editable={editable}>
        <AvatarImage src={src} />
      </AvatarContainer>
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(["12", "32", "48", "96"]),
  isOnline: PropTypes.bool,
};

Avatar.avatarCreator = avatarCreator;
Avatar.avatarClient = avatarClient;

export default Avatar;
