import React from "react";
import Flatpickr from "react-flatpickr";
import "./flatpickr_soremo.css";

const Calendar = () => {
  return (
    <Flatpickr
      options={{
        inline: true, //インライン表示
        allowInput: false,
        dateFormat: "Y-M-j",
        mode: "multiple",
        monthSelectorType: "static",
        prevArrow: "<span class='material-symbols-rounded'>chevron_left</span>",
        nextArrow:
          "<span class='material-symbols-rounded'>chevron_right</span>",
        minDate: new Date(),
        position: "auto center",
        shorthandCurrentMonth: true,
      }}
      style={{ display: "none" }}
    />
  );
};

export default Calendar;
