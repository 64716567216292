import React from "react";

import SystemMessage from "./SystemMessage";
import MessageSentBlock from "./MessageSentBlock";
import MessageReceivedBlock from "./MessageReceivedBlock";

const MessageOwnerList = ({ comments, user, offer, offer_user }) => {
  return (
    <>
      {comments.map((comment, index) => {
        if (comment?.type_message !== "1") {
          return (
            <SystemMessage
              key={comment.id}
              comment={comment}
              user={user}
              typeMessage="messenger_artist"
            />
          );
        } else {
          // Logic for offer_user.position matching
          if (comment.offer_user === offer_user) {
            // Check if it is the first message
            return (
              <MessageSentBlock
                key={comment.id}
                comment={comment}
                user={user}
                type="messenger_artist"
                firstMessage={index === 0}
              />
            );
          } else {
            // Render Received Message
            return (
              <MessageReceivedBlock
                key={comment.id}
                comment={comment}
                user={user}
                type="messenger_artist"
              />
            );
          }
        }
      })}
    </>
  );
};

export default MessageOwnerList;
