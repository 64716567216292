import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";

import { useCreateSceneMutation } from "../../../../../../../app/services/project";

// Form validation schema with Yup
const validationSchema = Yup.object({
  sceneName: Yup.string()
    .required("Scene name is required")
    .min(3, "Scene name should be at least 3 characters"),
  sceneSchedule: Yup.string().required("Schedule date is required"),
  scheduleHour: Yup.string().required("Schedule time is required"),
});

const AddSceneModal = ({ show, onClose, project_scene, project_id }) => {
  const [createScene] = useCreateSceneMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [scenes, setScenes] = useState([]);

  // Formik hook for form management
  const formik = useFormik({
    initialValues: {
      sceneName: "",
      sceneSchedule: "",
      scheduleHour: "",
      file: null,
    },
    validationSchema,

    onSubmit: async (values) => {
      // Create FormData to send files
      const formData = new FormData();

      // Append the files to formData
      let indexCheck = 0;
      values?.file.map((fileArray, index) => {
        fileArray.map((fileDetail) => {
          formData.append(`movies_${indexCheck}`, fileDetail);
          indexCheck += 1;
          return fileDetail;
        });

        return fileArray;
      });

      formData.append(`count_list_file`, values?.file.length);
      formData.append(
        "list_width",
        scenes
          .map((scene) =>
            scene?.map((sceneDetail) => sceneDetail?.width).join("!||!"),
          )
          .join("!||!"),
      );
      formData.append(
        "list_height",
        scenes
          .map((scene) =>
            scene?.map((sceneDetail) => sceneDetail?.height).join("!||!"),
          )
          .join("!||!"),
      );
      formData.append(
        "thumbnail_base_64",
        scenes
          .map((scene) =>
            scene?.map((sceneDetail) => sceneDetail?.image).join("!||!"),
          )
          .join("!||!"),
      );

      // Append other form data to formData
      formData.append("product_scene_id", project_scene?.id);
      formData.append("product_scene_text", project_scene?.name);
      formData.append("title", values?.scene_name);
      formData.append(
        "file_names",
        scenes
          .map((scene) =>
            scene?.map((sceneDetail) => sceneDetail?.image).join(","),
          )
          .join(","),
      );
      formData.append(
        "schedule_date",
        dayjs(values?.sceneSchedule).format("YYYY-MM-DD") +
          " - " +
          dayjs(values?.scheduleHour).format("hh:mm"),
      );
      formData.append("variation_values", "!||!");
      formData.append("product", project_id);

      // Call the createScene mutation
      try {
        await createScene(formData).unwrap();
        // Optionally handle success here, e.g., show success message or redirect
      } catch (err) {
        // Optionally handle error here, e.g., show error message
      }
    },
  });

  // Handle file drop
  const onDrop = (acceptedFiles) => {
    setIsUploading(true);
    let currentValue = formik?.values?.file || [];
    const file = acceptedFiles[0];
    const fileName = file.name;
    formik.setFieldValue("file", [...currentValue, [file]]);

    // Convert image to base64 using FileReader
    const reader = new FileReader();
    reader.onloadend = () => {
      // Create an Image object to get the width and height
      const img = new Image();
      img.onload = () => {
        // Once the image is loaded, get its width and height
        const width = img.width;
        const height = img.height;

        // Add the image data along with its dimensions to the scenes state
        setScenes([
          ...scenes,
          [
            {
              image: reader.result,
              name: fileName,
              width: width,
              height: height,
            },
          ],
        ]);
        setIsUploading(false);
      };

      // Set the src of the image to the base64 string to trigger the onload event
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".mp4, .jpg, .jpeg, .png",
  });

  // Reset form on modal close
  useEffect(() => {
    if (!show) {
      formik.resetForm(); // Reset Formik form values
    }
  }, [show, formik]);

  return (
    <>
      <ModalWrapper show={show}>
        <ModalHeader>
          <h1>シーンの作成</h1>
        </ModalHeader>

        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <div className="create-scene__content">
              <div className="create-scene__form" style={{ marginTop: "0px" }}>
                <div className="form-row">
                  <div
                    className="col-sm-12 form-group"
                    style={{ marginBottom: "-1px", padding: 0, width: "100%" }}
                  >
                    <label htmlFor="sceneName">
                      <div className="contract__form-label heading--16">
                        scene name
                        <span className="blue-label--8">required</span>
                      </div>
                    </label>
                    <input
                      id="sceneName"
                      name="sceneName"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.sceneName}
                      placeholder="Enter Scene Name"
                    />
                    {formik.errors.sceneName && formik.touched.sceneName && (
                      <div style={{ color: "red", fontSize: "0.8rem" }}>
                        {formik.errors.sceneName}
                      </div>
                    )}
                  </div>
                </div>

                {/* Schedule Date */}
                <div className="form-row">
                  <div
                    className="col-sm-12 form-group"
                    style={{ marginBottom: "-1px", padding: 0 }}
                  >
                    <label htmlFor="sceneSchedule">
                      <div className="contract__form-label heading--16">
                        次の配信日時
                      </div>
                    </label>
                    <div className="schedule-date-container">
                      <div className="form-row row form-row__mobile">
                        <div
                          className="col-xs-6 form-row__mobile-date"
                          style={{ paddingRight: "8px", paddingLeft: 0 }}
                        >
                          <Flatpickr
                            id="sceneSchedule"
                            name="sceneSchedule"
                            value={formik.values.sceneSchedule}
                            onChange={([date]) =>
                              formik.setFieldValue("sceneSchedule", date)
                            }
                            placeholder="YYYY-MM-DD"
                          />
                          {formik.errors.sceneSchedule &&
                            formik.touched.sceneSchedule && (
                              <div style={{ color: "red", fontSize: "0.8rem" }}>
                                {formik.errors.sceneSchedule}
                              </div>
                            )}
                        </div>
                        <div className="col-xs-6" style={{ padding: 0 }}>
                          <Flatpickr
                            id="scheduleHour"
                            name="scheduleHour"
                            value={formik.values.scheduleHour}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                              time_24hr: true, // 24-hour time format
                            }}
                            onChange={([time]) =>
                              formik.setFieldValue("scheduleHour", time)
                            }
                          />
                          {formik.errors.scheduleHour &&
                            formik.touched.scheduleHour && (
                              <div style={{ color: "red", fontSize: "0.8rem" }}>
                                {formik.errors.scheduleHour}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-sm-12 form-group">
                    <label htmlFor="firstTakeFile">
                      <div className="contract__form-label heading--16">
                        ファーストテイクを追加{" "}
                        <span className="grey-label--8">any</span>
                      </div>
                    </label>
                    <div className="dropzone-container">
                      <div className="fallback dropzone">
                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          <p>
                            ドラッグしてファイルをアップロードするか、ここをクリックして選択
                          </p>
                        </div>
                      </div>
                    </div>
                    {formik.errors.firstTakeFile &&
                      formik.touched.firstTakeFile && (
                        <div style={{ color: "red", fontSize: "0.8rem" }}>
                          {formik.errors.firstTakeFile}
                        </div>
                      )}
                  </div>
                </div>
              </div>

              {/* Scene Name */}
            </div>

            {/* Scene List (Take) */}
            <div className="scene-list-take">
              {scenes && scenes.length > 0 ? (
                scenes.map((scene, index) => (
                  <div className="scene-take-container">
                    <div class="scene-take-video">
                      <img src={scene[0]?.image} alt={scene[0]?.name} />
                    </div>
                    <div class="scene-take-namne-container">
                      <div class="scene-take-name-left">
                        <div class="scene-take-name">
                          <div class="scene-take-name-content">
                            {scene.length === 1 ? "ファーストテイク" : "テイク"}
                            {scene.length}
                          </div>
                          <div class="scene-take-time-content">
                            {dayjs().format("DD-MM-YYYY")}
                          </div>
                        </div>
                        <div class="scene-take-list-variant">
                          {scene.map((sceneDetail, indexScene) => {
                            if (indexScene > 0) {
                              return (
                                <div class="variant-name-container">
                                  <div class="scene-variant-file">
                                    {sceneDetail?.name}
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div class="variant-name-container">
                                  <div class="scene-variant-file">
                                    {sceneDetail?.name}
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div class="scene-take-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9.2925 6.71002C8.9025 7.10002 8.9025 7.73002 9.2925 8.12002L13.1725 12L9.2925 15.88C8.9025 16.27 8.9025 16.9 9.2925 17.29C9.6825 17.68 10.3125 17.68 10.7025 17.29L15.2925 12.7C15.6825 12.31 15.6825 11.68 15.2925 11.29L10.7025 6.70002C10.3225 6.32002 9.6825 6.32002 9.2925 6.71002Z"
                            fill="#A7A8A9"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No scenes available</p>
              )}
            </div>
            <button type="submit">Submit</button>
          </form>
          <ModalFooter>
            <ButtonContainer>
              <Button variant="secondary" onClick={onClose}>
                キャンセル
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isUploading || formik.isSubmitting}
                onClick={() => formik.submitForm()}
              >
                {isUploading ? "アップロード中..." : "OK"}
              </Button>
            </ButtonContainer>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
      <ModalBackdrop show={show} onClick={onClose} />
    </>
  );
};

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: ${(props) => (props.show ? "block" : "none")};
  transition: opacity 0.3s ease;
`;

const ModalWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  width: 90%;
  z-index: 999;
  position: fixed;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  max-width: 800px;
  margin: 5% auto;
  overflow: hidden;
  transform: translateY(-20px);
  transition: transform 0.3s ease;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
`;

const ModalHeader = styled.div`
  background-color: #009ace;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
`;

const ModalContent = styled.div`
  padding: 20px;
  max-height: 100%;
  overflow-y: auto;

  .form-row {
    margin: 0px 0 24px;
  }

  .heading--16 {
    font-family: var(--font-family-R);
    font-size: var(--font-size-16);
    line-height: var(--line-height-24);
    color: #000;
    font-weight: var(--font-weight-400);
  }

  .blue-label--8 {
    margin-left: 4px;
    font-size: 8px;
    color: #009ace;
    color: var(--soremo-blue);
  }

  .dropzone {
    height: 96px;
    align-content: center;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }

  .scene-list-take {
    width: 100%;
    padding: 0;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin-bottom: 36px;
  }

  .scene-take-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .scene-take-video {
    position: relative;
    width: 86px;
    height: 48px;

    img {
      width: 86px;
      background: #f0f0f0;
      border-radius: 4px;
      height: 48px;
      text-indent: -10000px;
      min-width: 86px;
      min-height: 48px;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  .scene-take-namne-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: 8px;
    padding-bottom: 16px;
    margin-top: 16px;
  }

  .scene-take-name-left {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .scene-take-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .scene-take-name-content {
      font-family: "A+mfCv-AXISラウンド 50 R StdN";
      font-size: 13px;
      line-height: 200%;
      color: #000;
    }

    .scene-take-time-content {
      font-family: "A+mfCv-AXISラウンド 50 L StdN";
      font-size: 8px;
      line-height: 100%;
      color: #a7a8a9;
      margin-left: 16px;
    }
  }

  .scene-take-list-variant {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;

    .variant-name-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      width: 100%;

      .scene-variant-file {
        font-family: "A+mfCv-AXISラウンド 50 L StdN";
        font-size: 11px;
        line-height: 200%;
        color: #a7a8a9;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        max-width: 50%;
      }
    }
  }
`;

const ModalFooter = styled.div`
  text-align: right;
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
`;

const Button = styled.div`
  cursor: pointer;
  min-width: 192px;
  padding: 8px 24px;
  border: 1px solid #333;
  text-align: center;
  border-radius: 3px;
  .btn.btn--tertiary,
  .btn.btn--tertiary:focus {
    color: #53565a !important;
    color: var(--black2-color) !important;
    background-color: transparent !important;
    padding: 5px;
  }
`;

export default AddSceneModal;
