export const linkList = {
  app: {
    creator_index: "/gallery",
    top_page: "/top?force=true",
    updateinfo: "/updateinfo",
    get_bookmarked: "/collection/get_bookmarked",
    direct_create_offer: "/direct/create_offer",
    artist_create_product: "/direct/artist_create_product",
  },
  account: {
    accounts_login: "/accounts/login",
    accounts_list: "/accounts/list",
    accounts_update: "/accounts/update",
    accounts_setting: "/accounts/setting",
    curator_setting: "/accounts/curator/curator_setting",
    payment_info: "/accounts/payment_info",
    creator_info: "/accounts/creator_info",
    creator_setting: "/accounts/creator_setting",
    logout: "/accounts/logout",
  },
  mileage: {
    index: "/mileages/",
  },
  payment: {
    master_admin: "/payments/master_admins/info",
    payment_current_artist: "/payments/artists/info",
  },
};
