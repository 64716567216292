import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import { useUploadFileMutation } from "../../app/services/project";

// Styled components
const Container = styled.div`
  width: 100%;
`;

const FileList = styled.div`
  margin-bottom: 8px;
`;

const UploadedFileName = styled.p`
  border-radius: 4px;
  background-color: #f0f0f0;
  padding: 2px 8px;
`;

const Dropzone = styled.div`
  width: 100%;
  border: 1px dashed #f0f0f0;
  border-radius: 6px;
  padding: 24px 16px;
  text-align: center;
  cursor: pointer;
  aspect-ratio: 16 / 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;

  .material-symbols-rounded {
    color: #a7a8a9;
  }

  &:hover {
    background-color: #009ace;
    color: #fff;

    .material-symbols-rounded {
      color: #fff;
    }
  }

  &:active {
    border-color: #009ace;
  }
`;

const FileUploader = () => {
  const [files, setFiles] = useState([]);
  const [uploadFile] = useUploadFileMutation();

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    acceptedFiles.forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        await uploadFile(formData).unwrap();
        // console.log(`${file.name} uploaded successfully.`);
      } catch (error) {
        // console.error(`Failed to upload ${file.name}.`, error);
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <Container>
      <FileList className="u-row u-gap8">
        {files.map((file) => (
          <UploadedFileName
            className="bodytext-11"
            key={file.path || file.name}
          >
            {file.name}
          </UploadedFileName>
        ))}
      </FileList>

      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here...</p>
        ) : (
          <div className="u-col-center">
            <div
              className="material-symbols-rounded"
              style={{ fontSize: "64px" }}
            >
              file_upload
            </div>
            <p>Click or drag & drop to upload a file</p>
          </div>
        )}
      </Dropzone>
    </Container>
  );
};

export default FileUploader;
