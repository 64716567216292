import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// AsyncThunkでAPI呼び出し
export const fetchMileageData = createAsyncThunk(
  "mileage/fetchMileageData",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("http://localhost:8000/mileage/my/", {
        headers: {
          Authorization: "Token e8991d625427c7f434f458d3915b5f01287dc782",
        },
      });
      return response.data; // 配列としてすべてのランクデータを返す
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "データの取得に失敗しました",
      );
    }
  },
);

// スライスを定義
const mileageSlice = createSlice({
  name: "mileage",
  initialState: {
    data: [], // データを配列形式で保持
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMileageData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMileageData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // 全データをステートに保存
      })
      .addCase(fetchMileageData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default mileageSlice.reducer;
