import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import "dayjs/locale/ja";

import ToolTip from "../tooltip/ToolTip";

dayjs.locale("ja");

// styled component
const Preview = styled.div`
  height: 72px;
  aspect-ratio: 1 / 1.414;

  background-color: var(--soremo-background);
  border: 1px solid var(--soremo-border);
`;

const DownloadIcon = styled.span.attrs({
  className: "material-symbols-rounded u-fontsize-24 u-text-light-gray",
})`
  cursor: pointer;
  &:hover {
    color: var(--soremo-blue);
  }
`;

const PaymentListItem = (props) => {
  const {
    applicationDate,
    isPaymentCompleted = false,
    paymentDate,
    paymentAmount,
  } = props;

  const formattedApplicationDate =
    dayjs(applicationDate).format("YYYY年MM月DD日");
  const formattedPaymentDate = dayjs(paymentDate).format("YYYY年MM月DD日");

  // 通貨記号を除外した金額のフォーマット
  const formatCurrencyWithoutSymbol = (amount) => {
    const parts = new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
    }).formatToParts(amount);
    return parts
      .filter((part) => part.type !== "currency")
      .map((part) => part.value)
      .join("");
  };

  const formattedPaymentAmount = formatCurrencyWithoutSymbol(paymentAmount);

  return (
    <>
      {!isPaymentCompleted ? (
        <div className="u-row u-gap8">
          <Preview />
          <div className="u-col u-gap4 u-w100 u-border-bottom u-ptb16">
            <ul className="u-row-between u-w100">
              <li className="bodytext-11 u-text-light-gray">
                申請日: {formattedApplicationDate}
              </li>
              <li className="bodytext-11 u-text-blue">
                振込完了までお待ちください
              </li>
            </ul>
            <ul className="u-row u-items-end u-gap4">
              <li className="heading-24 u-line-height-100">
                {formattedPaymentAmount}
              </li>
              <li className="bodytext-11 u-line-height-100">円（税込）</li>
            </ul>
          </div>
        </div>
      ) : (
        <>
          <div className="u-row u-gap8">
            <Preview />
            <div className="u-col u-gap4 u-w100 u-border-bottom u-ptb16">
              <div className="u-row-between u-w100">
                <div className="u-col u-gap4">
                  <ul className=" u-w100">
                    <li className="bodytext-11 u-text-light-gray">
                      支払日: {formattedPaymentDate}
                    </li>
                    <li className="bodytext-11 u-text-blue"></li>
                  </ul>
                  <ul className="u-row u-items-end u-gap4">
                    <li className="heading-24 u-line-height-100">
                      {formattedPaymentAmount}
                    </li>
                    <li className="bodytext-11 u-line-height-100">
                      円（税込）
                    </li>
                  </ul>
                </div>
                <ToolTip description="PDFをダウンロード" placement="left">
                  <DownloadIcon>download</DownloadIcon>
                </ToolTip>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// propTypes
PaymentListItem.propTypes = {
  applicationDate: PropTypes.string.isRequired,
  isPaymentCompleted: PropTypes.bool.isRequired,
  paymentDate: PropTypes.string,
  paymentAmount: PropTypes.number.isRequired,
};

export default PaymentListItem;
