import PropTypes from "prop-types";
import React, { useState } from "react";

const Button = ({
  name,
  size = "large",
  color = "primary",
  leftIcon,
  rightIcon,
  isDisabled = false,
  onClick,
}) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const btnPrimaryStyle = {
    color: "#fff",
    backgroundColor: isHover ? "#0076a5" : "#009ace",
    border: isHover ? "1px solid #0076a5" : "1px solid #009ace",
    outline: isHover ? "1px solid #0076a5" : "1px solid #009ace",
    borderRadius: "4px",
    transition: "0.2s",
    minWidth:
      size === "xl"
        ? "100%"
        : size === "large"
          ? "192px"
          : size === "medium"
            ? "160px"
            : size === "small"
              ? "128px"
              : size === "xs"
                ? "auto"
                : "auto",
    padding:
      size === "xl"
        ? "24px 24px"
        : size === "large"
          ? "12px 24px"
          : size === "medium"
            ? "8px 24px"
            : size === "small"
              ? "4px 12px"
              : size === "xs"
                ? "2px 8px"
                : "2px 8px",
    boxShadow: isHover ? "2px 4px 8px 3px rgba(0, 154, 206, 0.25)" : "none ",
    cursor: isHover ? "pointer" : "default",
  };
  const btnSecondaryStyle = {
    color: "#fff",
    backgroundColor: isHover ? "#009ace" : "#a7a8a9",
    border: isHover ? "1px solid #009ace" : "1px solid #a7a8a9",
    outline: isHover ? "1px solid #009ace" : "1px solid #a7a8a9",
    borderRadius: "4px",
    transition: "0.2s",
    minWidth:
      size === "xl"
        ? "100%"
        : size === "large"
          ? "192px"
          : size === "medium"
            ? "160px"
            : size === "small"
              ? "128px"
              : size === "xs"
                ? "auto"
                : "auto",
    padding:
      size === "xl"
        ? "24px 24px"
        : size === "large"
          ? "12px 24px"
          : size === "medium"
            ? "8px 24px"
            : size === "small"
              ? "4px 12px"
              : size === "xs"
                ? "2px 8px"
                : "2px 8px",
    boxShadow: isHover ? "2px 4px 8px 3px rgba(0, 154, 206, 0.25)" : "none ",
    cursor: isHover ? "pointer" : "default",
  };
  const btnTertiaryStyle = {
    color: isHover ? "#fff" : "#a7a8a9",
    backgroundColor: isHover ? "#a7a8a9" : "#fcfcfc",
    border: isHover ? "1px solid #a7a8a9" : "1px solid #fcfcfc",
    outline: isHover ? "1px solid #a7a8a9" : "1px solid #fcfcfc",
    borderRadius: "4px",
    transition: "0.2s",
    minWidth:
      size === "large"
        ? "144px"
        : size === "medium"
          ? "112px"
          : size === "small"
            ? "80px"
            : size === "xs"
              ? "auto"
              : "auto",
    padding:
      size === "large"
        ? "12px 24px"
        : size === "medium"
          ? "8px 24px"
          : size === "small"
            ? "4px 12px"
            : size === "xs"
              ? "2px 8px"
              : "2px 8px",
    boxShadow: isHover ? "2px 4px 8px 3px rgba(0, 0, 0, 0.10)" : "none ",
    cursor: isHover ? "pointer" : "default",
  };

  const btnBorderStyle = {
    color: isHover ? "#009ace" : "#a7a8a9",
    backgroundColor: "#ffffff",
    border: isHover ? "1px solid #009ace" : "1px solid #a7a8a9",
    outline: isHover ? "1px solid #009ace" : "1px solid #a7a8a9",
    borderRadius: "4px",
    transition: "0.2s",
    minWidth:
      size === "large"
        ? "144px"
        : size === "medium"
          ? "112px"
          : size === "small"
            ? "80px"
            : size === "xs"
              ? "auto"
              : "auto",
    padding:
      size === "large"
        ? "12px 24px"
        : size === "medium"
          ? "8px 24px"
          : size === "small"
            ? "4px 12px"
            : size === "xs"
              ? "2px 8px"
              : "12px 24px",
    boxShadow: isHover ? "2px 4px 8px 3px rgba(0, 154, 206, 0.25)" : "none ",
    cursor: isHover ? "pointer" : "default",
  };
  const btnIsDisabledStyle = {
    color: "#a7a8a9",
    backgroundColor: "#f0f0f0",
    border: "1px solid #f0f0f0",
    outline: "1px solid #f0f0f0",
    borderRadius: "4px",
    transition: "0.2s",
    minWidth:
      size === "large"
        ? "144px"
        : size === "medium"
          ? "112px"
          : size === "small"
            ? "80px"
            : size === "xs"
              ? "auto"
              : "auto",
    padding:
      size === "large"
        ? "12px 24px"
        : size === "medium"
          ? "8px 24px"
          : size === "small"
            ? "4px 12px"
            : size === "xs"
              ? "2px 8px"
              : "12px 24px",
    boxShadow: "none ",
    cursor: "not-allowed",
  };
  return (
    <button
      className="u-row-center u-gap16"
      style={{
        ...(isDisabled
          ? btnIsDisabledStyle
          : color === "primary"
            ? btnPrimaryStyle
            : color === "secondary"
              ? btnSecondaryStyle
              : color === "tertiary"
                ? btnTertiaryStyle
                : color === "border"
                  ? btnBorderStyle
                  : btnPrimaryStyle),
        ...(leftIcon && {
          paddingLeft: "8px",
        }),
        ...(rightIcon && {
          paddingLeft: "32px",
          paddingRight: "16px",
        }),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {leftIcon && (
        <span
          className="material-symbols-rounded u-fontsize-24"
          style={{ color: "inherit" }}
        >
          {leftIcon}
        </span>
      )}
      {name}
      {rightIcon && (
        <span
          className="material-symbols-rounded u-fontsize-24"
          style={{ color: "inherit" }}
        >
          {rightIcon}
        </span>
      )}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(["xl", "large", "medium", "small", "xs"]),
  color: PropTypes.oneOf(["primary", "secondary", "tertiary", "border"]),
  leftIcon: PropTypes.oneOf(["chevron_left", "handshake", "check", ""]),
  rightIcon: PropTypes.oneOf(["chevron_right", ""]),
  isDisabled: PropTypes.bool,
};

export default Button;
