import React from "react";

import ItemSentBlock from "./ItemSentBlock";

const CommentContentWithParent = ({ comment, user }) => {
  const isProduct = ["product", "messenger_owner", "messenger"].includes(
    comment?.type,
  );

  return (
    <>
      {comment?.comment && comment?.comment !== "" && (
        <div className="s-filedisable-wrap">
          <button className="s-filedisable s-filedisable--filedisable s-filedisable--black">
            {comment?.parentComment?.comment &&
            comment?.parentComment?.comment !== ""
              ? comment?.parentComment?.comment
              : comment?.parentComment?.name}
          </button>
          {!isProduct && comment?.pin_time ? (
            <div
              className="s-audio s-audio--audio s-audio--black"
              data-scene-id={comment?.scene?.scene_id}
            >
              <div className="s-audio-icon">
                <div className="s-audio-control video-pin-time">
                  <span className="material-symbols-rounded c-icon-play-audio u-fontsize-32 material-symbol-play">
                    play_circle
                  </span>
                  <span className="material-symbols-rounded c-icon-pause-audio u-fontsize-32 material-symbol-pause">
                    pause
                  </span>
                </div>
                <div className="s-audio-time video-pin-start">
                  {comment?.pin_time}
                </div>
              </div>
              <div className="s-filetext">{comment?.comment}</div>
            </div>
          ) : (
            <div className="s-filetext">{comment?.comment}</div>
          )}
        </div>
      )}
      {comment.fileList?.length > 0 &&
        comment.fileList.map((file, indexFile) => {
          if (!file.folder_id) {
            return (
              <div key={indexFile} className="comments-audio-block">
                <div className="s-filedisable-wrap">
                  <div className="s-filedisable s-filedisable--filedisable s-filedisable--black">
                    {comment?.parent_comment?.comment}
                  </div>
                  <ItemSentBlock
                    comment={comment}
                    file={file}
                    index={indexFile}
                  />
                </div>
              </div>
            );
          }
          return null;
        })}
      {comment.folders?.length > 0 &&
        comment.folders.map((folder, indexFolder) => {
          if (!folder.parent) {
            return (
              <ItemSentBlock
                comment={comment}
                folder={folder}
                index={indexFolder}
              />
            );
          }
          return null;
        })}
    </>
  );
};

export default CommentContentWithParent;
