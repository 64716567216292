export const fontSetting = [
  {
    id: 1,
    active: true,
    code: "code-font1",
    css_code: {
      fontFamily: "corporate-logo-ver2, sans-serif",
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "clamp(1.875rem, 1.375rem + 2.5vw, 2.375rem)",
      whiteSpace: "nowrap",
    },
    sample_code: "日本語コードネームを表示",
  },
  {
    id: 2,
    active: true,
    code: "code-font2",
    css_code: {
      fontFamily: "dnp-shuei-mincho-pr6n, sans-serif",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "clamp(1.5rem, 0.75rem + 3.75vw, 2.25rem)",
      whiteSpace: "nowrap",
    },
    sample_code: "日本語コードネームを表示",
  },
  {
    id: 3,
    active: false,
    code: "code-font3",
    css_code: {
      fontFamily: "din-2014, sans-serif",
      fontWeight: 800,
      fontStyle: "normal",
      fontSize: "clamp(2.5rem, 2.25rem + 1.25vw, 2.75rem)",
      textTransform: "uppercase",
    },
    sample_code: "CODE NAME",
  },
  {
    id: 4,
    active: true,
    code: "code-font4",
    css_code: {
      fontFamily: "futura-pt, sans-serif",
      fontWeight: 300,
      fontStyle: "italic",
      fontSize: "clamp(2.375rem, 2.125rem + 1.25vw, 2.625rem)",
    },
    sample_code: "CODE NAME",
  },
  {
    id: 5,
    active: true,
    code: "code-font5",
    css_code: {
      fontFamily: "trajan-pro-3, serif",
      fontWeight: 300,
      fontStyle: "normal",
      fontSize: "clamp(2rem, 1.875rem + 0.63vw, 2.125rem)",
      textTransform: "uppercase",
    },
    sample_code: "CODE NAME",
  },
  {
    id: 6,
    active: true,
    code: "code-font6",
    css_code: {
      fontFamily: "lindsey-signature, sans-serif",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "36px",
    },
    sample_code: "CODE NAME",
  },
];
