import React from "react";

import LoginpageLayout from "./login";

const routes = [
  {
    path: "/accounts/login",
    exact: true,
    component: <LoginpageLayout />,
    isHideTopBar: true,
  },
];

export default routes;
