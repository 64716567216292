import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import styled from "styled-components";

// Styled components
const TagContainer = styled.div`
  display: flex;
  flex-wrap: ${({ nowrap }) => (nowrap ? "nowrap" : "wrap")};
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  // max-width: 100%;
`;

const Tag = styled.span.attrs({
  className: "bodytext-13",
})`
  position: relative;
  margin: 4px 4px 4px 2px;
  padding: 4px 12px;
  border-radius: 4px;
  color: var(--soremo-light-gray);
  background-color: var(--soremo-background);
  outline: 1px solid var(--soremo-border);
  user-select: none;
  cursor: pointer;
  overflow: hidden;

  flex-shrink: 0;

  .shine {
    position: absolute;
    top: 0;
    left: -24px;
    width: 16px;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    transform: skewX(-15deg);
    pointer-events: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: linear-gradient(
        105deg,
        #feffc7 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }
  }
`;

const TagComponent = React.forwardRef(({ onClick, children }, ref) => {
  return (
    <Tag onClick={onClick} ref={ref}>
      {children}
      <span className="shine" />
    </Tag>
  );
});

const Autofill = (props) => {
  const { group, nowrap = false, onSelectContent } = props;
  const [keywords, setKeywords] = useState([]);
  const [previousContent, setPreviousContent] = useState(null);

  const [dataArray, setDataArray] = useState([]);

  // タグの参照を保持する配列
  const tagRefs = useRef([]);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const module = await import(`./${group}`);
        const data = module[group];

        if (Array.isArray(data)) {
          setDataArray(data);
          const allKeywords = data.map((item) => item.keyword);
          setKeywords(allKeywords);
        } else {
          setKeywords([]);
        }
      } catch {
        setKeywords([]);
      }
    };
    fetchKeywords();
  }, [group]);

  const handleKeywordClick = (keyword, index) => {
    animateShine(tagRefs.current[index]);

    const matchedItem = dataArray.find((item) => item.keyword === keyword);

    if (matchedItem && Array.isArray(matchedItem.contents)) {
      let availableContents = matchedItem.contents;

      // データが複数ある場合、前回のデータを除外
      if (availableContents.length > 1 && previousContent !== null) {
        availableContents = availableContents.filter(
          (content) => content !== previousContent,
        );
      }

      // ランダムに一つのデータを選択
      const randomContent =
        availableContents[Math.floor(Math.random() * availableContents.length)];

      // 前回選択したデータとして保存
      setPreviousContent(randomContent);

      // 親コンポーネントのコールバック関数を呼び出し、選択したデータを渡す
      if (onSelectContent) {
        onSelectContent(randomContent);
      }
    }
  };

  const animateShine = (tagElement) => {
    const shineElement = tagElement.querySelector(".shine");
    gsap.fromTo(
      shineElement,
      { left: "-24px" },
      {
        left: "100%",
        duration: 0.3,
        ease: "power2.inOut",
        onComplete: () => {
          // アニメーション後に初期位置に戻す
          gsap.set(shineElement, { left: "-24px" });
        },
      },
    );
  };

  return (
    <TagContainer nowrap={nowrap}>
      {keywords.map((keyword, index) => (
        <TagComponent
          key={index}
          onClick={() => handleKeywordClick(keyword, index)}
          ref={(el) => (tagRefs.current[index] = el)}
        >
          {keyword}
        </TagComponent>
      ))}
    </TagContainer>
  );
};

Autofill.propTypes = {
  group: PropTypes.oneOf(["criteria", "review", "schedule"]),
  nowrap: PropTypes.bool,
};

export default Autofill;
