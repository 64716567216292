import React, { useMemo } from "react";
import styled from "styled-components";

// import CommentBlock from "../block/CommentBlock.js"; // Assuming this is your existing CommentBlock component
// import NavigationTopBar from "../block/NavigationTopBar.js"; // Assuming you have this component
import CommentBox from "./CommentBox.jsx";
import CommentList from "./CommentList.jsx";

const MainTalkRoom = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: all 0.3s;
  ${(props) => (props.fileOpen ? `right: 267px;` : `right: 0;`)}
`;

const ProjectCommentsBlock = ({ data, user, project, fileOpen }) => {
  let user_role = data?.current_user_role;

  let comments = useMemo(
    () =>
      data?.comments?.map((comment) => {
        return {
          ...comment,
          ...data?.list_same_role?.find(
            (valueData) => valueData.comment_id === comment.comment_id,
          ),
          fileList: data?.comment_files
            ?.map((file) => {
              let extraInfo = data?.list_extra_file_info?.find(
                (dataFile) => dataFile?.file_id === file.file_id,
              );
              if (file.message_id === comment.comment_id) {
                return {
                  ...file,
                  ...extraInfo,
                };
              } else {
                return false;
              }
            })
            .filter(Boolean),
          folders: data?.folder
            ?.map((folder) => {
              if (folder.message_id === comment.comment_id) {
                let fileList = data?.comment_files
                  ?.map((file) => {
                    let extraInfo = data?.list_extra_file_info?.find(
                      (dataFile) => dataFile?.file_id === file.file_id,
                    );
                    if (file.folder_id === folder.folder_id) {
                      return {
                        ...file,
                        ...extraInfo,
                      };
                    } else {
                      return false;
                    }
                  })
                  .filter(Boolean);
                return {
                  ...folder,
                  children: fileList,
                };
              } else {
                return false;
              }
            })
            .filter(Boolean),
          parentComment: {
            ...data?.parent_comments?.find(
              (valueData) => valueData.comment_id === comment.parent_id,
            ),
          },
        };
      }),
    [
      data?.comments,
      data?.list_same_role,
      data?.comment_files,
      data?.folder,
      data?.list_extra_file_info,
      data?.parent_comments,
    ],
  );

  return (
    <MainTalkRoom fileOpen={fileOpen}>
      <div className="pd-comment refactor">
        <CommentBox isEditing={false} editingComment={{}} fileOpen={fileOpen} />

        <div className="pd-comment__main">
          <div className="pd-comment__content">
            <CommentList
              comments={comments}
              user={user}
              user_role={user_role}
            />
          </div>
        </div>
      </div>
    </MainTalkRoom>
  );
};

export default ProjectCommentsBlock;
