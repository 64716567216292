import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";

import { useGetProfileByNameQuery } from "../../../../app/services/auth";
import { useGetProjectQuery } from "../../../../app/services/project";
import ProjectBanner from "../components/ProjectBanner";
import BottomNavBar from "../components/BottomNavBar";
import DM from "./components/DM";

const ProjectPageDMLayout = () => {
  const { projectId } = useParams(); // Access the projectId
  const [modalMemberVisible, setModalMemberVisible] = useState(false);
  const { data: userInfo } = useGetProfileByNameQuery("");
  let {
    data: project,
    // isLoading: loading,
    // isFetching,
  } = useGetProjectQuery(projectId);

  const user = userInfo?.result;

  return (
    <main
      className={`owner-top prdt ${isMobile ? "on-mobile" : ""} ${user?.role === "admin" ? "is-admin" : ""}`}
    >
      <div className="container">
        <div className="new-video-menu">
          <div className="project-list">
            <div
              className={`project-item active ${isMobile ? "on-mobile" : ""}`}
            >
              {project ? (
                <ProjectBanner
                  project={project}
                  user={user}
                  modalMember={modalMemberVisible}
                  setModalMember={setModalMemberVisible}
                />
              ) : (
                <></>
              )}
              {project ? <DM user={user} project={project} /> : <></>}
            </div>
          </div>
        </div>
      </div>
      <BottomNavBar user={user} project={project} current={"dm"} />
    </main>
  );
};

export default ProjectPageDMLayout;
