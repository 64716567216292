export const colorSetting = [
  {
    id: 1,
    active: true,
    code: "banner-color1",
    css_code: {
      background: "#53565A",
    },
    text_color_css: "white",
  },
  {
    id: 2,
    active: true,
    code: "banner-color2",
    css_code: {
      background: "#3B5375",
    },
    text_color_css: "white",
  },
  {
    id: 3,
    active: true,
    code: "banner-color3",
    css_code: {
      background:
        "linear-gradient(to right, #3b5375, #33578b, #2a5aa1, #255db6, #2a5ecb)",
    },
    text_color_css: "white",
  },
  {
    id: 4,
    active: true,
    code: "banner-color4",
    css_code: {
      background:
        "linear-gradient(to left, #6fa8ed, #5a96e6, #4783de, #3771d5, #2a5ecb)",
    },
    text_color_css: "white",
  },
  {
    id: 5,
    active: true,
    code: "banner-color5",
    css_code: {
      background:
        "linear-gradient(135deg, #6eb370, #26b89b, #00b8c5, #00b2e3, #6fa8ed)",
    },
    text_color_css: "white",
  },
  {
    id: 6,
    active: true,
    code: "banner-color6",
    css_code: {
      background:
        "linear-gradient(to right, #6eb370, #94be72, #b8c979, #dad384, #fadd94)",
    },
    text_color_css: "white",
  },
  {
    id: 7,
    active: true,
    code: "banner-color7",
    css_code: {
      background:
        "linear-gradient(to right, #ffa254, #ffac83, #ffbbac, #ffcecf, #ffe3e8)",
    },
    text_color_css: "white",
  },
  {
    id: 8,
    active: true,
    code: "banner-color8",
    css_code: {
      background:
        "linear-gradient(135deg, #ea6586, #f2879e, #f8a6b6, #fdc5cf, #ffe3e8)",
    },
    text_color_css: "white",
  },
  {
    id: 9,
    active: true,
    code: "banner-color9",
    css_code: {
      background:
        "linear-gradient(135deg, #c68ed5, #d483c6, #df77b3, #e66d9e, #ea6586)",
    },
    text_color_css: "white",
  },
  {
    id: 10,
    active: true,
    code: "banner-color10",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(168, 186, 181), rgb(178, 196, 191), rgb(188, 207, 202), rgb(198, 217, 212), rgb(208, 228, 223))",
    },
    text_color_css: "white",
  },
  {
    id: 11,
    active: true,
    code: "banner-color11",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(255, 151, 143), rgb(254, 175, 159), rgb(251, 199, 175), rgb(247, 221, 191), rgb(240, 244, 208))",
    },
    text_color_css: "white",
  },
  {
    id: 12,
    active: true,
    code: "banner-color12",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(76, 224, 221), rgb(131, 229, 220), rgb(171, 233, 218), rgb(204, 237, 217), rgb(235, 241, 215))",
    },
    text_color_css: "white",
  },
  {
    id: 13,
    active: true,
    code: "banner-color13",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(11, 207, 228), rgb(102, 213, 229), rgb(145, 219, 229), rgb(180, 225, 230), rgb(211, 231, 230))",
    },
    text_color_css: "white",
  },
  {
    id: 14,
    active: true,
    code: "banner-color14",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(247, 246, 247), rgb(232, 239, 239), rgb(217, 232, 231), rgb(201, 224, 224), rgb(186, 217, 216))",
    },
    text_color_css: "black",
  },
  {
    id: 15,
    active: true,
    code: "banner-color15",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(254, 254, 254), rgb(246, 248, 243), rgb(238, 241, 232), rgb(231, 235, 222), rgb(223, 229, 211))",
    },
    text_color_css: "black",
  },
  {
    id: 16,
    active: true,
    code: "banner-color16",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(251, 251, 251), rgb(249, 246, 238), rgba(247, 241, 225, 0.5), rgba(245, 236, 213, 0.6), rgba(242, 231, 200, 0.7))",
    },
    text_color_css: "black",
  },
  {
    id: 17,
    active: true,
    code: "banner-color17",
    css_code: {
      background:
        "linear-gradient(135deg, rgb(253, 253, 253), rgb(247, 247, 246), rgb(242, 242, 239), rgb(236, 236, 232), rgb(231, 231, 225))",
    },
    text_color_css: "black",
  },
  {
    id: 18,
    active: true,
    code: "banner-color18",
    css_code: {
      background:
        "linear-gradient(315deg, #f0f0f0, #f3f3f3, #f6f6f6, #f9f9f9, #fcfcfc)",
    },
    text_color_css: "black",
  },
];
