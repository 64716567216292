import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import CommentBoxDM from "./CommentBoxDM";
import CommentListDM from "./CommentListDM";
import {
  setScrollUp,
  setScrollDown,
  resetScroll,
} from "../../../../../app/actions/layout/scroll";

const ProjectDMChatBoxContainer = ({ offerData, user, project }) => {
  const dispatch = useDispatch();
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = (e) => {
    const currentScrollPos = e?.target?.scrollTop;

    // Compare previous scroll position with the current to determine direction
    if (currentScrollPos > prevScrollPos) {
      dispatch(setScrollDown());
    } else if (currentScrollPos < prevScrollPos) {
      dispatch(setScrollUp());
    } else {
      dispatch(resetScroll());
    }

    setPrevScrollPos(currentScrollPos);
  };

  let user_role = offerData?.current_user_role;

  let comments = useMemo(
    () =>
      offerData?.comments?.map((comment) => {
        return {
          ...comment,
          ...offerData?.list_same_role?.find(
            (valueData) => valueData.comment_id === comment.comment_id,
          ),
          fileList: offerData?.comment_files
            ?.map((file) => {
              let extraInfo = offerData?.list_extra_file_info?.find(
                (dataFile) => dataFile?.file_id === file.file_id,
              );
              if (file.message_id === comment.comment_id) {
                return {
                  ...file,
                  ...extraInfo,
                };
              } else {
                return false;
              }
            })
            .filter(Boolean),
          folders: offerData?.folder
            ?.map((folder) => {
              if (folder.message_id === comment.comment_id) {
                let fileList = offerData?.comment_files
                  ?.map((file) => {
                    let extraInfo = offerData?.list_extra_file_info?.find(
                      (dataFile) => dataFile?.file_id === file.file_id,
                    );
                    if (file.folder_id === folder.folder_id) {
                      return {
                        ...file,
                        ...extraInfo,
                      };
                    } else {
                      return false;
                    }
                  })
                  .filter(Boolean);
                return {
                  ...folder,
                  children: fileList,
                };
              } else {
                return false;
              }
            })
            .filter(Boolean),
          parentComment: {
            ...offerData?.parent_comments?.find(
              (valueData) => valueData.comment_id === comment.parent_id,
            ),
          },
        };
      }),
    [
      offerData?.comments,
      offerData?.list_same_role,
      offerData?.comment_files,
      offerData?.folder,
      offerData?.list_extra_file_info,
      offerData?.parent_comments,
    ],
  );

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100% - 100px)",
        overflow: "auto",
      }}
      onScroll={handleScroll}
    >
      <CommentBoxDM />
      <div className="pd-comment__main">
        <div className="pd-comment__content">
          <CommentListDM
            type_comment={offerData?.type_comment}
            comments={comments}
            offer_user={offerData?.offer_user}
            user={user}
            user_role={user_role}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectDMChatBoxContainer;
