import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Avatar from "../avatar/Avatar";
// import Textarea from "../../components/form-item/Textarea";

// styled component
const StyledListBlock = styled.div`
  width: 100%;
  padding: 8px 8px;

  border-radius: 4px;
  border: 1px solid var(--soremo-border);
  background-color: #fff;

  /* Card drop shadow */
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: var(--soremo-background);
  }
`;

const StyledListBlockAdd = styled(StyledListBlock)`
    width: 100%;
    padding: 8px 8px;

  border-radius: 4px;
  border: 1px solid var(--soremo-border);
  background-color: #fff;


  /* Card drop shadow */
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    cursor: pointer;
    color: #fff;
    border: 1px solid var(--soremo-blue);
    background-color: var(--soremo-blue);

  .material-symbols-rounded {
    color: #fff;
  }
`;

const ListBlock = ({
  type,
  label,
  icon = "home",
  isSortable = false,
  isDeletable = false,
  onClick,
}) => {
  return (
    <>
      {type === "add" ? (
        <StyledListBlockAdd className="u-col-center u-gap4" onClick={onClick}>
          <div
            className="material-symbols-rounded"
            style={{ fontSize: "32px" }}
          >
            add_circle
          </div>
          <span className="bodytext-11 u-line-height-100">{label}</span>
        </StyledListBlockAdd>
      ) : (
        <StyledListBlock className="u-row-between" onClick={onClick}>
          {type === "user" ? (
            <div className="u-row-between">
              <div className="u-row u-gap8">
                <Avatar size="40" />
                <ul className="u-col u-gap8">
                  <li className="heading-13-spacing u-line-height-100">
                    アーティスト名
                  </li>
                  <li className="bodytext-11 u-line-height-100">ロール</li>
                </ul>
              </div>
            </div>
          ) : type === "link-text" ? (
            // Footer用リンク設定ブロック（リンク名称＋URL）
            <div className="u-row-between">
              <div className="u-row u-gap16">
                <ul className="u-col u-gap8">
                  <li className="heading-13-spacing u-line-height-100">
                    リンク名
                  </li>
                  <li className="heading-13 u-line-height-100">Link Name</li>
                </ul>
                <p className="bodytext-11 u-text-light-gray">
                  https://soremo.jp/
                </p>
              </div>
            </div>
          ) : type === "link-icon" ? (
            // Footer用リンク設定ブロック（アイコン＋URL）
            <div className="u-row-between">
              <div className="u-row u-gap16">
                <div
                  className="material-symbols-rounded"
                  style={{ fontSize: "24px" }}
                >
                  {icon}
                </div>
                <p className="bodytext-11 u-text-light-gray">
                  https://soremo.jp/
                </p>
              </div>
            </div>
          ) : type === "works" ? (
            // MySiteWorks作品一覧用ブロック
            <div className="u-row-between u-gap16">
              <div
                className="u-bg-light-gray"
                style={{ width: "126px", height: "126px", borderRadius: "4px" }}
              >
                作品サムネイル
              </div>
              <div className="u-col u-gap16">
                <div className="heading-spacing u-line-height-100">
                  作品名作品名作品名
                </div>
                <ul className="u-row u-gap8 u-text-light-gray">
                  <li className="bodytext-11 u-line-height-100">20XX年</li>
                  <li className="bodytext-11 u-line-height-100">
                    {" "}
                    #タグ #タグ #タグ
                  </li>
                </ul>
                <li className="bodytext-11 u-text-light-gray u-line-height-100">
                  サンプル視聴のみ
                </li>
              </div>
            </div>
          ) : type === "service" ? (
            // MySiteServiceサービス一覧用ブロック
            <div className="u-row-between u-gap16">
              <div
                className="u-bg-light-gray"
                style={{ width: "126px", height: "126px", borderRadius: "4px" }}
              >
                サービスサムネイル
              </div>
              <div className="u-col u-gap16">
                <div className="heading-spacing u-line-height-100">
                  サービス名サービス名
                </div>
                <ul className="u-col u-gap8 u-text-light-gray">
                  <li className="bodytext-11 u-line-height-100">
                    インタラクティブミュージック 効果音
                  </li>
                  <li className="bodytext-11 u-line-height-100">
                    {" "}
                    #タグ #タグ #タグ
                  </li>
                </ul>
              </div>
            </div>
          ) : type === "profile" ? (
            // MySiteText用（日本語）
            <div className="u-col-between u-w100 u-gap8">
              <div className="heading-spacing">題名</div>
              <div className="bodytext-11">
                私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。
              </div>
            </div>
          ) : type === "profile-03" ? (
            // MySiteText用（日本語）
            <div className="u-col-between u-w100 u-gap8">
              <div className="heading-spacing-1824">題名</div>
              <div className="">
                私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。
              </div>
            </div>
          ) : (
            <div>default</div>
          )}
          <div className="u-row u-gap8">
            {isSortable && (
              <div
                className="material-symbols-rounded"
                style={{ fontSize: "24px" }}
              >
                drag_indicator
              </div>
            )}
            {isDeletable && (
              <div
                className="material-symbols-rounded"
                style={{ fontSize: "24px" }}
              >
                delete
              </div>
            )}
          </div>
        </StyledListBlock>
      )}
    </>
  );
};

ListBlock.propTypes = {
  type: PropTypes.oneOf(["add", "user", "link-text", "link-icon", "works"]),
};

export default ListBlock;
