const numberOfDayToDeadline = (deadline) => {
  if (deadline) {
    const currentDay = new Date();
    currentDay.setDate(currentDay.getDate() - 1); // Subtract 1 day to match the Python code
    const deadlineDate = new Date(deadline); // Convert string to Date
    const timeDiff = deadlineDate - currentDay; // Difference in milliseconds
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  }
  return null;
};

export const getDataOfferByRole = (user, offer) => {
  if (user?.role) {
    if (user.role !== "master_admin") {
      return getDataOfferByRoleCreator(offer);
    } else if (user?.role === "master_admin") {
      return getDataOfferByRoleAdmin(offer);
    }
  } else {
    if (user?.role !== "master_admin") {
      return getDataOfferByRoleCreator(offer);
    } else if (user?.role === "master_admin") {
      return getDataOfferByRoleAdmin(offer);
    }
  }
  return null;
};

// Function for creator's role
const getDataOfferByRoleCreator = (offer) => {
  const result = {};
  const offerStatus = offer?.offer_creator?.status;
  const numberOfDay = numberOfDayToDeadline(offer?.offer_creator?.deadline);

  switch (offerStatus) {
    case "1":
      result.color = "#A7A8A9";
      result.text = "オファーを確認";
      break;
    case "2":
      result.color = "#009ACE";
      result.text = `納期まであと${numberOfDay}日`;
      if (numberOfDay < 0) {
        result.text = `納期を${Math.abs(numberOfDay)}日超過`;
      }
      break;
    case "3":
      result.color = "white";
      result.text = "検収待ち";
      break;
    case "4":
      result.color = "#53565A";
      result.text = "レビューを書く";
      // if (review) {
      //   result.text = 'お疲れさまでした';
      // }
      break;
    default:
      return null;
  }

  return result;
};

// Function for admin's role
const getDataOfferByRoleAdmin = (offer) => {
  const result = {};
  const offerStatus = offer?.offer_creator?.status;
  const numberOfDay = numberOfDayToDeadline(offer?.offer_creator?.deadline);

  switch (offerStatus) {
    case "1":
      result.color = "#A7A8A9";
      result.text = "オファー済み";
      break;
    case "2":
      result.color = "white";
      result.text = `納期まであと${numberOfDay}日`;
      if (numberOfDay < 0) {
        result.color = "white";
        result.text = `納期を${Math.abs(numberOfDay)}日超過`;
      }
      break;
    case "3":
      result.color = "#009ACE";
      result.text = "検収しよう";
      break;
    case "4":
      result.color = "#53565A";
      result.text = "レビューを書く";
      // if (review) {
      //   result.text = 'お疲れさまでした';
      // }
      break;
    default:
      return null;
  }

  return result;
};

export const getDataProjectOrder = (user, offer, project) => {
  switch (user?.role) {
    case "master_client":
      return getDataProjectOrderByOwner(offer, project);
    case "master_admin":
    case "admin":
      return getDataProjectOrderByRole(offer, project);
    default:
      return false;
  }
};

export const getDataProjectOrderByOwner = (offer, project) => {
  const offerStatus = offer.offer_product_condition;
  const numberOfDay = numberOfDayToDeadline(offer.offer_product_deadline);
  const currentHeart = project.current_heart;
  const currentDate = new Date();
  let result = {};

  switch (offerStatus) {
    case "1":
      result.color = "#A7A8A9";
      result.text = "見積り待ち";
      break;
    case "8":
      result.color = "#A7A8A9";
      result.text = "見積から注文";
      break;
    case "9":
      result.color = "#A7A8A9";
      result.text = `納期まであと${numberOfDay}日`;
      if (numberOfDay < 0) {
        result.text = "";
      }
      break;
    case "2":
      result.color = "white";
      result.text = "契約を締結";
      break;
    case "3":
      result.color = "white";
      result.text = `納期まであと${numberOfDay}日`;
      if (numberOfDay < 0) {
        result.color = "white";
        result.text = `納期を${Math.abs(numberOfDay)}日超過`;
      }
      if (
        currentHeart < 100 &&
        offer.offer_product_deadline &&
        currentDate > new Date(offer.offer_product_deadline)
      ) {
        result.color = "white";
        result.text = `納期を${Math.abs(numberOfDay)}日超過`;
      } else if (currentHeart === 100) {
        result.color = "#009ACE";
        result.text = "検収する";
      }
      break;
    case "4":
      result.color = "#53565A";
      result.text = "請求待ち";
      break;
    case "5":
      result.color = "#53565A";
      result.text = "支払い";
      break;
    case "6":
      result.color = "#53565A";
      result.text = "お疲れさまでした";
      break;
    default:
      return null;
  }

  return result;
};

export const getDataProjectOrderByRole = (offer, project) => {
  const offerStatus = offer.offer_product_condition;
  const numberOfDay = numberOfDayToDeadline(offer.offer_product_deadline);
  const currentHeart = project.current_heart;
  const currentDate = new Date();
  let result = {};

  switch (offerStatus) {
    case "1":
      result.color = "#A7A8A9";
      result.text = "見積書を提示";
      break;
    case "8":
      result.color = "#A7A8A9";
      result.text = "注文待ち";
      break;
    case "9":
      result.color = "#A7A8A9";
      result.text = "契約書を提示";
      break;
    case "2":
      result.color = "#A7A8A9";
      result.text = `納期まであと${numberOfDay}日`;
      if (numberOfDay < 0) {
        result.text = "";
      }
      break;
    case "3":
      result.color = "#009ACE";
      result.text = `納期まであと${numberOfDay}日`;
      if (numberOfDay < 0) {
        result.text = `納期を${Math.abs(numberOfDay)}日超過`;
      }
      if (
        currentHeart < 100 &&
        offer.offer_product_deadline &&
        currentDate > new Date(offer.offer_product_deadline)
      ) {
        result.color = "#009ACE";
        result.text = `納期を${Math.abs(numberOfDay)}日超過`;
        if (numberOfDay < 0) {
          result.color = "#009ACE";
          result.text = `納期を${Math.abs(numberOfDay)}日超過`;
        }
      } else if (currentHeart === 100) {
        result.color = "white";
        result.text = "検収待ち";
      }
      break;
    case "4":
      result.color = "#53565A";
      result.text = "請求書を提出";
      break;
    case "5":
      result.color = "#53565A";
      result.text = "支払い待ち";
      break;
    case "6":
      result.color = "#53565A";
      result.text = "お疲れさまでした";
      break;
    default:
      return null;
  }

  return result;
};
