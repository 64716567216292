import React from "react";
import styled from "styled-components";

import { DAY_OF_WEEK } from "../../../../../../constant/index";
import FileInfoBlock from "./FileInfoBlock";
import FolderInfoBlock from "./FolderInfoBlock";

const FileList = styled.div`
  margin-bottom: 8px;
`;

const FileItem = styled.div`
  margin-bottom: 10px;

  .tfile-item-time {
    margin-bottom: 8px;
    color: #a7a8a9;
    font-size: 8px;
  }

  .tfile-item-content {
    cursor: pointer;
    margin-bottom: 5px;

    .tfile-type:last-child {
      margin-bottom: 0;
    }

    .tfile-type {
      position: relative;
      margin-bottom: 8px;

      .tfile-file {
        position: relative;

        .s-file {
          line-height: 20px;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          margin-right: 5px;
          border: 1px solid #f0f0f0;
          background-color: #fcfcfc;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 8px 16px;
          font-size: 12px;
          word-break: break-word;
          border-radius: 6px;
          justify-content: space-between;
        }

        .sview-user {
          position: absolute;
          left: 10px;
          bottom: 6px;

          .notification {
            margin-left: 2px;
            font-size: 8px;
            color: #53565a;
            border: 1px solid #a7a8a9;
            height: 14px;
            line-height: 12px;
            background-color: #fff;
            border-radius: 12px;
            padding: 0 8px;
            min-width: 15px;
            text-align: center;
          }
        }
      }
    }
  }
`;

const FileItemBlock = ({ comments, fileList, folderList, user, project }) => {
  const currentDate = new Date();
  let newListFileAndFolder = [...fileList];

  // Merge folder and file lists
  if (folderList.length > 0) {
    const sFolders = folderList.map((folder) => {
      const listFile = fileList.filter(
        (file) => file.folder_id === folder.folder_id,
      );
      if (listFile.length > 0) {
        listFile.forEach((file) => {
          newListFileAndFolder = newListFileAndFolder.filter(
            (f) => f.file_id !== file.file_id,
          );
        });
        return { ...folder, children: listFile };
      }
      return folder;
    });
    newListFileAndFolder = [...newListFileAndFolder, ...sFolders];
  }

  // Sort the files and folders by modified date
  newListFileAndFolder = newListFileAndFolder.sort(
    (a, b) => new Date(b.modified) - new Date(a.modified),
  );

  const getListFileAndFolderByDate = (fileAndFolderArray) => {
    let output = [];
    let dateArray = [];
    let currentDateFile = [];

    let dataDate = false;
    let isCurrentDate = false;
    let isDayOfWeek = false;
    let lastDateOutput = "";
    let dateOutput = "";

    fileAndFolderArray.map((objectData) => {
      dataDate = new Date(objectData?.modified);
      isCurrentDate = false;
      isDayOfWeek = false;

      // Check if the file or folder was modified today
      if (
        dataDate.getMonth() === currentDate.getMonth() &&
        dataDate.getDate() === currentDate.getDate()
      ) {
        isCurrentDate = true;
      }

      // Check if the file/folder is the first modification of the hour
      // if (isCurrentDate && (dataDate.getHours() !== dateTime.getHours() || dataDate.getMinutes() !== dateTime.getMinutes())) {
      //   isFirstHour = true;
      // }

      // Check if the file/folder was modified within the last 7 days
      if ((currentDate - dataDate) / (24 * 3600 * 1000) < 7) {
        isDayOfWeek = true;
      }

      // Check if it's the first time this file/folder is being displayed
      // if (dateTime?.getMonth() !== dataDate.getMonth() || dataDate.getDate() !== dateTime?.getDate()) {
      //   isFirstTime = true;
      // }

      // dateTime = new Date(objectData?.modified);

      dateOutput = isCurrentDate
        ? `${new Date(objectData?.modified).getHours().toString().padStart(2, "0")}:${new Date(objectData?.modified).getMinutes().toString().padStart(2, "0")}`
        : isDayOfWeek
          ? DAY_OF_WEEK[dataDate.getDay()]
          : `${new Date(objectData?.modified).getMonth() + 1}/${new Date(objectData?.modified).getDate()}`;

      if (
        dateOutput !== lastDateOutput &&
        !dateArray.includes(dateOutput) &&
        lastDateOutput !== ""
      ) {
        //this mean this is new date, we will add file to output
        output.push({
          date: lastDateOutput,
          files: currentDateFile,
        });

        currentDateFile = [];
      }

      if (!dateArray.includes(lastDateOutput) && lastDateOutput !== "") {
        dateArray.push(lastDateOutput);
      }

      lastDateOutput = dateOutput;
      currentDateFile.push(objectData);

      return objectData;
    });

    return output;
  };

  return (
    <div className="tfile">
      <FileList>
        {getListFileAndFolderByDate(newListFileAndFolder).map((objectData) => {
          let dateString = objectData?.date;
          let fileArray = objectData?.files;

          return (
            <FileItem key={dateString}>
              <div className={`tfile-item-time`}>{dateString}</div>
              <div className="tfile-item-content">
                {fileArray.map((fileOrFolder) => {
                  if (fileOrFolder?.file_id) {
                    return (
                      <FileInfoBlock file={fileOrFolder} comments={comments} />
                    );
                  } else {
                    return (
                      <div className="tfile-file">
                        <FolderInfoBlock
                          folder={fileOrFolder}
                          comments={comments}
                          comment_files={fileList}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </FileItem>
          );
        })}
      </FileList>
    </div>
  );
};

export default FileItemBlock;
