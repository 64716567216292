import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

// styled-components
const StyledTextarea = styled.textarea.attrs({
  className: "bodytext",
})`
  line-height: 150%;
`;

const StyledCounter = styled.div.attrs({
  className: "bodytext-11 u-w100",
})`
  text-align: right;
  color: ${(props) =>
    props.isOverLimit ? "var(--soremo-green)" : "var(--soremo-light-gray)"};
`;

const Textarea = React.forwardRef((props, ref) => {
  const { name, id, rows, placeholder, maxLength, value, onChange } = props;

  return (
    <>
      <StyledTextarea
        name={name}
        id={id}
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        ref={ref}
      />
      {maxLength && (
        <StyledCounter isOverLimit={value.length >= maxLength}>
          {value.length}/{maxLength}
        </StyledCounter>
      )}
    </>
  );
});

Textarea.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Textarea;
