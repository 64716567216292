import React, { useState } from "react";
import styled from "styled-components";

const BlockSearchOffer = styled.div`
  padding: 0 8px 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-size: clamp(13px, 10px + 0.94vw, 16px);
  line-height: 200%;
  position: relative;
`;

const ArtistSearchBox = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsSearchActive(e.target.value.length > 0);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setIsSearchActive(false);
  };

  return (
    <BlockSearchOffer>
      <span className="material-symbols-rounded c-icon-search">search</span>
      <input
        className="input-search-offer"
        type="search"
        id="pm-search"
        placeholder="スレッドを検索"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {isSearchActive && (
        <span
          className="material-symbols-rounded c-icon-close-small search-delete-icon"
          onClick={handleClearSearch}
        >
          close_small
        </span>
      )}
    </BlockSearchOffer>
  );
};

export default ArtistSearchBox;
