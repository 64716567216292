import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import {
  setScrollUp,
  setScrollDown,
  resetScroll,
} from "../../../../../app/actions/layout/scroll";
import CommentBlock from "./CommentBlock";

const MessageList = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
`;

const CommentList = ({ comments, user }) => {
  const dispatch = useDispatch();
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = (e) => {
    const currentScrollPos = e?.target?.scrollTop;

    // Compare previous scroll position with the current to determine direction
    if (currentScrollPos > prevScrollPos) {
      dispatch(setScrollDown());
    } else if (currentScrollPos < prevScrollPos) {
      dispatch(setScrollUp());
    } else {
      dispatch(resetScroll());
    }

    setPrevScrollPos(currentScrollPos);
  };

  return (
    <MessageList
      className="mmessage-list mscrollbar mscrollbar--vertical refactor mscrollbar--bottom"
      onScroll={handleScroll}
    >
      <div className="mmessage-list-container refactor">
        {comments?.map((comment, index) => {
          return (
            <CommentBlock
              key={comment.comment_id || index} // assuming comment_id exists for each comment
              comment={{
                ...comment,
                type: comments.type,
              }}
              user={user}
            />
          );
        })}
      </div>
    </MessageList>
  );
};

export default CommentList;
