import React from "react";

import "./index.scss";
import ListLogo from "./components/ListLogo";
import FunctionBlock from "./components/FunctionBlock";
import ArticleBlock from "./components/ArticleBlock";
import SignUpBlock from "./components/SignUpBlock";
import VideoBlock from "./components/VideoBlock";
import HeaderBlock from "./components/HeaderBlock";
import FooterBlock from "./components/FooterBlock";

const HomepageLayout = () => {
  return (
    <>
      <HeaderBlock />
      <VideoBlock />
      <SignUpBlock />
      <ArticleBlock />
      <FunctionBlock />
      <ListLogo />
      <FooterBlock />
    </>
  );
};

export default HomepageLayout;
