import React from "react";
import styled from "styled-components";

const NavigateBeforeContainer = styled.ul`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  cursor: pointer;
  transition: 0.2s;

  position: absolute;
  top: 8px;
  left: 0px;

  z-index: 7;

  color: var(--soremo-blue);

  .material-symbols-rounded {
    color: var(--soremo-blue);
  }

  &:hover {
    color: var(--soremo-deep-blue);
    transform: scale(1.05) translate(2%, 0);

    .material-symbols-rounded {
      color: var(--soremo-deep-blue);
    }
  }
`;

const NavigateBefore = ({ name = "戻る", onClick }) => {
  return (
    <>
      <NavigateBeforeContainer onClick={onClick}>
        <li className="material-symbols-rounded">navigate_before</li>
        <li>{name}</li>
      </NavigateBeforeContainer>
    </>
  );
};

export default NavigateBefore;
