import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect } from "react";
import { EffectCards } from "swiper/modules";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "../../../components/swiper/swiper.scss";
import "../../../components/swiper/effect-cards.scss";
import "../../../components/swiper/credit-cards-swiper.css";
import TitleBlock from "../../../components/block/TitleBlock";
import ListBlock from "../../../components/block/ListBlock";
import Modal from "../../../components/modal/Modal";
import PaymentForm from "../../../components/payment-form/PaymentForm";
import IconButton from "../../../components/button/IconButton";
import ToolTip from "../../../components/tooltip/ToolTip";

// stripeの公開可能キーを設定（モックデータ）
const stripePromise = loadStripe("pk_test_CZZafTUOaKacl7g2F0kgKkgm");

const CreditCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(
    "test_client_secret_1234567890",
  );

  //モックデータ
  const [paymentMethods] = useState([
    {
      id: 1,
      card: { brand: "visa", last4: "0000", exp_month: "03", exp_year: "26" },
    },
    {
      id: 2,
      card: {
        brand: "mastercard",
        last4: "1234",
        exp_month: "05",
        exp_year: "27",
      },
    },
    {
      id: 3,
      card: { brand: "visa", last4: "5678", exp_month: "06", exp_year: "26" },
    },
    {
      id: 4,
      card: { brand: "visa", last4: "9012", exp_month: "05", exp_year: "27" },
    },
    {
      id: 5,
      card: { brand: "visa", last4: "3456", exp_month: "03", exp_year: "28" },
    },
  ]);

  // モーダルを開いたときにclientSecretを取得
  useEffect(() => {
    if (isModalOpen) {
      fetch("/api/create-setup-intent", {
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
        })
        .catch((error) => {
          // console.error("Error fetching client secret:", error);
        });
    }
  }, [isModalOpen]);

  return (
    <>
      <TitleBlock
        title="クレジットカード情報"
        description="スワイプして、お支払いのメインカードを設定"
      />
      {isModalOpen && clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <Modal onClose={() => setIsModalOpen(false)}>
            <PaymentForm
              clientSecret={clientSecret}
              onClose={() => setIsModalOpen(false)}
            />
          </Modal>
        </Elements>
      ) : (
        <>
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            initialSlide={0}
            loop={false}
          >
            {paymentMethods.map((method) => (
              <SwiperSlide key={method.id}>
                <div className="u-col u-mb8">
                  <div className="heading-24">
                    {method.card.brand.toUpperCase()} **** {method.card.last4}
                  </div>
                  <div className="bodytext-16">
                    {method.card.exp_month}/{method.card.exp_year}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="u-w100 u-row-center u-mb48">
            <div
              className="u-row-end"
              style={{
                width: "min(100% - 16px, 32px * 13)",
              }}
            >
              <ToolTip
                description="このクレジットカード情報を削除"
                placement="left"
              >
                <IconButton onClick={() => {}}>delete</IconButton>
              </ToolTip>
            </div>
          </div>
          <ListBlock
            type="add"
            label="新しいクレジットカード情報を追加"
            onClick={() => setIsModalOpen(true)}
          />
        </>
      )}
    </>
  );
};

export default CreditCard;
