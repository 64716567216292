// scrollSlice.js
import { createSlice } from "@reduxjs/toolkit";

const scrollSlice = createSlice({
  name: "scroll",
  initialState: "init",
  reducers: {
    setScrollUp: () => "up",
    setScrollDown: () => "down",
    resetScroll: () => "init",
  },
});

export const { setScrollUp, setScrollDown, resetScroll } = scrollSlice.actions;

export default scrollSlice.reducer;
