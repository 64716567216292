import React, { useEffect } from "react";

const SvgLoadingAnimation = () => {
  useEffect(() => {
    function animationLoading(time) {
      const circles = document.querySelectorAll(".circle");
      circles.forEach((circle, index) => {
        const angle = (time * (2 - index / 10)) / 5;
        circle.setAttribute("transform", `rotate(${angle} 0 0)`);
      });

      requestAnimationFrame(animationLoading);
    }

    // Start the animation
    animationLoading(0);

    return () => {
      // Clean up animation on component unmount
      cancelAnimationFrame(animationLoading);
    };
  }, []);

  return (
    <div
      id="loading_component"
      style={{ width: "64px", height: "64px", display: "inline-block" }}
    >
      <svg className="loader-example" viewBox="0 0 100 100">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="
                1 0 0 0 0
                0 1 0 0 0
                0 0 1 0 0
                0 0 0 25 -8"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
        <g filter="url(#goo)" fill="#f0f0f0" stroke="#fcfcfc">
          <g transform="translate(50, 50)">
            <g className="circle -a">
              <g transform="translate(-50, -50)">
                <circle cx="25" cy="50" r="9" />
              </g>
            </g>
          </g>
          <g transform="translate(50, 50)">
            <g className="circle -b">
              <g transform="translate(-50, -50)">
                <circle cx="50" cy="25" r="8" />
              </g>
            </g>
          </g>
          <g transform="translate(50, 50)">
            <g className="circle -c">
              <g transform="translate(-50, -50)">
                <circle cx="75" cy="50" r="7" />
              </g>
            </g>
          </g>
          <g transform="translate(50, 50)">
            <g className="circle -d">
              <g transform="translate(-50, -50)">
                <circle cx="50" cy="75" r="6" />
              </g>
            </g>
          </g>
          <g transform="translate(50, 50)">
            <g className="circle -e">
              <g transform="translate(-50, -50)">
                <circle cx="25" cy="50" r="5" />
              </g>
            </g>
          </g>
          <g transform="translate(50, 50)">
            <g className="circle -f">
              <g transform="translate(-50, -50)">
                <circle cx="50" cy="25" r="4" />
              </g>
            </g>
          </g>
          <g transform="translate(50, 50)">
            <g className="circle -g">
              <g transform="translate(-50, -50)">
                <circle cx="75" cy="50" r="3" />
              </g>
            </g>
          </g>
          <g transform="translate(50, 50)">
            <g className="circle -h">
              <g transform="translate(-50, -50)">
                <circle cx="50" cy="75" r="2" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default SvgLoadingAnimation;
