import React from "react";

import PaymentDetails from "./components/PaymentDetails";
import TransactionHistory from "./components/TransactionHistory";
import BankAccount from "./components/BankAccount";
import CreditCard from "./components/CreditCard";
import Notification from "./components/Notification";
import OfferConditions from "./components/OfferConditions";
import PersonalInformation from "./components/PersonalInformation";
import Schedule from "./components/Schedule";
import FollowersAndFollowing from "./components/FollowersAndFollowing";
import MySitePublic from "./components/MySitePublic";
import MySiteContact from "./components/MySiteContact";
import MySiteDesign from "./components/MySiteDesign";
import MySiteState from "./components/MySiteState";
import MySiteText from "./components/MySiteText";
import MySiteService from "./components/MySiteService";
import MySiteWorks from "./components/MySiteWorks";
import MySiteFooter from "./components/MySiteFooter";
import EmailPassword from "./components/EmailPassword";

const rightColumnComponents = {
  paymentDetails: <PaymentDetails />,
  transactionHistory: <TransactionHistory />,
  bankAccount: <BankAccount />,
  creditCard: <CreditCard />,
  notification: <Notification />,
  offerConditions: <OfferConditions />,
  schedule: <Schedule />,
  followersAndFollowing: <FollowersAndFollowing />,
  mySitePublic: <MySitePublic />,
  mySiteContact: <MySiteContact />,
  mySiteDesign: <MySiteDesign />,
  mySiteState: <MySiteState />,
  mySiteText: <MySiteText />,
  mySiteService: <MySiteService />,
  mySiteWorks: <MySiteWorks />,
  mySiteFooter: <MySiteFooter />,
  emailPassword: <EmailPassword />,
  personalInformation: <PersonalInformation />,
};

export default rightColumnComponents;
