import React, { useEffect, useState } from "react";

import Button from "../../../components/button/Button";
import GuideImage from "../../../components/guide-image/GuideImage";
import Input from "../../../components/form-item/Input";
import TitleBlock from "../../../components/block/TitleBlock";
import Modal from "../../../components/modal/Modal";

const EmailPassword = () => {
  const [email, setEmail] = useState(""); // ローカルのメールアドレス状態
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState(""); // 新しいパスワード
  const [confirmPassword, setConfirmPassword] = useState(""); // 確認用パスワード
  const [error, setError] = useState(null); // エラーメッセージ

  // サーバーからデータを取得
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/auth-user/1/");
        if (!response.ok) {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
        const data = await response.json();
        const result = data.result || {};
        setEmail(result.email || ""); // メールアドレスを設定
        setError(null); // データが正常に取得できた場合、エラーをクリア
      } catch (err) {
        setError("ユーザーデータの取得に失敗しました。");
      }
    };

    fetchUserData();
  }, []);

  // サーバーにデータを同期
  const updateUserData = async (field, value) => {
    try {
      const response = await fetch("http://localhost:8000/api/auth-user/1/", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ [field]: value }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update user data: ${response.statusText}`);
      }

      setError(null); // データが正常に更新できた場合、エラーをクリア
    } catch (err) {
      setError("有効なメールアドレスを入力してください。");
    }
  };

  // メールアドレス変更処理
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail); // ローカルで即時反映

    // メールアドレスの簡易的なバリデーション
    if (/\S+@\S+\.\S+/.test(newEmail)) {
      updateUserData("email", newEmail); // サーバーに同期
      setError(null); // 有効なメールアドレスの場合、エラーをクリア
    } else {
      setError("有効なメールアドレスを入力してください。");
    }
  };

  // パスワード変更処理
  const handlePasswordChange = async () => {
    if (password !== confirmPassword) {
      setError("パスワードが一致しません。");
      return;
    }

    try {
      const response = await fetch("http://localhost:8000/api/auth-user/1/", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update password: ${response.statusText}`);
      }

      setIsPasswordModalOpen(false);
      setPassword(""); // パスワードをリセット
      setConfirmPassword(""); // 確認用パスワードもリセット
      setError(null); // データが正常に更新できた場合、エラーをクリア
    } catch (err) {
      setError("パスワードの更新に失敗しました。");
    }
  };

  return (
    <>
      <TitleBlock title="メールアドレス・パスワード" />
      <div className="u-col u-gap16">
        <GuideImage name="account" />
        <p>サインインに必要なメールアドレスとパスワードを変更できます。</p>
        {error && <p style={{ color: "red" }}>{error}</p>}{" "}
        {/* エラーメッセージの表示 */}
        <div className="u-w100">
          <Input
            label="メールアドレス"
            option="[必須]"
            type="email"
            name="email"
            inputmode="email"
            placeholder="username@soremo.jp"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <Button
          name="パスワード変更"
          size="medium"
          color="secondary"
          onClick={() => setIsPasswordModalOpen(true)}
        />
        {isPasswordModalOpen && (
          <Modal>
            <div className="u-w100 u-col u-gap16">
              <Input
                label="パスワード"
                option="[必須]"
                type="password"
                autocomplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                label="パスワード確認"
                option="[必須]"
                type="password"
                autocomplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="u-w100 u-row-end u-gap8 u-pt16">
              <Button
                name="キャンセル"
                size="medium"
                color="tertiary"
                onClick={() => setIsPasswordModalOpen(false)}
              />
              <Button
                name="変更"
                size="medium"
                color="primary"
                onClick={handlePasswordChange}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default EmailPassword;
