import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { getDataProjectOrder } from "../../common";

const ItemOffer = styled.li`
  background-color: #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  height: inherit;
  border-radius: 0;
  border-radius: initial;
  padding: 0;
  padding: initial;
  -webkit-box-orient: initial;
  -webkit-box-direction: initial;
  -ms-flex-direction: initial;
  flex-direction: initial;
  margin-bottom: 0;
  ${(props) => (props?.isCurrentOffer ? "background-color: #f0f0f0;" : "")}

  .progress-offer {
    background-color: black;
    width: 4px;
    height: auto;
  }

  .offer-main {
    width: 100%;
    position: relative;
    padding: 8px 0px 8px 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 4px;
  }

  .grade-icon {
    visibility: visible;
    color: #fff000;
    text-shadow: 0px 0px 4px rgba(249, 248, 113, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content-offer {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 8px;

    .block-offer {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      gap: 8px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;

      .block-offer-1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;

        .title-offer {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          font-family: "A+mfCv-AXISラウンド 50 R StdN", "M PLUS 1p", sans-serif;
          font-style: normal;
          font-size: 13px;
          line-height: 100%;
          letter-spacing: 2.5px;
          color: black;
          word-wrap: break-word;
        }
        .time-offer {
          font-weight: normal;
          font-size: 8px;
          line-height: 100%;
          color: #a7a8a9;
        }
      }

      .block-offer-2 {
        width: 100%;
        gap: 6px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .block-avatar-user {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          font-weight: normal;
          font-size: clamp(13.008px, 10px + 0.94vw, 16px);
          font-size: clamp(0.813rem, 0.625rem + 0.94vw, 1rem);
          line-height: 200%;

          .avatar-user-offer {
            width: 24px;
            height: 24px;
            border-radius: 10px;
            border: 1px solid #fff;
            -webkit-box-shadow: 2px 4px 10px 0px #e5e5e5;
            box-shadow: 2px 4px 10px 0px #e5e5e5;
          }
        }

        .description-content-offer {
          line-height: 100%;
          font-weight: normal;
          font-size: 11px;
          color: #a7a8a9;
        }
      }
    }
  }
`;

const OfferTwo = ({ offer, user, project, currentOffer }) => {
  // Formatting the modification time, assuming realOffer.modified is a Date object
  const navigate = useNavigate();
  const formatDate = (date) => {
    // You can use libraries like moment.js or date-fns to format the date
    return new Date(date).toLocaleDateString(); // Basic formatting
  };

  let projectOrderInfo = getDataProjectOrder(user, offer, project);

  return (
    <ItemOffer
      isCurrentOffer={offer?.offer_id === currentOffer}
      onClick={(e) => {
        navigate(`/top/project/${project?.product_id}/dm/${offer?.offer_id}`);
      }}
    >
      {/* {productOfferOrder && (
        <div
          className="progress-offer"
          style={{ backgroundColor: productOfferOrder.color }}
        ></div>
      )} */}

      <div className="offer-main">
        <span className="material-symbols-rounded u-fontsize-24 grade-icon">
          grade
        </span>

        <div className="content-offer">
          <div className="block-offer">
            <div className="block-offer-1">
              <span className="title-offer">このプロジェクトの契約</span>
              <span className="time-offer">{formatDate(offer.modified)}</span>
            </div>

            <div className="block-offer-2">
              <div className="block-avatar-user">
                {user.role === "master_client" ? (
                  <>
                    {project?.owners?.map((owner) => {
                      return (
                        <img
                          className="avatar-user-offer"
                          src={owner?.avatar}
                          alt={owner?.display_name}
                        />
                      );
                    })}
                    {project?.admins?.length > 0 ? (
                      <img
                        className="avatar-user-offer ml-8"
                        src={project?.admins[0]?.avatar}
                        alt={project?.admins[0]?.display_name}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <img
                      className="avatar-user-offer"
                      src={user?.small_avatar}
                      alt={user?.display_name}
                    />
                    <img
                      className="avatar-user-offer ml-8"
                      src={project?.owners[0]?.avatar}
                      alt={project?.owners[0]?.display_name}
                    />
                  </>
                )}
              </div>
              <span className="description-content-offer">
                {projectOrderInfo?.text}
              </span>
            </div>
          </div>

          <div className="right-offer">
            {offer?.unread_message_count > 0 && (
              <div className="block-message-count">
                <div
                  className={`number-offer notification notification--blue notification--round`}
                >
                  {offer?.unread_message_count < 100
                    ? offer?.unread_message_count
                    : "99+"}
                </div>
              </div>
            )}
            <span className="next-offer-icon material-symbols-rounded">
              navigate_next
            </span>
          </div>
        </div>
      </div>
    </ItemOffer>
  );
};

export default OfferTwo;
