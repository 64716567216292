import React from "react";

import MessageOwnerList from "./message/MessageOwnerList";

const CommentListDM = ({
  type_comment,
  comments,
  user,
  offer_user,
  user_role,
}) => {
  if (type_comment === "messenger_owner") {
    return (
      <div>
        <MessageOwnerList
          comments={comments}
          user={user}
          offer_user={offer_user}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default CommentListDM;
