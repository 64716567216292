import React from "react";
import styled from "styled-components";

const NotificationRound = styled.div`
  margin-left: 2px;
  font-size: 9px;
  color: #53565a;
  line-height: 12px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 0 8px;
  ${(props) => (props.user_count === 0 ? `display: none` : `display: 'flex'`)}
`;

const CountUserSeen = ({ user_count }) => {
  if (user_count) {
    return (
      <NotificationRound user_count={user_count}>
        {user_count >= 10 ? "+9" : `+${user_count}`}
      </NotificationRound>
    );
  } else {
    return <></>;
  }
};

export default CountUserSeen;
