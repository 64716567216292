import React, { useState } from "react";

import Autofill from "../../../components/autofill/AutoFill";
import Textarea from "../../../components/form-item/Textarea";
import SwitchComponent from "../../../components/form-item/SwitchComponent";
// import TitleBlock from "../../../components/block/TitleBlock";
import RadioCard from "../../../components/form-item/RadioCard";

const OfferConditions = () => {
  const [textareaValue, setTextareaValue] = useState("");

  const handleSelectContent = (content) => {
    setTextareaValue(content);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  return (
    <>
      {/* <TitleBlock title="オファーを引き受ける基準" /> */}

      <div className="u-col u-gap64 u-w100">
        <div className="u-col u-gap16 u-w100">
          <div className="heading-13-spacing u-w100">クライテリア</div>
          <p>オファーを引き受ける基準を共有できます。</p>
          <div className="u-col u-gap8 u-w100">
            <Autofill
              group="criteria"
              nowrap={true}
              onSelectContent={handleSelectContent}
            />
            <Textarea
              value={textareaValue}
              onChange={handleTextareaChange}
              rows={3}
              placeholder="プロジェクトが私にとってエキサイティングかどうかで判断します。"
              maxLength={150}
            />
          </div>
        </div>

        <div className="u-col u-gap16 u-w100">
          <div className="heading-13-spacing u-w100">ステータス</div>
          <p>今の忙しさを共有できます。3日でリセットされます。</p>
          <div className="u-col u-gap8 u-w100">
            <RadioCard
              name="status"
              id="1"
              checked={true}
              showRadio={true}
              height="64px"
            >
              いま、のんびり中
            </RadioCard>
            <RadioCard
              name="status"
              id="2"
              checked={false}
              showRadio={true}
              height="64px"
            >
              そこそこ余裕あり
            </RadioCard>
            <RadioCard
              name="status"
              id="3"
              checked={false}
              showRadio={true}
              height="64px"
            >
              ちょっと込み合い中
            </RadioCard>
            <RadioCard
              name="status"
              id="4"
              checked={false}
              showRadio={true}
              height="64px"
            >
              かなり集中モード
            </RadioCard>
            <RadioCard
              name="status"
              id="5"
              checked={false}
              showRadio={true}
              height="64px"
            >
              もう限界間近
            </RadioCard>
          </div>
        </div>

        <div className="u-col u-gap16">
          <div className="heading-13-spacing u-w100">
            オファーを引き受ける相手先の範囲
          </div>
          <div className="u-col u-gap8">
            <SwitchComponent
              options={[
                { label: "公開", value: "open" },
                { label: "公開先を限定", value: "close" },
              ]}
              defaultValue="close"
              onChange={() => {}}
            />
            <p>
              ONにすると、プロジェクトのプロデューサー、ディレクターだけでなく、スタッフ全体に開示。再々委託になるオファーも受けたいときにはONにしましょう。
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferConditions;
