import React from "react";

// Share Modal Component
const ShareModal = ({ isOpen, onClose }) => {
  return (
    <div
      className={`modal fade share-modal ${isOpen ? "show" : ""}`}
      role="dialog"
      style={{ zIndex: 9999, display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Share Video</h6>
            <button className="close" onClick={onClose} type="button">
              閉じる
            </button>
          </div>
          <div className="modal-body">
            <div className="video-time-slider-item">
              <div className="video-time-slider-content">
                <div className="video-time-slider-start">00:00</div>
                <div className="video-time-slider-bar"></div>
                <div className="video-time-slider-end hide">00:00</div>
              </div>
              <div className="video-time-slider-label">
                <div className="video-time-slider-label-start">
                  開始位置を指定
                </div>
                <div className="video-time-slider-label-end hide">
                  終了位置も指定
                </div>
              </div>
            </div>
            <div className="modal-share-link">
              <div className="video-item-share-input">
                <input
                  className="video-share-link"
                  id="video-share-link"
                  type="text"
                  name="video-share-link"
                  placeholder=""
                  value=""
                  readOnly
                />
              </div>
              <div
                className="button button--text button--text-primary video-item-share-btn"
                role="button"
              >
                URLをコピー
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
