import React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

// import CountUserSeen from "./CountUserSeen";
import UserDownloadedAvatar from "./UserDownloadedAvatar";

const ViewUserContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const UserDownloaded = ({ file }) => {
  const maxLoop = !isMobile ? 7 : 4; // Set the limit for the number of avatars to display
  let count = 0;

  return (
    <ViewUserContainer>
      {file?.list_user_downloaded?.length > 0
        ? file.list_user_downloaded.map((user_downloaded, index) => {
            count++;
            if (count <= maxLoop) {
              return (
                <UserDownloadedAvatar
                  key={index}
                  display_name={user_downloaded?.display_name}
                  avatar={user_downloaded?.avatar}
                />
              );
            }
            return null; // Return null for users that exceed the max loop count
          })
        : null}

      {/* <CountUserSeen
        user_count={file?.list_user_downloaded?.length
          ? file.list_user_downloaded.length - maxLoop + 1
          : 0 - 6}
      /> */}
    </ViewUserContainer>
  );
};

export default UserDownloaded;
