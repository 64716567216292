import React from "react";
import { useParams } from "react-router-dom";
// import styled from "styled-components";

import {
  useGetMessengerListArtistQuery,
  useLoadOfferMessageDetailQuery,
} from "../../../../../app/services/project";
import ProjectDMArtisList from "./ProjectDMArtisList";
import ProjectDMChatBoxContainer from "./ProjectDMChatBoxContainer";

const DM = ({ user, project }) => {
  const { projectId, offerId } = useParams();

  if (!user?.id) {
    return <div>Loading...</div>; // Or return nothing/empty if you prefer
  }

  let {
    data,
    // isLoading: loading,
    // isFetching,
  } = useGetMessengerListArtistQuery({
    project_id: projectId,
    user_id: user?.id,
    filter_offer: "",
    offer_active: offerId,
  });

  let {
    data: offerData,
    // isLoading: loading,
    // isFetching,
  } = useLoadOfferMessageDetailQuery({
    offer_id: offerId,
  });

  return (
    <div
      className="project-item__content refactor"
      id="projectItemDetail"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flexStart",
      }}
    >
      <ProjectDMArtisList
        data={data}
        user={user}
        project={project}
        currentOffer={offerId}
      />
      <ProjectDMChatBoxContainer
        offerData={offerData}
        user={user}
        project={project}
      />
    </div>
  );
};

export default DM;
