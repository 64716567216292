import React from "react";
import styled from "styled-components";

// Styled components for the rating stars
const StarsContainer = styled.div`
  position: relative;
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ canRate }) => (canRate ? "pointer" : "default")};

  /* Disable pointer events if not able to rate */
  ${({ canRate }) =>
    !canRate &&
    `
    .star {
      pointer-events: none;
    }
  `}
`;

const Star = styled.span`
  margin-right: 4px;
  width: 8px;
  font-size: 13px;
  color: ${({ active }) => (active ? "#009ace" : "var(--soremo-light-gray)")};
  /* Apply active class if rating matches */
  &.active {
    color: #009ace;
  }
`;

const RatingComponent = ({ currentRating, canRate = false }) => {
  return (
    <StarsContainer canRate={canRate} data-rating={currentRating}>
      {[1, 2, 3, 4, 5].map((starValue) => (
        <Star
          key={starValue}
          className={`material-symbols-rounded star-${starValue} ${starValue <= currentRating ? "active" : ""}`}
          active={starValue <= currentRating}
          data-value={starValue}
        >
          star
        </Star>
      ))}
    </StarsContainer>
  );
};

export default RatingComponent;
