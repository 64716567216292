import React from "react";

import soremoTeaser from "../../../assets/videos/soremo-teaser-2020.mp4";

const VideoBlock = () => {
  return (
    <div className="video-container">
      <video
        src={soremoTeaser}
        autoPlay={true}
        playsInline={true}
        muted={true}
        loop={true}
      />
    </div>
  );
};

export default VideoBlock;
