import React from "react";

import MyPage from "./MyPage";

const routes = [
  {
    path: "/my-page/",
    exact: true,
    component: <MyPage />,
    isHideTopBar: false,
  },
];

export default routes;
