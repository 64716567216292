import React from "react";

import Input from "../../../components/form-item/Input";
import ListBlock from "../../../components/block/ListBlock";
import TitleBlock from "../../../components/block/TitleBlock";

const MySiteFooter = () => {
  return (
    <div className="u-col u-gap24">
      <TitleBlock
        title="フッターのリンク設定"
        description="フッターに表示するリンク先やアイコンを設定しましょう。"
      />

      <div className="u-col u-w100">
        <p>リンク先を設定できます。</p>
        <div className="u-row u-gap4">
          <div className="heading">メニュー</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>

        <div className="u-w100 u-col u-gap16">
          <div className="u-w100 u-col u-gap4">
            <ListBlock type="link-text" isSortable={true} isDeletable={true} />
            <ListBlock type="link-text" isSortable={true} isDeletable={true} />
            <ListBlock type="link-text" isSortable={true} isDeletable={true} />
          </div>
          <ListBlock type="add" label="メインメニューを追加" />
        </div>
      </div>

      <div className="u-col u-w100">
        <p>アイコンとリンク先を設定できます。</p>
        <div className="u-row u-gap4">
          <div className="heading">アイコン</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div className="u-w100 u-col u-gap16">
          <div className="u-w100 u-col u-gap4">
            <ListBlock
              type="link-icon"
              icon="home"
              isSortable={true}
              isDeletable={false}
            />
            <ListBlock
              type="link-icon"
              icon="mail"
              isSortable={true}
              isDeletable={true}
            />
          </div>
          <ListBlock type="add" label="アイコンリンクを追加" />
        </div>
      </div>

      <div className="u-col u-w100">
        <p>権利表記を調整できます。</p>
        <Input
          label="コピーライト"
          option="[任意]"
          type="text"
          placeholder="&copy;SOREMO Co., ltd. All right reserved."
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <p>コピーライトに続くサブメニューを追加できます。</p>
        <div className="u-row u-gap4">
          <div className="heading">サブメニュー</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div className="u-w100 u-col u-gap16">
          <div className="u-w100 u-col u-gap4">
            <ListBlock type="link-text" isSortable={true} isDeletable={true} />
          </div>
          <ListBlock type="add" label="サブメニューを追加" />
        </div>
      </div>
    </div>
  );
};

export default MySiteFooter;
