import React, { useState } from "react";

import Tab from "../../../components/tab/Tab";
import PaymentListItem from "../../../components/list-item/PaymentListItem";
import TitleBlock from "../../../components/block/TitleBlock";

const PaymentDetails = () => {
  // アクティブなタブを管理するStateを追加
  const [activeTab, setActiveTab] = useState("2024年");

  return (
    <>
      <TitleBlock
        title="支払明細書"
        description="支払明細書をPDFでダウンロードできます。"
      />
      <Tab
        type="text"
        list={["2024年", "2023年", "2022年", "2021年", "2020年"]}
        activeTab={activeTab}
        onTabChange={(tabName) => setActiveTab(tabName)}
      />
      {activeTab === "2024年" && (
        <>
          <PaymentListItem
            applicationDate={new Date(2024, 10, 13)}
            isPaymentCompleted={false}
            paymentAmount="230000"
          />
          <PaymentListItem
            applicationDate={new Date(2024, 9, 10)}
            isPaymentCompleted={false}
            paymentAmount="11000"
          />
          <PaymentListItem
            isPaymentCompleted={true}
            paymentDate={new Date(2024, 8, 12)}
            paymentAmount="110000"
          />
          <PaymentListItem
            isPaymentCompleted={true}
            paymentDate={new Date(2024, 8, 3)}
            paymentAmount="1100"
          />
        </>
      )}
    </>
  );
};

export default PaymentDetails;
