import React from "react";
import styled from "styled-components";

import MemberList from "./MemberList";

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
`;

const ModalUserJoined = ({ user, project, visible, setVisible }) => {
  return (
    <>
      <div
        className={`modal-users-in-project fade ${visible ? "" : "d-none-el"}`}
        id="modalUsersInProject"
        data-product={project.id}
      >
        <MemberList
          owners={project?.owners || []}
          admins={project?.admins || []}
          members={project?.members}
          product={project}
          user={user}
        />
      </div>
      {visible ? (
        <Backdrop onClick={(e) => setVisible(false)}></Backdrop>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModalUserJoined;
