import React from "react";
import styled from "styled-components";

const StyledCVideoThumbListDelivery = styled.div`
  padding: 0;
  width: 100%;
  flex: 0 0;
`;

const StyledCVideoThumb = styled.div`
  height: 96px;
  min-height: 96px;
  cursor: pointer;
  background-size: cover;
  border-radius: 6px;
  position: relative;

  & video {
    vertical-align: middle;
    border-radius: 6px;
    height: 96px;
    width: auto;
    object-fit: cover;
    background: #000;
    display: inline-block;
  }
`;

const StyledCvideoHeading = styled.div`
  display: none;
`;

const StyledCvideoMeta = styled.div`
  display: none;
`;

const CSceneDelivery = ({ scene, user }) => {
  return (
    <StyledCVideoThumbListDelivery>
      <div
        className="cvideo__thumb-item-delivery"
        data-scene-title-id={scene?.id}
        data-status={scene?.status}
        data-rating={scene?.rating}
        data-title={scene?.name}
        data-scene-id={scene?.scene_id}
        data-product-scene={scene?.product_scene_id} // Assuming this is an ID of the product scene
      >
        <StyledCVideoThumb
          data-file-type={scene?.movie_name ? scene?.is_audio_file : undefined}
          out-of-date-schedule={scene?.is_out_of_date || undefined}
        >
          {!scene?.is_out_of_date ? (
            <video
              width="100%"
              height="256px"
              poster={scene?.thumbnail}
              preload="none"
              loading="lazy"
              style={{ verticalAlign: "middle", borderRadius: "6px" }}
            >
              {scene?.movie && <source src={scene?.movie} type="video/mp4" />}
            </video>
          ) : (
            <div
              className="thumb-schedule-video view_only refactor"
              style={
                scene?.movie
                  ? {
                      backgroundImage: `url(${scene?.thumbnail})`,
                      mixBlendMode: "luminosity",
                      opacity: 0.5,
                    }
                  : {}
              }
            >
              {/* Handle out-of-date schedule here if necessary */}
            </div>
          )}
        </StyledCVideoThumb>

        <StyledCvideoHeading
          style={{ lineHeight: "200%", margin: 0, padding: 0 }}
        >
          {!["5", "6"].includes(scene?.status) ? (
            <div
              title=""
              className={` icon--sicon-heart-o project-chapter-video-done ${
                (user?.role === "admin" || scene?.view_only) && "cannot-check"
              }`}
            />
          ) : (
            <div
              title=""
              className={` icon--sicon-heart project-chapter-video-undone ${
                scene?.view_only && "cannot-check"
              }`}
            />
          )}
          <div className="cvideo__title" style={{ padding: 0 }}>
            {scene?.name}
          </div>
        </StyledCvideoHeading>

        <StyledCvideoMeta>
          <div className={`cvideo__rating`}>
            <div
              className={`stars ${
                user?.role === "admin" ? "cannot-check average-star" : ""
              }`}
              data-rating={scene?.rating}
            >
              <span>
                {Array.from({ length: 5 }, (_, index) => (
                  <button
                    key={index + 1}
                    className={`star-${index + 1}`}
                    data-value={index + 1}
                  >
                    {index + 1}
                  </button>
                ))}
              </span>
            </div>
          </div>

          <div className="cvideo__date-time" style={{ margin: 0, padding: 0 }}>
            {scene.movie ? (
              scene?.is_out_of_date ? (
                <div className="cvideo__date">
                  配信予定: {scene.is_out_of_date}
                </div>
              ) : (
                <div className="cvideo__date">{scene?.is_out_of_date_st}</div>
              )
            ) : !scene?.is_out_of_date ||
              scene?.is_out_of_date === "まもなくリリース" ? (
              <div className="release-soon" style={{ fontSize: "8px" }}>
                まもなくリリース
              </div>
            ) : (
              <div className="cvideo__date">
                配信予定: {scene.is_out_of_date}
              </div>
            )}
          </div>
        </StyledCvideoMeta>
      </div>
    </StyledCVideoThumbListDelivery>
  );
};

export default CSceneDelivery;
