import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledTitleBlock = styled.div`
  width: 100%;
`;

const TitleBlock = (props) => {
  const { title, description } = props;
  return (
    <StyledTitleBlock className="u-col u-gap16 u-mb16">
      <div className="heading-spacing-1824">{title}</div>
      <p>{description}</p>
    </StyledTitleBlock>
  );
};

TitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TitleBlock;
