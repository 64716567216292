import React from "react";

import UserDownloaded from "./UserDownloaded.jsx";

const VideoBlock = ({ file, fileInfo, index, comment }) => {
  // Check if the ACR status is one of the valid statuses
  const validAcrStatuses = ["1", "2", "3"];
  const isAcrValid = validAcrStatuses.includes(file?.acr_status);

  // Determine the ACR result icon based on status
  const acrIcon =
    file?.acr_status === "3" ? (
      <div className="acr-result-icon btn-finger-print active">
        <img
          src="/static/images/scene-detail/icon-finger-print-active.svg"
          alt="finger print"
        />
      </div>
    ) : (
      <div className="acr-result-icon btn-finger-print">
        <img
          src="/static/images/scene-detail/icon-finger-print.svg"
          alt="finger print"
        />
      </div>
    );

  return (
    <div>
      {/* Video block */}
      <div className="block-video-cmt" style={{ width: "100%" }}>
        <video height="144px" preload="auto" loading="lazy">
          <source src={file?.file} type="video/mp4" />
        </video>
      </div>

      {/* File content */}
      <div className="comment-file-content">
        <p style={{ wordBreak: "break-all" }} className="file-name">
          {file?.real_name}
        </p>
        <div className="action-right-video">
          {isAcrValid && acrIcon}
          <div className="btn-download-file">
            <span className="material-symbols-rounded scene-file-download">
              download
            </span>
          </div>
        </div>
      </div>

      {/* Video info and download info */}
      <div className="info-message-video info-message-file">
        <div className="size-file-message">
          {/* Example for parsing file info */}
          {fileInfo ? (
            <>
              <span>{`${fileInfo?.width} x ${fileInfo?.height}px`}</span>
              <span>{`${fileInfo?.fps}fps`}</span>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="user-download-file">
          <div className="has_user_downloaded">
            <UserDownloaded file={file} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBlock;
