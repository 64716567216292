import React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

import UserSeenMessage from "./comment-block/UserSeenMessage";
import CountUserSeen from "./comment-block/CountUserSeen";
import { DAY_OF_WEEK } from "../../../../../constant/index";

const MessageInfoContainer = styled.div`
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: auto;
  cursor: pointer;
  padding-right: 5px;

  .mmessage-status {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;

    .mmessage-time {
      color: #a7a8a9;
      text-align: right;
      margin-bottom: 2px;
      font-family: "A+mfCv-AXISラウンド 50 L StdN", "Noto Sans Japanese", "sans-serif";
      font-size: 8px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%;
    }

    .mmessage-user {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      display: flex;
    }
  }
}
`;

const MessageInfoBlock = ({ comment, user, typeInfo }) => {
  let maxLoop = !isMobile ? 7 : 4;
  let time = "";
  const nowDate = new Date();
  const dateCreated = new Date(comment.created);

  if (
    dateCreated.getDate() === nowDate.getDate() &&
    dateCreated.getMonth() === nowDate.getMonth() &&
    dateCreated.getFullYear() === nowDate.getFullYear()
  ) {
    time = `${dateCreated.getHours().toString().padStart(2, "0")}:${dateCreated.getMinutes().toString().padStart(2, "0")}`;
  } else if (nowDate - dateCreated < 7 * 24 * 3600 * 1000) {
    // If the message was created within the last 7 days, show the weekday
    time = DAY_OF_WEEK[dateCreated.getDay()];
  } else {
    // Show month and day if the message is older than 7 days
    time = `${dateCreated.getMonth() + 1}:${dateCreated.getDate()}`;
  }

  return (
    <MessageInfoContainer>
      <div className="mmessage-status">
        <div className="mmessage-time">{time}</div>
        <div className="mmessage-user">
          {/* Render CountUserSeen and UserSeenMessage components */}
          {comment?.list_user_seen?.length > 0 && typeInfo !== "received" ? (
            <CountUserSeen
              user_count={comment?.list_user_seen?.length - maxLoop}
            />
          ) : null}

          {comment?.list_user_seen?.length > 0 &&
            comment?.list_user_seen?.map((user, indexUser) => {
              if (indexUser < maxLoop - 1) {
                return <UserSeenMessage key={user.id} user={user} />;
              }
              return null;
            })}

          {comment?.list_user_seen?.length > 0 && typeInfo === "received" ? (
            <CountUserSeen
              user_count={comment?.list_user_seen?.length - maxLoop}
            />
          ) : null}
        </div>
      </div>
    </MessageInfoContainer>
  );
};

export default MessageInfoBlock;
