import React from "react";

import heroTextImg from "../../../assets/images/hero_text_img.png";

const HeaderBlock = () => {
  return (
    <div className="main wrapper">
      <div className="main-title">
        <div className="image-hero-text-container">
          <img src={heroTextImg} alt="hero text" className="image-hero-text" />
        </div>
        <p>
          動画を送るとピッタリな「音」を返してくれるコンシェルジュ。
          <br />
          <span>
            オーダーメイドの音楽・音・声を、
            <br />
            まるごと依頼できるWebサービスです。
          </span>
        </p>
      </div>
    </div>
  );
};

export default HeaderBlock;
