import React from "react";

const Avatar = ({ url, isOnline, size = "32" }) => {
  return (
    <div className="u-relative">
      <div
        className={`c-avatar${size}`}
        style={{ backgroundImage: `url(${url})` }}
      ></div>
      {isOnline && <div className="c-avatar-status"></div>}
    </div>
  );
};

export default Avatar;
