import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { getDataOfferByRole } from "../../common";

const ItemOffer = styled.li`
  background-color: #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  height: inherit;
  border-radius: 0;
  border-radius: initial;
  padding: 0;
  padding: initial;
  -webkit-box-orient: initial;
  -webkit-box-direction: initial;
  -ms-flex-direction: initial;
  flex-direction: initial;
  margin-bottom: 0;
  ${(props) => (props?.isCurrentOffer ? "background-color: #f0f0f0;" : "")}

  .offer-main {
    width: 100%;
    position: relative;
    padding: 8px 0px 8px 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 4px;
  }

  .offer-main-item-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 8px;

    .right-offer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-width: 40px;
      width: 40px;

      .block-message-count {
        width: 16px;
        height: 16px;
      }

      .right-offer .next-offer-icon {
        color: #a7a8a9;
        visibility: hidden;
      }
    }

    .offer-main-content {
      width: 100%;

      .block-offer-top {
        width: 100%;
        gap: 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 0px 0px 0px 26px;

        .offer-top-avatar {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          .top-avatar {
            min-width: 24px;
            min-height: 24px;
            width: 24px;
            height: 24px;
            background-size: cover;
            border-radius: 10px;
            border: 1px solid #fff;
            -webkit-box-shadow: 2px 4px 10px 0px #e5e5e5;
            box-shadow: 2px 4px 10px 0px #e5e5e5;
          }
        }

        .text-bottom-offer {
          padding-top: 8px;
          padding-bottom: 8px;
          width: 100%;
          font-weight: normal;
          font-size: 11px;
          color: #a7a8a9;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          height: 27px;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
      }
    }

    .offer-content-bottom {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 4px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .main-avatar {
        min-width: 48px;
        min-height: 48px;
        width: 48px;
        height: 48px;
        border-radius: 18px;
        border: 1px solid #fff;
        -webkit-box-shadow: 2px 4px 10px 0px #e5e5e5;
        box-shadow: 2px 4px 10px 0px #e5e5e5;
        background-size: cover;
      }

      .content-offer {
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 8px;

        .block-offer {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          gap: 8px;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;

          .block-offer-1 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            width: 100%;

            .title-offer {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              font-family: "A+mfCv-AXISラウンド 50 R StdN", "M PLUS 1p",
                sans-serif;
              font-style: normal;
              font-size: 13px;
              line-height: 100%;
              letter-spacing: 2.5px;
              word-wrap: break-word;
              color: #009ace !important;
            }

            .time-offer {
              font-weight: normal;
              font-size: 8px;
              line-height: 100%;
              color: #a7a8a9;
            }
          }

          .block-offer-2 {
            width: 100%;
            gap: 6px;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .block-text-description {
              line-height: 100%;
              font-weight: normal;
              font-size: 11px;

              .text-des-1,
              .text-des-2 {
                color: black;
              }
            }

            .description-content-offer {
              line-height: 100%;
              font-weight: normal;
              font-size: 11px;
              color: #a7a8a9;
            }
          }
        }
      }
    }
  }
`;

const OfferOne = ({ offer, user, project, currentOffer }) => {
  const navigate = useNavigate();
  let projectOrderInfo = getDataOfferByRole(user, offer);

  return (
    <ItemOffer
      isCurrentOffer={offer?.offer_id === currentOffer}
      onClick={(e) => {
        navigate(`/top/project/${project?.product_id}/dm/${offer?.offer_id}`);
      }}
    >
      {/* {offerColorText && (
        <div className={`progress-offer ${offerColorText}`} style={{ backgroundColor: offerColorText.color }}></div>
      )} */}

      <div className="offer-main offer-main-item-2">
        <div className="offer-main-content">
          {offer?.offer_creator && user.role !== "master_admin" && (
            <div className="block-offer-top">
              <div className="offer-top-avatar">
                <div
                  className="top-avatar"
                  style={{
                    backgroundImage: `url(${offer?.offer_creator?.admin_avatar_url})`,
                  }}
                ></div>
              </div>
              <span className="text-bottom-offer">
                {offer?.offer_creator.admin_name}さんからのオファー
              </span>
            </div>
          )}

          <div className="offer-content-bottom">
            <div
              className="main-avatar"
              style={{
                backgroundImage: `url(${offer.offer_creator?.creator_avatar_url})`,
              }}
            ></div>

            <div className="content-offer">
              <div className="block-offer">
                <div className="block-offer-1">
                  <span className={`title-offer`}>
                    {user?.role !== "master_admin" &&
                    user?.id === offer?.offer_creator?.creator
                      ? "あなた"
                      : offer.offer_creator?.creator_name}
                  </span>
                  <span className="time-offer">
                    {dayjs(offer?.modified).format("M/D")}
                  </span>
                </div>

                <div className="block-offer-2">
                  <div className="block-text-description">
                    <span className="text-des-1">
                      {offer?.offer_creator?.contract}
                    </span>
                    <span className="text-des-2">
                      {offer?.offer_creator?.scenes
                        ? `（${offer?.offer_creator?.scenes}）`
                        : ""}
                    </span>
                  </div>
                  <span className="description-content-offer">
                    {projectOrderInfo?.text}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right-offer">
          <div className="block-message-count">
            {offer?.unread_message_count > 0 && (
              <div
                className={`number-offer notification notification--blue notification--round`}
              >
                {offer?.unread_message_count < 100
                  ? offer?.unread_message_count
                  : "99+"}
              </div>
            )}
          </div>
          <span className="next-offer-icon material-symbols-rounded">
            navigate_next
          </span>
        </div>
      </div>
    </ItemOffer>
  );
};

export default OfferOne;
