import React from "react";

import CommentReceivedBlock from "./CommentReceivedBlock";
import CommentSentBlock from "./CommentSentBlock";

const CommentBlock = ({ comment, user }) => {
  if (comment?.is_same_role) {
    return <CommentSentBlock comment={comment} user={user} />;
  } else {
    return <CommentReceivedBlock comment={comment} user={user} />;
  }
};

export default CommentBlock;
