import React, { useState } from "react";

import Modal from "../modal/Modal";
import Button from "../button/Button";
import StatusLabel from "../status-label/StatusLabel";
import FileUploader from "../uploader/FileUploader";

const IdentityBadge = () => {
  const [authUser] = useState({
    //CreatorFileモデルが追加されている。用途を確認し、同じであれば削除する。
    file_status: "0", // 0: 未提出, 1: 審査中, 2: 本人確認済み, 3: 要更新
    user_file: "", // 本人確認書類
    user_file_name: "",
  });

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      {authUser.file_status === "0" && (
        <Button
          name="「本人確認済み」ラベルを獲得"
          size="xs"
          color="border"
          rightIcon="chevron_right"
          onClick={handleModalOpen}
        />
      )}
      {authUser.file_status === "1" && (
        <StatusLabel
          label="本人確認審査中"
          color="light-gray"
          leftIcon="pending"
        />
      )}
      {authUser.file_status === "3" && (
        <Button
          name="本人確認書類を更新"
          size="xs"
          color="primary"
          rightIcon="chevron_right"
          onClick={handleModalOpen}
        />
      )}
      {authUser.file_status === "2" && (
        <StatusLabel label="本人確認済み" leftIcon="editor_choice" />
      )}
      {modalOpen &&
        (authUser.file_status === "0" ? (
          <Modal onClose={handleModalClose}>
            <p>本人確認書類を提出して「本人確認済み」ラベルを獲得</p>
            <FileUploader />
          </Modal>
        ) : (
          <Modal onClose={handleModalClose}>
            <p>本人確認書類を更新して「本人確認済み」ラベルを獲得</p>
            {/* TODO: 提出ファイルのプレビュー */}
            <FileUploader />
          </Modal>
        ))}
    </>
  );
};

export default IdentityBadge;
