import React, { useState } from "react";
// import styled from "styled-components";

import Autofill from "../../../components/autofill/AutoFill";
import Calendar from "../../../components/form-item/Calendar";
import Textarea from "../../../components/form-item/Textarea";
import TitleBlock from "../../../components/block/TitleBlock";

const Schedule = () => {
  const [textareaValue, setTextareaValue] = useState("");

  const handleSelectContent = (content) => {
    setTextareaValue(content);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  return (
    <>
      <TitleBlock
        title="スケジュール"
        // description="NG日を事前に設定し、スケジュールに合わないオファーを減らします。"
      />
      <div className="u-col u-gap32 u-w100">
        <div className="u-col u-gap4 u-w100">
          <div className="heading-13-spacing">作業スタイル</div>
          <Autofill
            group="schedule"
            nowrap={true}
            onSelectContent={handleSelectContent}
          />
          <Textarea
            value={textareaValue}
            rows={3}
            maxLength={150}
            onChange={handleTextareaChange}
          />
        </div>
        <div className="u-col u-gap8 u-w100">
          <div className="heading-13-spacing">NG日</div>
          <div className="u-row-center u-w100">
            <Calendar inline={true} allowInput={false} minDate={new Date()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
