import { createSlice } from "@reduxjs/toolkit";

import { profileApi } from "../../services/auth";

const initialState = {
  value: {}, // Profile data
  loading: false, // Loading state for async operations
  error: null, // Error state for async operations
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      //set value in payload to state
      state.value = action.payload;
    },
    unsetProfile: (state) => {
      //remove profile value
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling the states of `profileApi.getTestProfiles` query
      .addMatcher(
        profileApi.endpoints.getTestProfiles.matchPending,
        (state) => {
          state.loading = true; // Set loading to true when the request starts
          state.error = null; // Clear any previous error
        },
      )
      .addMatcher(
        profileApi.endpoints.getTestProfiles.matchFulfilled,
        (state, { payload }) => {
          state.loading = false; // Set loading to false once the data is fetched
          state.value = payload?.result; // Set the fetched profiles to the value state
        },
      )
      .addMatcher(
        profileApi.endpoints.getTestProfiles.matchRejected,
        (state, { error }) => {
          state.loading = false; // Set loading to false on error
          state.error = error.message || "Something went wrong"; // Set the error message
        },
      );
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, unsetProfile } = profileSlice.actions;

export default profileSlice.reducer;
