// LanguageSwitcher.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledLanguageSwitcher = styled.div.attrs({
  className: "u-w100 u-row u-gap4 u-justify-end u-text-blue",
})`
  display: flex;
  color: var(--soremo-blue)

  justify-content: flex-end;
  cursor: pointer;

  transition: 0.3s;

  @media (width > 640px) {
    &:hover {
      color: var(--soremo-deep-blue);

      .material-symbols-rounded {
        color: var(--soremo-deep-blue);
        scale: 1.2;
      }
    }
  }
`;

const LanguageSwitcher = ({ onLanguageChange }) => {
  const [language, setLanguage] = useState("JP");

  const toggleLanguage = () => {
    const newLanguage = language === "JP" ? "EN" : "JP";
    setLanguage(newLanguage);
    onLanguageChange(newLanguage);
  };

  return (
    <StyledLanguageSwitcher onClick={toggleLanguage}>
      <div className="u-col-center">
        <div className="material-symbols-rounded u-text-blue">language</div>
        <span className="bodytext-11 u-text-blue">
          {language === "JP" ? "EN" : "JP"}
        </span>
      </div>
    </StyledLanguageSwitcher>
  );
};

LanguageSwitcher.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
};

export default LanguageSwitcher;
