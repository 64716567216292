import React from "react";
import styled from "styled-components";

import CSceneDelivery from "./CSceneDelivery"; // Assuming you have a CSceneDelivery component

const StyledProjectFinishedList = styled.div`
  padding: 16px 0px;
  width: 100%;
  transition: 0.3s all ease 0.3s;
  background: rgba(240, 240, 240, 0.5);
`;

const StyledProjectHeading = styled.div`
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "A+mfCv-AXISラウンド 50 R StdN";
  letter-spacing: 2.5px;
  line-height: 100%;
  padding: 0px 16px;
  & span {
    font-family: "A+mfCv-AXISラウンド 50 L StdN";
    font-size: 13px;
    letter-spacing: 0px;
    color: rgb(167, 168, 169);
    padding: 8px 0px 0px;
  }
`;

const StyledPDSectionContent = styled.div`
  width: 100%;
  overflow: auto;
`;

const StyledPDSectionVideo = styled.div`
  gap: 4px 4px;
  padding: 8px 16px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  display: flex;
}
`;

const ProjectFinishedList = ({ sceneList, user }) => {
  return (
    <StyledProjectFinishedList>
      <StyledProjectHeading>
        チェックバック待ち
        <span>ほかの作業に集中しよう</span>
      </StyledProjectHeading>

      <StyledPDSectionContent>
        <StyledPDSectionVideo>
          {sceneList.map((st) => {
            const scene = st.scene_id; // Assuming you have the equivalent method getLastVersion in JS
            return scene ? (
              <CSceneDelivery key={st.scene_id} scene={st} user={user} />
            ) : (
              <></>
            );
          })}
        </StyledPDSectionVideo>
      </StyledPDSectionContent>
    </StyledProjectFinishedList>
  );
};

export default ProjectFinishedList;
