import React from "react";
import dayjs from "dayjs";

const ProjectUpdateBlock = ({ scene, user }) => {
  const isAudio = scene?.thumbnail?.includes("audio");

  return (
    <div
      className={`project-delivery-item-content item-delivery-video`}
      data-scene-title-id={scene?.id}
      data-status={scene?.status}
      data-rating={scene?.rating}
      data-title={scene?.name}
      data-scene-id={scene?.scene_id}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className={isAudio ? "navigation-scene" : ""}
      >
        <div className="cvideo__title bodytext-1316">
          {scene?.name}が届いています
        </div>
        <div className="cvideo__heading">
          <div className="cvideo__meta">
            <div className="cvideo__date-time">
              <div className="cvideo__date">
                {dayjs(scene?.updated_at).format("YYYY-MM-DD")}
              </div>
              <div className="cvideo__time">
                {dayjs(scene?.updated_at).format("HH:mm")}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isAudio ? (
        <div className="cvideo__thumb cvideo__thumb-list-update">
          <video
            width="100%"
            height="100%"
            poster={scene?.thumbnail}
            preload="none"
            loading="lazy"
            style={{ borderRadius: "6px" }}
          >
            <source src={scene?.movie} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div className="d-flex flex-direction-column">
          <div
            className="s-audio s-audio--audio-wave s-audio--white audio-pc u-relative d-block u-w100"
            data-scene-id={scene?.scene_id}
          >
            <div
              className="s-audio-source"
              data-link={scene?.movie}
              title={scene?.name}
              data-wavecolor="#d3d3d3"
              data-progresscolor="#53565a"
            ></div>
            <div className="s-audio-waveform u-w100"></div>
            <div className="s-audio-time new-position"></div>
            <div className="s-audio-time-total"></div>
          </div>
          <div className="audio-control-custom u-row u-gap16">
            <div className="s-audio-control pin-time-audio nav-play-sp u-w100 u-row u-gap8 s-audio-control-progress-tab">
              <span className="material-symbols-rounded c-icon-play-audio u-fontsize-32 material-symbol-play">
                play_circle
              </span>
              <span className="material-symbols-rounded c-icon-pause-audio u-fontsize-32 material-symbol-pause">
                pause
              </span>
              <input
                className="s-audio-wave-zoom"
                type="range"
                min="1"
                max="100"
                defaultValue="1"
              />
            </div>
          </div>
        </div>
      )}

      {scene?.last_comment?.length > 0 && (
        <div className="cvideo__last-msg">
          <div className="msg__wrap">
            <div className="msg__avatar">
              <img
                src={scene?.last_comment[0]?.avatar}
                alt=""
                width="32"
                height="32"
              />
            </div>
            {scene?.last_comment[0].comment &&
            scene?.last_comment[0].comment !== "" ? (
              <div className="msg__info">
                <div className="bodytext-13 u-line-height-150">
                  {scene?.last_comment[0].comment}
                </div>
              </div>
            ) : (
              <div className="msg__file-text-none">
                {scene?.last_comment[0].folders?.map(
                  (folder) =>
                    !folder.parent && (
                      <div key={folder.id} className="msg__file">
                        <i className="icon icon--sicon-storage"></i>
                        <div className="msg__file-name">{folder.name}</div>
                      </div>
                    ),
                )}
                {scene?.last_comment[0].files?.map(
                  (file) =>
                    !file.folder && (
                      <div key={file.id} className="msg__file">
                        <div className="msg__file-name">{file?.realName}</div>
                      </div>
                    ),
                )}
              </div>
            )}
          </div>

          {scene?.last_comment[0].comment &&
            scene?.last_comment[0].comment !== "" && (
              <>
                {scene?.last_comment[0].folders?.map(
                  (folder) =>
                    !folder.parent && (
                      <div key={folder.id} className="msg__file">
                        <i className="icon icon--sicon-storage"></i>
                        <div className="msg__file-name">{folder.name}</div>
                      </div>
                    ),
                )}
                {scene?.last_comment[0].files?.map(
                  (file) =>
                    !file.folder && (
                      <div key={file.id} className="msg__file">
                        <div className="msg__file-name">{file.realName}</div>
                      </div>
                    ),
                )}
              </>
            )}
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {scene?.status !== "5" && scene?.status !== "6" ? (
          <div
            title=""
            className={`project-chapter-video-done ${user?.role === "admin" || scene?.view_only ? "cannot-check" : ""}`}
          >
            <span className="material-symbols-rounded">check_circle</span>
          </div>
        ) : (
          <div
            title=""
            className={`project-chapter-video-undone ${scene?.view_only ? "cannot-check" : ""}`}
          >
            <span className="material-symbols-rounded u-text-blue">
              check_circle
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectUpdateBlock;
