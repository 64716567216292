import Preview from "../../components/preview/Preview";
import TestVideo1 from "../../assets/videos/『ラブ、デス&ロボット』VOL.3 予告編.mp4";
import TestVideo2 from "../../assets/videos/Explore 3 new features in UI3.mp4";
import TestVideo3 from "../../assets/videos/トルコ🇹🇷カッパドキアの朝.mp4";

const SceneDetail = () => {
  return (
    <>
      {/* <div className="u-col u-gap4">
        <Preview src={TestVideo1} fixedWidth="100%" />
        <Preview src={TestVideo2} fixedWidth="100%" />
        <Preview src={TestVideo3} fixedWidth="100%" />
        </div> */}

      <div
        className="u-row u-gap8"
        style={{
          overflowX: "auto",
          WebkitOverflowScrolling: "touch",
          width: "100%",
        }}
      >
        <Preview src={TestVideo1} fixedHeight="512px" maxWidth="100%" />
        <Preview src={TestVideo2} fixedHeight="512px" maxWidth="100%" />
        <Preview src={TestVideo3} fixedHeight="512px" maxWidth="100%" />
      </div>
    </>
  );
};

export default SceneDetail;
