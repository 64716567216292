import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";

// Set the workerSrc to the path of the PDF.js worker file
GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs`;

// Function to render PDF as Blob (same as before)
export const renderPdfThumbnail = async (pdfUrl) => {
  try {
    const pdf = await getDocument(pdfUrl).promise; // Use getDocument from pdfjs-dist
    const page = await pdf.getPage(1); // Get the first page

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const viewport = page.getViewport({ scale: 0.5 }); // Scale to fit within thumbnail size

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    // Render the PDF page to the canvas context
    await page.render({ canvasContext: context, viewport }).promise;

    // Convert the canvas to a Blob (image format)
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, "image/png"); // Create a Blob from the canvas in PNG format
    });

    return {
      url: URL.createObjectURL(blob),
      width: viewport.width,
      height: viewport.height,
    };
  } catch (error) {
    // console.error("Error rendering PDF to Blob:", error);
  }
};
