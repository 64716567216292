import React, { useState } from "react";

import TitleBlock from "../../../components/block/TitleBlock";
import ImageUploader from "../../../components/uploader/ImageUploader";
import testImage from "../../../assets/images/test.png";
import Textarea from "../../../components/form-item/Textarea";
import LanguageSwitcher from "../../../components/tab/LanguageSwitcher";
import Tab from "../../../components/tab/Tab";
import Toggle from "../../../components/form-item/Toggle";

const MySiteDesign = () => {
  const [language, setLanguage] = useState("JP");

  // アクティブなタブを管理するStateを追加
  const [activeTab, setActiveTab] = useState("フルスクリーン");

  // タブが変更されたときのハンドラを定義
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <TitleBlock
        title="マイサイトのデザイン"
        description="あなたのサイトに好きなビジュアルを登録してカスタマイズ"
      />

      <div className="u-col u-gap16">
        <div className="u-col u-w100">
          <p>キービジュアルの配置方法を選択できます。</p>
          <Tab
            type="tab"
            list={["フルスクリーン", "バナー"]}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
          {activeTab === "フルスクリーン" && (
            <div className="u-col u-w100 u-gap16 c-tab1">
              <div className="u-col u-w100">
                <p>キービジュアルを画面いっぱいに拡大して配置します。</p>
                <div className="u-row u-gap4">
                  <div className="heading">
                    フルスクリーン向けのキービジュアルデータ
                  </div>
                  <span className="label8 u-text-light-gray">[任意]</span>
                </div>
                <ImageUploader initialImage={testImage} aspect="16 / 10" />
              </div>
              <div className="u-col u-w100">
                <p>
                  モバイル向けに、縦長のビジュアルも登録し、切り替えもできます。
                </p>
                <ImageUploader initialImage={testImage} aspect="10 / 16" />
              </div>
              <div className="u-col u-w100">
                <p>左上にロゴ（透過PNG推奨）を表示できます。</p>
                <ImageUploader aspect="1 / 1" />
              </div>

              <div className="u-col u-w100">
                <p>キャッチフレーズを設定できます。</p>
                <div className="u-col u-w100 u-gap16">
                  <Textarea
                    rows="3"
                    placeholder={
                      language === "JP"
                        ? "音で「ワクワク」をデザイン"
                        : "Design with Sound"
                    }
                  ></Textarea>
                  <LanguageSwitcher
                    onLanguageChange={(lang) => setLanguage(lang)}
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab === "バナー" && (
            <div className="u-col u-w100 u-gap16 c-tab1">
              <p>キービジュアルをシネスコープ（2.35:1）で固定配置します。</p>
              <div className="u-row u-gap4">
                <div className="heading">バナー</div>
                <span className="label8 u-text-light-gray">[任意]</span>
              </div>
              <ImageUploader initialImage={testImage} aspect="2.35 / 1" />
            </div>
          )}
        </div>
      </div>

      <div className="u-col u-w100">
        {/* <p>アーティスト名とタイトルを表示できます。</p> */}
        <Toggle value={false} children="アーティスト名" />
        <Toggle value={false} children="タイトル" />
      </div>
    </>
  );
};

export default MySiteDesign;
