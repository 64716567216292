import React from "react";
import styled from "styled-components";

import UserDownloaded from "./UserDownloaded.jsx";

const ImageContainer = styled.div`
  padding: 8px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #000;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  word-break: break-word;
  border-radius: 6px;
`;

const ImagePreviewComment = styled.div`
  margin-bottom: 6px;

  img {
    width: 100%;
    max-width: 250px;
    margin-bottom: 6px;
    height: 100%;
    max-height: 250px;
    vertical-align: middle;
    border: 0;
  }
`;
const ImageFileContent = styled.div`
  margin-bottom: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 9px;
  width: 100%;
`;

const ImageInfoMessage = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
  
.prdt .mmessage.clicked .mmessage-main .messenger-content .messenger-image-preview-content .info-message-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    place-content: space-between;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 8px;
`;

const ImageBlock = ({ file, fileInfo, index, comment }) => {
  return (
    <ImageContainer>
      <ImagePreviewComment>
        <img
          src={`https://soremo-voice-jp-stg.s3.ap-northeast-1.amazonaws.com:443/${file?.file}`}
          alt={file?.real_name}
          loading="lazy"
        />
      </ImagePreviewComment>
      <ImageFileContent>
        <span style={{ wordBreak: "break-all" }}>{file?.real_name}</span>
        <div className="btn-download-file-cmt">
          <div className="btn-download-file">
            <span className="material-symbols-rounded scene-file-download">
              download
            </span>
          </div>
        </div>
      </ImageFileContent>
      <ImageInfoMessage>
        <div className="size-file-message">
          {fileInfo?.width && fileInfo?.height ? (
            <span>{`${fileInfo?.width} x ${fileInfo?.height}px`}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="user-download-file">
          <div className="has_user_downloaded">
            <UserDownloaded file={file} />
          </div>
        </div>
      </ImageInfoMessage>
    </ImageContainer>
  );
};

export default ImageBlock;
