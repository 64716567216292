import React from "react";

const SystemMessage = ({ comment, user, typeMessage }) => {
  // Check if message type is '2' or '3' and user role is 'master_client'
  if (
    (comment.type_message === "2" || comment.type_message === "3") &&
    user.role === "master_client"
  ) {
    return (
      <div className="mmessage-container refactor">
        <div className="mmessage mmessage-system">
          <div className="mmessage-system__content">{comment.comment}</div>
        </div>
      </div>
    );
  }

  // Return null if the condition is not met (i.e., don't render anything)
  return null;
};

export default SystemMessage;
