// 標準ライブラリ
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux"; // サードパーティライブラリ

// プロジェクト内モジュール
import { store } from "./app/store"; // Redux ストア
import routeSchema from "./pages/routes";
import GlobalWraper from "./components/global/GlobalWraper";

// スタイルファイル
import "./App.scss";
import "./soremo_style.scss";

const Application = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {routeSchema.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <GlobalWraper
                  isHideTopBar={route?.isHideTopBar}
                  children={route.component}
                />
              }
              exact={route.exact}
            />
          ))}
        </Routes>
      </Router>
    </Provider>
  );
};

export default Application;
