import React from "react";
import styled from "styled-components";

const GuideImage = ({ name }) => {
  const GuideImageStyle = styled.div`
    height: 160px;
    aspect-ratio: 16/9;

    // display: block;
    // margin-inline: auto;

    border-radius: 12px;
    background-image: url(${require(`../../assets/images/image_${name}.png`)});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  `;

  return <GuideImageStyle></GuideImageStyle>;
};

export default GuideImage;
